import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnInit, OnDestroy {

  time = new Date();
  rxTime = new Date();
  intervalId;
  subscription: Subscription;
  constructor() { }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    // Using Basic Interval
    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);
 // Using RxJS Timer
    this.subscription = timer(0, 1000)
 .pipe(
   map(() => new Date()),
   share()
 )
 .subscribe(time => {
   this.rxTime = time;
 });
}

// tslint:disable-next-line:typedef
ngOnDestroy() {
clearInterval(this.intervalId);
if (this.subscription) {
 this.subscription.unsubscribe();
}
}
}
