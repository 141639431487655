import { HttpClient } from '@angular/common/http'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { ToastrService } from 'ngx-toastr'
import { DataLimitsComponent } from 'src/app/data-limits/data-limits.component'
import { DeviceExtraDataComponent } from 'src/app/device-extra-data/device-extra-data.component'
import { DeviceKontactorsComponent } from 'src/app/device-kontactors/device-kontactors.component'
import { ShareDataService } from 'src/app/services/share-data.service'
import { UserService } from 'src/app/services/user.service'
import { EditUserInfoComponent } from '../edit-user-info/edit-user-info.component'

@Component({
  selector: 'app-device-full-info',
  templateUrl: './device-full-info.component.html',
  styleUrls: ['./device-full-info.component.scss'],
})
export class DeviceFullInfoComponent implements OnInit, OnDestroy {
 
  @Input() showInfoTable
  @Input() selectedDeviceId
 
  pingMessage: Object
 
  userDetails: Object
  Id: any
  userId: any
  deviceLog: {}
  interval: number
  startedMonitoring: string
  limitsPerDevice: any
  CommandAnswe: any
  defenceChanged: boolean
  deftoggle: boolean
  defType: string
  disableSignal = false
  serverTime: any
  updatedOnTime: string
  updatedOffTime: string
  min1: number
  min2: number

  constructor(
    private http: HttpClient,
    private service: UserService,
    private toastr: ToastrService,
   
    private _bottomSheet: MatBottomSheet,
    private shareDataService: ShareDataService,
  ) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res
      
        this.userId = this.userDetails['id']
        this.getPingMessage(this.userId)
      },
      (err) => {
        console.log(err)
      },
    )

    // @ts-ignore
    this.interval = setInterval(() => {
      this.getPingMessage(this.userId)
      // this.selectTurnOnMonitoringState();
      // this.getCurrentLimits();
    }, 5000)
    if (
      this.selectedDeviceId === undefined ||
      this.selectedDeviceId === 'undefined'
    ) {
      const localStorageKeys = Object.keys(localStorage)

      localStorageKeys.forEach((x) => {
        if (localStorage.getItem(x) === 'true') {
          this.selectedDeviceId = x
        }
      })
    }
  }

 
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
  
  getPingMessage(x) {
    this.service.PingMessage(x).subscribe(
      (res) => {
         this.pingMessage = res
         this.deviceLog = {}

        this.serverTime = res[res.length - 1].date
         for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          this.deviceLog[this.pingMessage[i].imei] = this.pingMessage[i]
        }
        this.calculateNewOnOff( )
        if (this.selectedDeviceId !== undefined) {
          this.defenceChanged =
            this.deviceLog[this.selectedDeviceId]['doorState'].split('.')[1] ==
            '1'
              ? true
              : false

          this.defType = this.defenceChanged ? 'აყვანილია' : 'მოხსნილია'
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }
 
   startMonitoring() {
    let startOrStop = '0'
    if (this.startedMonitoring === '0') {
      startOrStop = '1'
    }
    this.service.turnOnOffMonitoring(startOrStop).subscribe(
      (res) => {
        console.log(res)
      },
      (err) => {
        console.log(err)
      },
    )
  }
   selectTurnOnMonitoringState() {
    this.service.selectTurnOnMonitoringResult().subscribe(
      (res) => {
        this.startedMonitoring = res
      },
      (err) => {
        console.log(err)
      },
    )
  }

   saveNewComment(comment, imei) {
    this.service.addNewComment(comment, imei).subscribe(
      (res) => {
        console.log(res)
      },
      (err) => {
        if (err.error.text === 'OK') {
          this.toastr.success('ბრძანება გაიგზავნა')
        } else {
          this.toastr.error('ბრძანების გაგზავნა ვერ ხერხდება')
        }
      },
    )
  }
 

  openBottomSheet(deviceid, data): void {
    this._bottomSheet.open(DataLimitsComponent)
    this.shareDataService.selectedDataForLimits(deviceid, data)
  }
  openBottomSheet1(deviceid): void {
    this._bottomSheet.open(DeviceExtraDataComponent)
    this.shareDataService.selectExtraData(deviceid)
  }
  openBottomSheet2(deviceid, data): void {
    this._bottomSheet.open(DeviceKontactorsComponent)
    this.shareDataService.selectKontactors(deviceid, data)
  }

  changeDefence(defenceVal: string, event: MatSlideToggleChange) {
    this.toastr.success('ბრძანება გაიგზავნა')
    this.deftoggle = !event.checked
    this.disableSignal = true
    let answer = ''
    let command = ''
    if (defenceVal) {
      command = 'cmd_alarm_on'
      answer = 'სიგნალიზაცია არ ჩაირთო'
    } else {
      command = 'cmd_alarm_off'
      answer = 'სიგნალიზაცია არ გამოირთო'
    }
    var unix = Math.round(+new Date() / 1000).toString(16)

    command = command.replace('_', '_' + unix.toLocaleUpperCase() + '_')

    this.service.deviceCommands(command, this.selectedDeviceId).subscribe(
      (res) => {
        this.CommandAnswe = res
        this.disableSignal = false

        setTimeout(() => {
          this.disableSignal = false
        }, 10000)

        if (res.length == 0 || res[0].responseType != 'Ok') {
          var responseText = ''
          if (res.length > 0) {
            responseText =
              res[0].response.split(';')[2] == 0
                ? 'დროის აცდენა'
                : res[0].response.split(';')[2] == 2
                ? 'მოწყობილობის დრო არასწორია'
                : res[0].response.split(';')[2] == 3
                ? 'ბრძანება არასწორად გაიგზავნა'
                : res[0].response.split(';')[4] == 4
                ? 'მესიჯის ფორმატის შეცდომა'
                : ''
          }
          this.toastr.error(answer, responseText)
          this.defType = event.checked == true ? 'მოხსნილია' : 'აყვანილია'
        } else {
          this.defType = event.checked == true ? 'აყვანილია' : 'მოხსნილია'
        }
      },
      (err) => {
        if (err) {
          this.toastr.error(answer, 'ტექნიკური ხარვეზი')
          this.disableSignal = false
          this.defType = event.checked == true ? 'მოხსნილია' : 'აყვანილია'
          this.deftoggle =
            this.deftoggle == true ? this.deftoggle : !this.deftoggle
        }
      },
    )
  }

  calculateNewOnOff( ) {
 

    const devTurnOnOffTime: string =
      this.deviceLog[this.selectedDeviceId] &&
      this.deviceLog[this.selectedDeviceId]['plannedGraphicTurnOnOffTime']
    if (devTurnOnOffTime) {
      const [devOnTime, devOffTime]: string[] = devTurnOnOffTime.split('-')

      const devWanacvleba: string = this.deviceLog[this.selectedDeviceId][
        'plannedGraphicOffsetMins'
      ]

      let wanacvleba2: string
      const wanacvleba1: string = devWanacvleba.substring(0, 3)
      console.log('length', devWanacvleba.length)
      if (devWanacvleba.length === 6) {
        wanacvleba2 = devWanacvleba.substring(3)
      } else if (devWanacvleba.length === 7) {
        wanacvleba2 = devWanacvleba.substring(4)
      } else {
        // Handle the case when the length is neither 8 nor 9
        // You can assign a default value or perform other logic here
        wanacvleba2 = 'Some default value'
      }
      // const wanacvleba2: string = devWanacvleba.substring(4);

      // Convert devOnTime and devOffTime to Date objects
      const onTime: Date = new Date()
      const offTime: Date = new Date()
      const onTimeParts: string[] = devOnTime.split(':')
      const offTimeParts: string[] = devOffTime.split(':')

      onTime.setHours(parseInt(onTimeParts[0]))
      onTime.setMinutes(parseInt(onTimeParts[1]))
      offTime.setHours(parseInt(offTimeParts[0]))
      offTime.setMinutes(parseInt(offTimeParts[1]))

      // Convert wanacvleba1 and wanacvleba2 to numbers
      const minutes1: number = parseInt(wanacvleba1)
      const minutes2: number = parseInt(wanacvleba2)

      this.min1 = minutes1
      this.min2 = minutes2

      // Add or subtract minutes from the times
      onTime.setMinutes(onTime.getMinutes() + minutes1)
      offTime.setMinutes(offTime.getMinutes() + minutes2)

      // Convert the updated times back to string format
      const updatedOnTime: string = `${onTime.getHours()}:${onTime
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
      const updatedOffTime: string = `${offTime.getHours()}:${offTime
        .getMinutes()
        .toString()
        .padStart(2, '0')}`

      this.updatedOnTime = updatedOnTime
      this.updatedOffTime = updatedOffTime

      // Output the results
      // console.log('Updated On Time:', updatedOnTime)
      // console.log('Updated Off Time:', updatedOffTime)
      // console.log('dev wanacvleba:', devWanacvleba)

      // console.log(devOnTime);  // Output: 20:47
      // console.log(devOffTime); // Output: 05:13

      // console.log('onTime:', onTime)
      // console.log('offTime:', offTime)
      // console.log('minutes1:', minutes1)
      // console.log('minutes2:', minutes2)
    }
  }
}
