<div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col-12 padding-0"  id="user-devices-column" cdkDropListGroup>
        
        <div class="example-container">
          <h2>{{monitorNames[firstIndex]}}</h2>
      
          <div
            cdkDropList
            [cdkDropListData]="targetDeviceGroup"
            [cdkDropListAutoScrollStep]="7"
            class="example-list"
            (cdkDropListDropped)="drop($event)">
            <div class="example-box" id="left-box" *ngFor="let item of targetDeviceGroup" cdkDrag>{{item.split("__")[0]}}</div>
          </div>
        </div>
        <div class="example-container" style="cursor:pointer;">
          <h2>მონიტორი</h2>
      
          <div
            class="example-list"
            style="cursor:pointer;"
            
           >
            <div class="example-box"  style="cursor:pointer;display:flex;justify-content: space-between !important;" *ngFor="let name of monitorNames; let i = index" >
              <!-- <button class="btn btn-primary" (click)="OpenMonitor(i,selectedArrayIndexRight,'left')">L</button> -->
              <div (click)="OpenMonitor(i,selectedArrayIndexRight,'left')">
                <mat-icon style="color:rgb(28, 139, 0)">keyboard_arrow_left</mat-icon>
              </div>
              
              {{name}}
              <!-- <button class="btn btn-primary" (click)="OpenMonitor(selectedArrayIndex,i,'right')">R</button> -->
              <div (click)="OpenMonitor(selectedArrayIndex,i,'right')">
                <mat-icon style="color:rgb(28, 139, 0)">keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="example-container">
          <h2>{{monitorNames[secondIndex]}}</h2>
      
          <div
            cdkDropList
            [cdkDropListData]="targetDeviceGroup1"
            class="example-list"
            [cdkDropListAutoScrollStep]="7"
            (cdkDropListDropped)="drop($event)">
            <div class="example-box" id="right-box" *ngFor="let item of targetDeviceGroup1" cdkDrag>{{item.split("__")[0]}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <button class="btn btn-success">შენახვა</button> -->
  