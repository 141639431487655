import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
type UpdatePValuesType = {
  imei: string;
  pa: string;
  pb: string;
  pc: string;
};
@Injectable({
  providedIn: 'root',
})
export class DeviceServiceService {
  readonly BaseURI = ' https://request2.mygps.ge:4490/api';

  constructor(private http: HttpClient) {}

  UpdatePValues(obj: UpdatePValuesType) {
    console.log(obj);
    return this.http.post(`${this.BaseURI}/DevicePingMessage/UpdatePValues`, {
      ...obj,
    });
  }
}
