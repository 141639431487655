<h1>მომხმარებლის უფლებები</h1>

<form class="example-form">
  <mat-form-field appearance="fill" class="add-device-option" style="margin-right: 5px;">
    <mat-label>მომხარებლი:</mat-label>
    <mat-select [formControl]="toppings" multiple [(ngModel)]="selectedUser" (selectionChange)="onDeviceSelect(selectedUser)">
      <mat-select-trigger>
        {{toppings.value ? toppings.value[0] : ''}}
        <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
          (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
    </mat-select>
  </mat-form-field>
    <!-- <p>სახელი:</p>
    <input matInput  class="form-control"  [value]="selectedUser" [(ngModel)]="selectedUserName" name="name">
    <p>პაროლი:</p>
    <input matInput  class="form-control"  [value]="selectedUserPass" [(ngModel)]="selectedUserPass" name="imei"> -->

</form>
<div style="position:relative; display:flex;flex-direction: column; margin-top: 15px;left: 20px;">
  <label for="View">ქსელის დათვალიერების უფლება:</label>
  <mat-slide-toggle [(ngModel)]="ViewNetwork"></mat-slide-toggle>

  <label for="View">გარე განათების ჩართვა/გამორთვა:</label>
  <mat-slide-toggle [(ngModel)]="allowTurnOnOff"></mat-slide-toggle>
  <label for="View">გრაფიკის შეცვლის უფლება:</label>
  <mat-slide-toggle [(ngModel)]="allowGraphic"></mat-slide-toggle>
  <label for="View">ბრძანებების პანელი:</label>
  <mat-slide-toggle [(ngModel)]="allowCommandPanel"></mat-slide-toggle>
  <label for="View">დამატებითი ბრძანებების პანელი:</label>
  <mat-slide-toggle [(ngModel)]="allowOtherCommands"></mat-slide-toggle>
</div>

<button style="margin-top: 12px;" class="btn btn-primary" (click)="saveLimitCorrection(selectedUser,ViewNetwork,allowTurnOnOff,allowGraphic,allowCommandPanel,allowOtherCommands)">შენახვა</button>

