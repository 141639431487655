<section>
  <div class="example-button-row">
    <ul>
      <li>
        <button
          class="btn"
          mat-button
          [disabled]="false"
          (click)="deviceCommands('cmd_kontactors 1333', $event)"
          *ngIf="allowTurnOnOff == '1'"
          id="turn-on"
        >
          ჩართვა
        </button>
        <span
          _ngcontent-c20=""
          class="material-icons ng-star-inserted"
          (click)="showKontactors = !showKontactors"
          id="menu-icon"
          >menu</span
        >
        <ul *ngIf="showKontactors && allowTurnOnOff == '1'" id="sub-menu">
          <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K1checked"
              >K2
            </mat-checkbox>
          </li>
          <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K2checked"
              >K3
            </mat-checkbox>
          </li>
          <!-- <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K3checked"
              >K3
            </mat-checkbox>
          </li> -->
          <li>
            <button
              class="btn btn-success btn-sm"
              [disabled]="isDisabled"
              style="z-index: 1"
              id="turn-on-kontaktors"
              (click)="
                setK(K1checked + ';' + K2checked + ';' + K3checked, '1', $event)
              "
            >
              send
            </button>
          </li>
        </ul>
      </li>
      <li>
        <button
          class="btn"
          mat-button
          [disabled]="isDisabled"
          (click)="deviceCommands('cmd_kontactors 0333', $event)"
          *ngIf="allowTurnOnOff == '1'"
          id="turn-off"
        >
          გამორთვა
        </button>
        <span
          _ngcontent-c20=""
          class="material-icons ng-star-inserted"
          (click)="showKontactors1 = !showKontactors1"
          id="menu-icon"
          >menu</span
        >
        <ul *ngIf="showKontactors1 && allowTurnOnOff == '1'" id="sub-menu">
          <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K10checked"
              >K2
            </mat-checkbox>
          </li>
          <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K20checked"
              >K3
            </mat-checkbox>
          </li>
          <!-- <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K30checked"
              >K3
            </mat-checkbox>
          </li> -->
          <li>
            <button
              class="btn btn-success btn-sm"
              id="turn-off-kontaktors"
              [disabled]="isDisabled"
              (click)="
                setK(
                  K10checked + ';' + K20checked + ';' + K30checked,
                  '0',
                  $event
                )
              "
            >
              send
            </button>
          </li>
        </ul>
      </li>
      <li>
        <button
          class="btn"
          mat-button
          [disabled]="isDisabled"
          (click)="deviceCommands('cmd_kontactors 2223', $event)"
          *ngIf="allowGraphic == '1'"
          id="graphic"
        >
          გრაფიკით
        </button>
        <!-- <span
          _ngcontent-c20=""
          class="material-icons ng-star-inserted"
          (click)="showKontactors2 = !showKontactors2"
          id="menu-icon"
          >menu</span
        > -->
        <ul *ngIf="showKontactors2 && allowTurnOnOff == '1'" id="sub-menu">
          <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K12checked"
              >K2
            </mat-checkbox>
          </li>
          <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K22checked"
              >K3
            </mat-checkbox>
          </li>
          <!-- <li>
            <mat-checkbox class="example-margin" [(ngModel)]="K32checked"
              >K3
            </mat-checkbox>
          </li> -->
          <li>
            <button
              id="graphic-kontaktors"
              class="btn btn-success btn-sm"
              [disabled]="isDisabled"
              (click)="
                setK(
                  K12checked + ';' + K22checked + ';' + K32checked,
                  '2',
                  $event
                )
              "
            >
              send
            </button>
          </li>
        </ul>
      </li>
      <!-- <li>
      <a mat-button [disabled]="isDisabled" (click)="deviceCommands('BUTTON')">ღილაკით <span _ngcontent-c20="" class="material-icons ng-star-inserted">update</span></a>
     </li> -->

      <li>
        <button
          class="btn"
          mat-button
          [disabled]="isDisabled"
          (click)="deviceCommands('cmd_request', $event)"
          id="request"
        >
          განახლება<span
            _ngcontent-c20=""
            class="material-icons ng-star-inserted"
            >update</span
          >
        </button>
      </li>
      <li>
        <button
          class="btn"
          mat-button
          [disabled]="isDisabled"
          (click)="openIntervalDiv = !openIntervalDiv"
          id="refresh-Interval"
        >
          განახლების ინტერვალი<span
            _ngcontent-c20=""
            class="material-icons ng-star-inserted"
            >update</span
          >
        </button>
        <div *ngIf="openIntervalDiv">
          <input type="text" style="width: 100px" [(ngModel)]="setInterval" />
          <button
            id="set-interval"
            class="btn btn-sm btn-success"
            (click)="
              deviceCommands('cmd_setspeed ' + setInterval, $event);
              openIntervalDiv = false
            "
          >
            გაგზავნა
          </button>
        </div>
      </li>
      <li>
        <button
          class="btn"
          mat-button
          [disabled]="isDisabled"
          (click)="deviceCommands('cmd_restart', $event)"
          id="restart"
        >
          გადატვირთვა
          <span _ngcontent-c20="" class="material-icons ng-star-inserted"
            >update</span
          >
        </button>

        <button
          class="btn"
          mat-button
          [disabled]="isDisabled"
          (click)="deviceCommands('cmd_alarm_on', $event)"
          id="alarm_on"
        >
          სიგნალიზაციის ჩართვა
          <span _ngcontent-c20="" class="material-icons ng-star-inserted"
            >update</span
          >
        </button>

        <button
          class="btn"
          mat-button
          [disabled]="isDisabled"
          (click)="deviceCommands('cmd_alarm_off', $event)"
          id="alarm_off"
        >
          სიგნალიზაციის გამორთვა
          <span _ngcontent-c20="" class="material-icons ng-star-inserted"
            >update</span
          >
        </button>
      </li>
      <!-- <li id="turn-on-monitoring-btn">
        <button
          class="button1"
          (click)="startMonitoring()"
          [attr.class]="
            this.startedMonitoring == '1' ? 'btn btn-success' : 'btn btn-danger'
          "
        >
          ჩართვის ხანგრძლივობა
        </button>
      </li> -->

      <li id="turn-on-monitoring-btn">
        <button class="btn" [disabled]="isDisabled" (click)="openBottomSheet()">
          წლის გრაფიკი
        </button>
      </li>
    </ul>

    <!-- <button mat-button color="primary">Primary</button>
    <button mat-button color="accent">Accent</button>
    <button mat-button color="warn">Warn</button>
    <button mat-button disabled>Disabled</button>
    <a mat-button href="https://www.google.com/" target="_blank">Link</a> -->
  </div>
</section>
