import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delete-device',
  templateUrl: './delete-device.component.html',
  styleUrls: ['./delete-device.component.scss'],
})
export class DeleteDeviceComponent implements OnInit {
  toppings = new FormControl();
  topping: string;
  toppingList: any[] = [];
  selectedDevice: string;
  pingMessage: any;
  deviceLog: {};
  userDetails: any;
  userId: any;
  CommandAnswe: any;
  interval: number;
  // tslint:disable-next-line:ban-types
  isSent: string;
  start: boolean;
  imeiToDelte: {};
  listCareUnits: any;
  anotherArray = [];

  constructor(
    private http: HttpClient,
    private service: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getPingMessage(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x + ';' + 'fromAdmin').subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        this.imeiToDelte = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          if (
            !(
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress in
              this.deviceLog
            )
          ) {
            this.deviceLog[
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress
            ] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress +
                this.pingMessage[i].devieId
            ] = this.pingMessage[i].imei;
          }

          this.imeiToDelte[this.pingMessage[i].imei] =
            this.pingMessage[i].name + '__' + this.pingMessage[i].deviceId;
        }
        this.toppingList = Object.keys(this.deviceLog);
        this.anotherArray = Object.keys(this.deviceLog);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  sendProgramCmd(x) {
    // const headers = { 'content-type': 'multipart/form-data'};
    const target = this.deviceLog[x];
    const device = {
      Imei: target,
    };
    const body = JSON.stringify(device);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    this.http
      .post<any>(
        ' https://request2.mygps.ge:4490/api/DevicePingMessage/DeleteDevice',
        body,
        {
          headers: headers,
        }
      )
      .subscribe(
        (res) => {
          // tslint:disable-next-line:no-string-literal
          this.CommandAnswe = res;
        },
        (err) => {
          if (err.error.text === 'OkOk') {
            this.toastr.success('მოწყობილობა წარმატებით წაიშალა');

            let dataArr = JSON.parse(localStorage.getItem('DeviceOrder'));

            for (let i = 0; i < dataArr[this.userId].length; i++) {
              const index = dataArr[this.userId][i].indexOf(
                this.imeiToDelte[target]
              );

              if (index > -1) {
                dataArr[this.userId][i].splice(index, 1);
                localStorage.setItem('DeviceOrder', JSON.stringify(dataArr));
                break;
              }
            }
          } else {
            this.toastr.error('მოწყობილობის წაშლა არ ხერხდება');
          }
        }
      );
  }

  filterListCareUnit(val) {
    this.toppingList = this.anotherArray.filter(
      (unit) => unit.indexOf(val) > -1
    );
  }
}
