import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Data } from '@angular/router';
import { first } from 'rxjs/operators';
import { ShareDataService } from 'src/app/services/share-data.service';
import { UserService } from 'src/app/services/user.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-map-full-info',
  templateUrl: './map-full-info.component.html',
  styleUrls: ['./map-full-info.component.scss']
})
export class MapFullInfoComponent implements OnInit {
  selectedId: string;

  fileName = 'DeviceMapInfo.xlsx';
  displayedColumns: string[] = [ 'DeviceName',  'OBJECTID','Longitude','Latitude','KARADIS_NO','RAIONI','DADGMULI_S',
  'KVEBIS_WYA','KARADIS_TI','MARTVIS_BL','MRICXVELIS','MRICXVEL_1','SIDA_MOWYO','MDGOMAREOB','DAMIWEBA','SENISVNA','GAMAVALI_K',
  'NUMBER_OF_','DISTRICT','THE_INSTAL','POWER_SOUR','TYPE_OF_TH',
  'MANAGEMENT','NUMBER_OF1','TYPE_OF__1','INTERNAL_M',
  'GROUNDING','OUTPUT_CAB' ];

  
  dataSource: MatTableDataSource<Device>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userId = '';
  userDetails: any;

  constructor( private service: UserService,
    private shareDataService: ShareDataService,) { }

  ngOnInit(): void {
   
    this.shareDataService.currentApprovalStageMessage9.pipe(first()).subscribe((msg) => {
      this.selectedId = msg;
      if(msg == 'all'){
        this.userInfo();
      }
      if(msg != 'all'){
        this.getFullInfoById(parseInt(this.selectedId));
      }
      


    });
  }
  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  exportexcel(): void {
    /* table id is passed over here */
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  getFullInfoById(objectId: number){
   this.service.getMapFullInfo(objectId).subscribe((data) => {
    console.log(data);
      var devices = new Array<Device>();
      devices.push(data);
      this.dataSource = new MatTableDataSource(devices);
   },
    err => {
      console.log(err)
    }
   )
   
  }
  userInfo(){
    this.service.getUserProfile().subscribe((data) => {
      this.userDetails = data;
      this.userId = this.userDetails['id'];
      this.getFullInfo();
    },
    err => console.log(err));
  }

  getFullInfo(){
    this.service.getMapAllInfo(this.userId).subscribe((data) => {
        var devices = new Array<Device>();
        devices = data;
        this.dataSource = new MatTableDataSource(devices);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

     },
      err => {
        console.log(err)
      }
     )
  }

 
}

interface Device {
  UserId: string;
  DeviceName: string;
  DeviceId: number;
  DeviceImei: string;
  Lat: string;
  Long: string;
  Adress: string;
  StationId: string;
  Longitude: number;
  Latitude: number;
  OBJECTID: number;
  KARADIS_NO: string;
  RAIONI: string;
  DADGMULI_S: number;
  KVEBIS_WYA: number;
  KARADIS_TI: number;
  MARTVIS_BL: number;
  MRICXVELIS: number;
  MRICXVEL_1: number;
  SIDA_MOWYO: string;
  MDGOMAREOB: number;
  DAMIWEBA: string;
  SENISVNA: string;
  GAMAVALI_K: number;
  NUMBER_OF_: string;
  DISTRICT: string;
  THE_INSTAL: string;
  POWER_SOUR: string;
  TYPE_OF_TH: string;
  MANAGEMENT: string;
  NUMBER_OF1: string;
  TYPE_OF__1: string;
  INTERNAL_M: string;
  GROUNDING: string;
  OUTPUT_CAB: string;
  // Other properties
}