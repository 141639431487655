import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-device-to-user',
  templateUrl: './device-to-user.component.html',
  styleUrls: ['./device-to-user.component.scss'],
})
export class DeviceToUserComponent implements OnInit {
  // tslint:disable-next-line:ban-types
  userList: Object;
  toppingList: any;
  pingMessage: any;
  deviceLog: {};
  devices: any[];
  // tslint:disable-next-line:ban-types
  deviceIdName: Object;
  nonUserDevices: any[];
  righthArrow: boolean;
  leftarrow: boolean;
  deviceName: string;
  userId: any;
  NameDevId: {};
  nameIdForNonUserDevices: any;
  selectedName: string;
  deviceID: string;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getUserList();
  }

  // tslint:disable-next-line:typedef
  getUserList() {
    this.userService.getUserList().subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.userList = res;
        this.toppingList = [];
        this.deviceIdName = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(res).length; i++) {
          this.deviceIdName[res[i].name] = res[i].id;
          // tslint:disable-next-line:forin
          for (const x in res[i]) {
            if (!this.toppingList.includes(res[i].name)) {
              this.toppingList.push(res[i].name);
            }
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  getDeviceList(name: string) {
    this.selectedName = name;

    const userId = this.deviceIdName[name];

    this.userService.PingMessage(userId).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        this.NameDevId = {};

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          this.NameDevId[this.pingMessage[i].name] =
            this.pingMessage[i].deviceId;

          if (!(this.pingMessage[i].name in this.deviceLog)) {
            this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
          }
        }

        this.devices = Object.keys(this.deviceLog).map((key) => {
          const value = this.deviceLog[key];
          return { key: key, value: value };
        });
      },
      (err) => {
        console.log(err);
      }
    );
    this.getNonUserDevices(name);
  }
  // tslint:disable-next-line:typedef
  getNonUserDevices(name: string) {
    this.userId = this.deviceIdName[name];

    console.log(this.userId, 'getNonUserDevices');
    this.userService.PingMessage(this.userId + ';' + 'fromAdmin').subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};

        this.nameIdForNonUserDevices = {};

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          this.nameIdForNonUserDevices[this.pingMessage[i].name] =
            this.pingMessage[i].deviceId;
          if (!(this.pingMessage[i].name in this.deviceLog)) {
            this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
          }
        }

        this.nonUserDevices = Object.keys(this.deviceLog).map((key) => {
          const value = this.deviceLog[key];

          return { key: key, value: value };
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  checked(source: string, deviceName: string, event: object) {
    this.deviceID = deviceName;
    console.log(this.userId, 'checked 2 ');
    // console.log(deviceName, 'checked - 2 ');
  }

  checked1(source: string, deviceName: string, event: object) {
    this.deviceID = deviceName;
    console.log(this.userId, 'checked 1 ');
  }
  test() {
    // console.log(this.nonUserDevices, 'nonUsers');
    // console.log(this.devices, 'devices');
    console.log(this.nameIdForNonUserDevices, 'NameDevId');
  }
  removeDeviceFromUser() {
    this.userService
      .removeDeviceFromUser(this.userId, this.deviceName)
      .subscribe(
        (res) => {
          this.getDeviceList(this.selectedName);
        },
        (err) => {
          console.log(err);
        }
      );
  }
  // tslint:disable-next-line:typedef
  addDeviceToUser(type: string) {
    let id =
      type === 'user' ? this.userId : '6f645521-b7f8-4e75-bb52-1d28b17590e9';
    console.log(id);
    this.userService.addDeviceToUser(id, this.deviceID).subscribe(
      (res) => {
        this.getDeviceList(this.selectedName);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
