<!-- <div >
 <h1>{{deviceLog['865067025224760']['id']}}</h1>
</div> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 mt-1" id="device-info-container">
      <table *ngIf="showInfoTable">
        <thead>
          <tr>
            <!-- <th scope="col">მიმდ. თარიღი</th> -->
            <th scope="col">ობიექტი</th>
            <th scope="col">K1 (მთავარი)</th>
            <th scope="col">კარადა</th>
            <!-- <th scope="col">გაჩ. ID</th> -->
            <th scope="col" colspan="4">თარიღი</th>
            <th scope="col" colspan="3">უკუძაბვა </th>
            <th scope="col" colspan="12">გამავალი დენები</th>

            <!-- <th scope="col">მრიცხველის ჩვენება</th> -->
            <th scope="col" colspan="7">დამ. სენსორები</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- <td data-label="მიმდ. თარიღი">22/06/02 17:06:48</td> -->
            <td
              data-label="კარადის N"
              style="color: darkred; font-weight: bold"
            >
            <div class="d-flex flex-column p-0 line-he">
              <span>
                {{ deviceLog[selectedDeviceId]["name"] }}
              </span>
                <span style="font-size:10px; color:darkblue">
                  SN {{ deviceLog[selectedDeviceId]["seriulI_NO"]}}
                </span>
            </div>
            </td>
            <!-- <td data-label="გაჩერების ID">{{deviceLog[selectedDeviceId]['stationId']}}</td> -->
            <td
              *ngIf="deviceLog[selectedDeviceId]['srOnOf'] == '0'"
              data-label="გარე განათების სტატუსი"
            >
              გამორთულია <br />გრაფიკით
            </td>
            <td
              *ngIf="deviceLog[selectedDeviceId]['srOnOf'] == '2'"
              data-label="გარე განათების სტატუსი"
            >
              გამორთულია <br />ხელით
            </td>
            <td
              *ngIf="deviceLog[selectedDeviceId]['srOnOf'] == '4'"
              data-label="გარე განათების სტატუსი"
            >
              გამორთულია <br />იუზ. გრაფიკით
            </td>

            <td
              *ngIf="deviceLog[selectedDeviceId]['srOnOf'] == '1'"
              data-label="გარე განათების სტატუსი"
            >
              ჩართულია <br />გრაფიკით
            </td>
            <td
              *ngIf="deviceLog[selectedDeviceId]['srOnOf'] == '3'"
              data-label="გარე განათების სტატუსი"
            >
              ჩართულია <br />ხელით
            </td>
            <td
              *ngIf="deviceLog[selectedDeviceId]['srOnOf'] == '5'"
              data-label="გარე განათების სტატუსი"
            >
              ჩართულია <br />იუზ. გრაფიკით
            </td>
            <td
              data-label="კარადა"
              *ngIf="
                deviceLog[selectedDeviceId]['doorState'].split('.')[0] == '0' ||
                deviceLog[selectedDeviceId]['doorState'].split('.')[0] == '2'
              "
            >
              დახურულია
            </td>
            <td
              data-label="კარადა"
              *ngIf="
                deviceLog[selectedDeviceId]['doorState'].split('.')[0] == '1' ||
                deviceLog[selectedDeviceId]['doorState'].split('.')[0] == '3'
              "
            >
              ღიაა
            </td>
            <td data-label="მიმდინარე დრო">
              <span class="lg-screen">რეალ: <br /></span>
              {{ serverTime | date:'yy/MM/dd, HH:mm:ss' }}
            </td>
            <td data-label="თარიღი">
              <span class="lg-screen">მიმდ: <br /></span>
              {{ deviceLog[selectedDeviceId]["deviceDateTime"] }}
            </td>
            <td data-label="ჩართვის დრო">
              <span class="lg-screen"> ჩართვა: <br /></span>
<!--              {{-->
<!--                deviceLog[selectedDeviceId][-->
<!--                  "plannedGraphicTurnOnOffTime"-->
<!--                ].split("-")[0]-->
<!--              }}-->

              {{ updatedOnTime }}

            </td>
            <!-- <td data-label="დღიური გამორთვის დრო">გამორთვა: <br>  {{deviceLog[selectedDeviceId]['plannedGraphicTurnOnOffTime'].split(',')[1]}}</td> -->
            <td data-label="გამორთვის დრო">
              <span class="lg-screen"> გამორთვა: <br /></span>
              {{
                updatedOffTime
              }}

            </td>

            <!-- <td
              data-label="დენი A ფაზაზე"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'ia')
              "
            >
              <span class="Ia lg-screen">Ia <br /></span
              >{{ deviceLog[selectedDeviceId]["ia"] }}
            </td>
            <td
              data-label="დენი B ფაზაზე"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'ib')
              "
            >
              <span class="Ia lg-screen">Ib <br /></span
              >{{ deviceLog[selectedDeviceId]["ib"] }}
            </td>
            <td
              data-label="დენი C ფაზაზე"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'ic')
              "
            >
              <span class="Ia lg-screen">Ic <br /></span
              >{{ deviceLog[selectedDeviceId]["ic"] }}
            </td> -->

    <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'a')
              "
              data-label="უკუძაბვა A ფაზაზე"
            >
              <span class="Ia lg-screen">A <br /></span
              >{{ deviceLog[selectedDeviceId]["ukudzabvaA"] == 'N' ? 'N': deviceLog[selectedDeviceId]["ukudzabvaA"] | number : "1.0-1" }}
            </td>
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'b')
              "
              data-label="უკუძაბვა B ფაზაზე"
            >
              <span class="Ia lg-screen">B <br /></span
              >{{ deviceLog[selectedDeviceId]["ukudzabvaB"]  == 'N' ? 'N': deviceLog[selectedDeviceId]["ukudzabvaB"] | number : "1.0-1"}}
            </td>
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'c')
              "
              data-label="უკუძაბვა C ფაზაზე"
            >
              <span class="Ia lg-screen">C <br /></span
              >{{ deviceLog[selectedDeviceId]["ukudzabvaC"] == 'N' ? 'N': deviceLog[selectedDeviceId]["ukudzabvaC"] | number : "1.0-1" }}
            </td>


            <td
              data-label="I1 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i1')
              "
            >
              <span class="i lg-screen">i1 <br /></span>
              {{ deviceLog[selectedDeviceId]["i1"]  == 'N' ? 'N': deviceLog[selectedDeviceId]["i1"] | number : "1.0-1"  }}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I2 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i2')
              "
            >
              <span class="i lg-screen">i2 <br /></span>
              {{ deviceLog[selectedDeviceId]["i2"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i2"] | number : "1.0-1"  }}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I3 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i3')
              "
            >
              <span class="i lg-screen">i3 <br /></span>
              {{ deviceLog[selectedDeviceId]["i3"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i3"] | number : "1.0-1"}}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I4 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i4')
              "
            >
              <span class="i lg-screen">i4 <br /></span>
              {{ deviceLog[selectedDeviceId]["i4"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i4"] | number : "1.0-1" }}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I5 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i5')
              "
            >
              <span class="i lg-screen">i5 <br /></span>
              {{ deviceLog[selectedDeviceId]["i5"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i5"] | number : "1.0-1" }}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I6 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i6')
              "
            >
              <span class="i lg-screen">i6 <br /></span>
              {{ deviceLog[selectedDeviceId]["i6"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i6"] | number : "1.0-1" }}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I7 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i7')
              "
            >
              <span class="i lg-screen">i7 <br /></span>
              {{ deviceLog[selectedDeviceId]["i7"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i7"] | number : "1.0-1"}}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I8 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i8')
              "
            >
              <span class="i lg-screen">i8 <br /></span>
              {{ deviceLog[selectedDeviceId]["i8"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i8"] | number : "1.0-1"}}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I9 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i9')
              "
            >
              <span class="i lg-screen">i9 <br /></span>
              {{ deviceLog[selectedDeviceId]["i9"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i9"] | number : "1.0-1"}}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I10 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i10')
              "
            >
              <span class="i lg-screen">i10 <br /></span>
              {{ deviceLog[selectedDeviceId]["i10"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i10"] | number : "1.0-1"}}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I11 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i11')
              "
            >
              <span class="i lg-screen">i11 <br /></span>
              {{ deviceLog[selectedDeviceId]["i11"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i11"] | number : "1.0-1"}}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>
            <td
              data-label="I12 სენსორი"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'i12')
              "
            >
              <span class="i lg-screen">i12 <br /></span>
              {{ deviceLog[selectedDeviceId]["i12"] == 'N' ? 'N': deviceLog[selectedDeviceId]["i12"] | number : "1.0-1"}}
              <!-- <input type="number" [value]="deviceLog[selectedDeviceId]['current']"  style="width:50px;"> -->
            </td>

            <!-- <td
              data-label="მრიცხველის ჩვენება"
              style="cursor: pointer"
              (click)="openBottomSheet1(deviceLog[selectedDeviceId]['imei'])"
            >
              {{ deviceLog[selectedDeviceId]["numeratorCount"] }}
            </td> -->


            <td
            style="cursor: pointer"
            (click)="
              openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'rh')
            "
            data-label="ტენიანობის სენსორი"
          >
            <span class="Ia lg-screen" title="ტენიანობა">RH <br /></span
            >{{ deviceLog[selectedDeviceId]["airHumidity"] == 'N' ? 'N': deviceLog[selectedDeviceId]["airHumidity"] | number : "1.0-1"}}
          </td>
          <td
            style="cursor: pointer"
            (click)="
              openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'cO2')
            "
            data-label="დაბინძურების სენსორი"
          >
            <span class="Ia lg-screen" title="დაბინძურება">CO<sub>2</sub> <br /></span
            >{{ deviceLog[selectedDeviceId]["airPollution"] == 'N' ? 'N': deviceLog[selectedDeviceId]["airPollution"] | number : "1.0-1"}}
          </td>
          <td
            style="cursor: pointer"
            (click)="
              openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'co')
            "
            data-label="ტემპერატურის სენსორი"
          >
            <span class="Ia lg-screen" title="ტემპერატურის სენსორი"
              >&#8451; <br /></span
            >{{ deviceLog[selectedDeviceId]["airTemp"] == 'N' ? 'N': deviceLog[selectedDeviceId]["airTemp"] | number : "1.0-1"}}
          </td>
          <td
            style="cursor: pointer"
            (click)="
              openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'dB')
            "
            data-label="ხმაურის სენსორი"
          >
            <span class="Ia lg-screen" title="დაბინძურება">dB <br /></span>
            {{ deviceLog[selectedDeviceId]["noiseLvlv"] == 'N' ? 'N': deviceLog[selectedDeviceId]["noiseLvlv"] | number : "1.0-1"}}
          </td>

          <td
            style="cursor: pointer"
            (click)="
              openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'nO2')
            "
            data-label="აზოტის დიოქსიდი სენსორი"
          >
            <span class="Ia lg-screen" title="აზოტის დიოქსიდის სენსორი"
              >NO<sub>2</sub> <br
            /></span>
            {{
              deviceLog[selectedDeviceId]["no2"] == ""
                ? 0
                : deviceLog[selectedDeviceId]["no2"]  | number : "1.0-1"
            }}
          </td>
          <td
            style="cursor: pointer"
            (click)="
              openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'lUX')
            "
            data-label="განათებულობა"
          >
            <span class="Ia lg-screen" title="განათებულობა">Lux <br /></span>
            {{ deviceLog[selectedDeviceId]["lux"]  == 'N' ? 'N': deviceLog[selectedDeviceId]["lux"] | number : "1.0-1"}}
          </td>

          <td
          style="cursor: pointer"
          data-label="დენი"
        >
          <span class="Ia lg-screen" title="დენი">დენი <br /></span>
          {{ deviceLog[selectedDeviceId]["currentSensor"] == 'N' ? 'N': deviceLog[selectedDeviceId]["currentSensor"] | number : "1.0-1"}}
        </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="showInfoTable">
        <thead>
          <tr>
            <!-- <th scope="col">მიმდ. თარიღი</th> -->
            <th scope="col" colspan="2">დამატებითი კონტაქტორები</th>
            <!-- <th scope="col">გაჩ. ID</th> -->
            <th scope="col" colspan="3">ძაბვა</th>
            <th scope="col" colspan="3">დენი</th>

            <!-- <th scope="col" colspan="6">დამ. სენსორები</th> -->
            <th scope="col">მრიცხველის ჩვენება</th>
            <th scope="col" title="ინტერვალი">ინტერვალი</th>
            <th scope="col">GSM</th>
            <th scope="col" colspan="2">ძაბვა</th>
            <th scope="col" colspan="2">კომენტარი</th>
            <th scope="col">დაცვა</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- <td>
              <app-clock></app-clock>
            </td> -->
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet2(deviceLog[selectedDeviceId]['imei'], 'K1')
              "
              data-label="K2 კონტაქტორი"
            >
              <span class="Ia lg-screen">K2 <br /></span
              >{{
                deviceLog[selectedDeviceId]["k1"] == "0"
                  ? "გამორთული გრაფიკით"
                  : deviceLog[selectedDeviceId]["k1"] == "1"
                  ? "ჩართული გრაფიკით"
                  : deviceLog[selectedDeviceId]["k1"] == "2"
                  ? "გამორთული ხელით"
                  : deviceLog[selectedDeviceId]["k1"] == "3"
                  ? "ჩართულია ხელით"
                  : deviceLog[selectedDeviceId]["k1"] == "4"
                  ? "გამორთულია, იუზერის გრაფიკით"
                  : deviceLog[selectedDeviceId]["k1"] == "5"
                  ? "ჩართულია და მუშაობს იუზერის გრაფიკით"
                  : ""
              }}
            </td>

            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet2(deviceLog[selectedDeviceId]['imei'], 'K2')
              "
              data-label="K3 კონტაქტორი"
            >
              <span class="Ia lg-screen">K3 <br /></span
              >{{
                deviceLog[selectedDeviceId]["k2"] == "0"
                  ? "გამორთული გრაფიკით"
                  : deviceLog[selectedDeviceId]["k2"] == "1"
                  ? "ჩართული გრაფიკით"
                  : deviceLog[selectedDeviceId]["k2"] == "2"
                  ? "გამორთული ხელით"
                  : deviceLog[selectedDeviceId]["k2"] == "3"
                  ? "ჩართულია ხელით"
                  : deviceLog[selectedDeviceId]["k2"] == "4"
                  ? "გამორთულია, იუზერის გრაფიკით"
                  : deviceLog[selectedDeviceId]["k2"] == "5"
                  ? "ჩართულია და მუშაობს იუზერის გრაფიკით"
                  : ""
              }}
            </td>
            <!--
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet2(deviceLog[selectedDeviceId]['imei'], 'K3')
              "
            >
              <span class="Ia">K3</span> <br />{{
                deviceLog[selectedDeviceId]["k3"] == "0"
                  ? "გამორთული გრაფიკით"
                  : deviceLog[selectedDeviceId]["k3"] == "1"
                  ? "ჩართული გრაფიკით"
                  : deviceLog[selectedDeviceId]["k3"] == "2"
                  ? "გამორთული ხელით"
                  : deviceLog[selectedDeviceId]["k3"] == "3"
                  ? "ჩართულია ხელით"
                  : deviceLog[selectedDeviceId]["k3"] == "4"
                  ? "გამორთულია, იუზერის გრაფიკით"
                  : deviceLog[selectedDeviceId]["k3"] == "5"
                  ? "ჩართულია და მუშაობს იუზერის გრაფიკით"
                  : ""
              }}
            </td> -->

            <td
              style="cursor: pointer"
              data-label="ძაბვა A ფაზაზე"
              title="ზედა ზღვარი {{ deviceLog[selectedDeviceId]['voltage'] }}"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'ua')
              "
            >
              <span class="u lg-screen">Ua <br /></span>
              {{ deviceLog[selectedDeviceId]["ua"] == 'N' ? 'N': deviceLog[selectedDeviceId]["ua"] | number : "1.0-1" }}
            </td>
            <td
              data-label="ძაბვა B ფაზაზე"
              style="cursor: pointer"
              title="ზედა ზღვარი {{ deviceLog[selectedDeviceId]['voltage'] }}"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'ub')
              "
            >
              <span class="u lg-screen">Ub <br /></span>
              {{ deviceLog[selectedDeviceId]["ub"] == 'N' ? 'N': deviceLog[selectedDeviceId]["ub"] | number : "1.0-1" }}
            </td>
            <td
              style="cursor: pointer"
              data-label="ძაბვა C ფაზაზე"
              title="ზედა ზღვარი {{ deviceLog[selectedDeviceId]['voltage'] }}"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'uc')
              "
            >
              <span class="u lg-screen">Uc <br /></span>
              {{ deviceLog[selectedDeviceId]["uc"] == 'N' ? 'N': deviceLog[selectedDeviceId]["uc"] | number : "1.0-1" }}
            </td>

            <!-- <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'a')
              "
              data-label="უკუძაბვა A ფაზაზე"
            >
              <span class="Ia lg-screen">A <br /></span
              >{{ deviceLog[selectedDeviceId]["ukudzabvaA"] }}
            </td>
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'b')
              "
              data-label="უკუძაბვა B ფაზაზე"
            >
              <span class="Ia lg-screen">B <br /></span
              >{{ deviceLog[selectedDeviceId]["ukudzabvaB"] }}
            </td>
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'c')
              "
              data-label="უკუძაბვა C ფაზაზე"
            >
              <span class="Ia lg-screen">C <br /></span
              >{{ deviceLog[selectedDeviceId]["ukudzabvaC"] }}
            </td> -->

    <td
              data-label="დენი A ფაზაზე"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'ia')
              "
            >
              <span class="Ia lg-screen">Ia <br /></span
              >{{ deviceLog[selectedDeviceId]["ia"] == 'N' ? 'N': deviceLog[selectedDeviceId]["ia"] | number : "1.0-1"}}
            </td>
            <td
              data-label="დენი B ფაზაზე"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'ib')
              "
            >
              <span class="Ia lg-screen">Ib <br /></span
              >{{ deviceLog[selectedDeviceId]["ib"] == 'N' ? 'N': deviceLog[selectedDeviceId]["ib"] | number : "1.0-1" }}
            </td>
            <td
              data-label="დენი C ფაზაზე"
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'ic')
              "
            >
              <span class="Ia lg-screen">Ic <br /></span
              >{{ deviceLog[selectedDeviceId]["ic"] == 'N' ? 'N': deviceLog[selectedDeviceId]["ic"] | number : "1.0-1" }}
            </td>

            <!-- <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'rh')
              "
              data-label="ტენიანობის სენსორი"
            >
              <span class="Ia lg-screen" title="ტენიანობა">RH <br /></span
              >{{ deviceLog[selectedDeviceId]["airHumidity"] }}
            </td>
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'cO2')
              "
              data-label="დაბინძურების სენსორი"
            >
              <span class="Ia lg-screen" title="დაბინძურება">CO2 <br /></span
              >{{ deviceLog[selectedDeviceId]["airPollution"] }}
            </td>
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'co')
              "
              data-label="ტემპერატურის სენსორი"
            >
              <span class="Ia lg-screen" title="ტემპერატურის სენსორი"
                >Co <br /></span
              >{{ deviceLog[selectedDeviceId]["airTemp"] }}
            </td>
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'dB')
              "
              data-label="ხმაურის სენსორი"
            >
              <span class="Ia lg-screen" title="დაბინძურება">dB <br /></span>
              {{ deviceLog[selectedDeviceId]["noiseLvlv"] }}
            </td>

            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'nO2')
              "
              data-label="აზოტის დიოქსიდი სენსორი"
            >
              <span class="Ia lg-screen" title="აზოტის დიოქსიდის სენსორი"
                >NO2 <br
              /></span>
              {{
                deviceLog[selectedDeviceId]["no2"] == ""
                  ? 0
                  : deviceLog[selectedDeviceId]["no2"]
              }}
            </td>
            <td
              style="cursor: pointer"
              (click)="
                openBottomSheet(deviceLog[selectedDeviceId]['imei'], 'lUX')
              "
              data-label="განათებულობა"
            >
              <span class="Ia lg-screen" title="განათებულობა">Lux <br /></span>
              {{ deviceLog[selectedDeviceId]["lux"] }}
            </td> -->
            <td
            data-label="მრიცხველის ჩვენება"
            style="cursor: pointer"
            (click)="openBottomSheet1(deviceLog[selectedDeviceId]['imei'])"
          >
            {{ deviceLog[selectedDeviceId]["numeratorCount"] == 'N' ? 'N': deviceLog[selectedDeviceId]["numeratorCount"] | number : "1.0-3" }}
          </td>
            <td
            style="cursor: pointer"
            data-label="ინტერვალი"
          >
            {{ deviceLog[selectedDeviceId]["interval"] }}
          </td>
          <td data-label="GSM/GPRS სიგნალის დონე">
            {{ deviceLog[selectedDeviceId]["csq"] + "%" }}
          </td>
          <td
          style="cursor: pointer"
          data-label="ელემენტის ძაბვა"
        >
        <span class="Ia lg-screen" title="ელემენტი">ელემენტი <br /></span>
          {{ deviceLog[selectedDeviceId]["elementWalt"] == 'N' ? 'N': deviceLog[selectedDeviceId]["elementWalt"] | number : "1.0-1"}}
        </td>

        <td
        style="cursor: pointer"
        data-label="კვების ბლოკის ძაბვა"
      >
      <span class="Ia lg-screen" title="კვების ბლოკი">კვების ბლოკი<br /></span>
        {{ deviceLog[selectedDeviceId]["waltMainPower"] == 'N' ? 'N': deviceLog[selectedDeviceId]["waltMainPower"] | number : "1.0-1"}}
      </td>


      <td
       colspan="2"
       data-label="კომენტარი"
       [title]="deviceLog[selectedDeviceId]['comment']"
            >
              {{
                deviceLog[selectedDeviceId]['comment'] | slice: 0:30
              }}
              <strong *ngIf="deviceLog[selectedDeviceId]['comment'].length > 30">...</strong>
      </td>
      <td  data-label="დაცვა">
            <!-- <div
              class="btn btn-success save"
              (click)="
                saveNewComment(
                  textvalue.value,
                  deviceLog[selectedDeviceId]['imei']
                )
              "
            >
              დაცვა
            </div> -->
              <!-- <mat-slide-toggle
                [disabled]="disableSignal"
                [(ngModel)]="defenceChanged"
                (change)="changeDefence(defenceChanged,$event)"
                [checked]="deftoggle"
                >{{ defType }}
              </mat-slide-toggle> -->
           <p class="mb-2"> {{this.defType }}</p>
      </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
