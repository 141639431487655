import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-device-info',
  templateUrl: './edit-device-info.component.html',
  styleUrls: ['./edit-device-info.component.scss'],
})
export class EditDeviceInfoComponent implements OnInit {
  toppings = new FormControl();
  topping: string;
  toppingList: any[] = [];
  selectedDevice;
  selectedDeviceName: string;
  adress: any;
  selectedDeviceAdress: string;
  selectedDeviceLat;
  selectedDeviceLong;
  selectedDeviceStationId;
  selectedSerialNumber;
  userDetails: any;
  userId: any;
  mapCordinates: any;
  deviceLog: any;
  noramDeviceLog: any = [];
  originalNoramDeviceLog: any = [];
  pingMessage: any;
  deviceFullLog: any;
  selectedDeviceImei: any;
  updateAnswer: any;
  selectedName2: any;
  selectedComment = '';
  anotherArray = [];
  selectedObjectId = '';
  deviceInfo = {};
  deviceOrderByImei = {};
  Karada;
  selectedKarada = '';
  disableAddButton = false;
  oldName: any;
  selectedDeviceId: any;
  SerialNumber: string = '';

  constructor(private service: UserService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getMapCordinates(this.userId);
        this.getPingMessage(this.userId);
        this.avalaibleDevices();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x + ';' + 'fromAdmin').subscribe(
      (res) => {
        console.log(res);
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        this.deviceFullLog = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          let obj = {
            name: this.pingMessage[i].name,
            stationId: this.pingMessage[i].stationId,
            adress: this.pingMessage[i].adress,
            imei: this.pingMessage[i].imei,
            deviceId: this.pingMessage[i].deviceId,
            seriulI_NO: this.pingMessage[i].seriulI_NO,
            karadiS_NO: this.pingMessage[i].karadiS_NO,
            comment: this.pingMessage[i].comment,
          };
          this.noramDeviceLog.push(obj);
          this.originalNoramDeviceLog = [...this.noramDeviceLog];

          // if (
          //   !(
          //     this.pingMessage[i].name +
          //       '__ ' +
          //       this.pingMessage[i].stationId +
          //       ' ' +
          //       this.pingMessage[i].adress +
          //       ' ' +
          //       this.pingMessage[i].imei in
          //     this.deviceLog
          //   )
          // ) {
          //   this.deviceLog[
          //     this.pingMessage[i].name +
          //       '__ ' +
          //       this.pingMessage[i].stationId +
          //       ' ' +
          //       this.pingMessage[i].adress +
          //       ' ' +
          //       this.pingMessage[i].imei +
          //       this.pingMessage[i].deviceId
          //   ] = this.pingMessage[i].imei;
          // } else {
          //   this.deviceLog[
          //     this.pingMessage[i].name +
          //       '__ ' +
          //       this.pingMessage[i].stationId +
          //       ' ' +
          //       this.pingMessage[i].adress +
          //       this.pingMessage[i].deviceId +
          //       ' ' +
          //       this.pingMessage[i].imei
          //   ] = this.pingMessage[i].imei;
          // }
          this.deviceFullLog[this.pingMessage[i].imei] = this.pingMessage[i];
        }
        this.toppingList = Object.keys(this.deviceLog);

        this.anotherArray = Object.keys(this.deviceLog);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  getMapCordinates(userName: string) {
    this.service.mapCordinates(userName).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.mapCordinates = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.mapCordinates[res[i].deviceName] = res[i];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  onDeviceSelect(x) {
    // console.log(this.selectedKarada);
    // console.log(x);
    // console.log(this.deviceInfo);

    const imei = this.deviceLog[x];

    // console.log(this.selectedDevice);
    console.log(x);
    this.SerialNumber = x.seriulI_NO;
    console.log(this.SerialNumber);
    // this.Karada = this.deviceOrderByImei[imei]['karadiS_NO'];
    //  var deviceName: string = x[0].split('__ ')[0].replace(/\s/g, "");

    this.selectedComment = x.comment;
    this.selectedDeviceName = x.name;
    this.selectedDeviceImei = x.imei;
    this.selectedName2 = x;
    this.selectedDeviceStationId = x.stationId;
    this.selectedSerialNumber = x.serialNumber;
    this.selectedKarada = x.karadiS_NO;
    this.oldName = x.name;
    this.selectedDeviceId = x.deviceId;
  }
  // tslint:disable-next-line:typedef
  editDeviceInfo(selectedDeviceName) {
    var deviceOrder = localStorage.getItem('DeviceOrder');
    var currentDeviceOrder: string = deviceOrder.replace(
      this.oldName + '__' + this.selectedDeviceId,
      selectedDeviceName + '__' + this.selectedDeviceId
    );

    if (currentDeviceOrder !== undefined && currentDeviceOrder !== '') {
      localStorage.setItem('DeviceOrder', currentDeviceOrder);
    }
    // tslint:disable-next-line:prefer-const

    const editDevice = {
      DeviceName: this.selectedDeviceName,
      DeviceImei: this.selectedDeviceImei,
      Comment: this.selectedComment,
      DeviceId: this.selectedDeviceId,
      KaradisId: this.selectedKarada,
      SerialNumber: this.SerialNumber,
    };
    this.service.editDeviceInfo(editDevice).subscribe(
      (res) => {
        this.updateAnswer = res;
        console.log(res);
      },
      (err) => {
        if (err.error.text === 'success') {
          this.toastr.success('ბრძანება გაიგზავნა');
        } else {
          this.toastr.error('ბრძანება არ გაიგზავნა');
        }
      }
    );
  }

  filterListCareUnit(val) {
    let combinedArray = this.originalNoramDeviceLog.flat();
    console.log(val);

    if (!val.trim()) {
      this.noramDeviceLog = this.originalNoramDeviceLog.flat(); // Reset to the original structure
    } else {
      this.noramDeviceLog = combinedArray
        .filter((item: any) => {
          return (
            item.name && item.name.toLowerCase().includes(val.toLowerCase())
          );
        })
        .flat();
    }

    console.log(this.noramDeviceLog);
  }
  onBookChange(event: any) {
    this.selectedKarada = event.value;
    this.selectedObjectId = this.deviceInfo[this.selectedKarada]['deviceId'];
    console.log(this.deviceInfo[this.selectedKarada]);
    console.log('valeri');
  }

  avalaibleDevices() {
    this.service.getMapAllInfo(this.userId).subscribe(
      (res) => {
        for (var i = 0; i < Object.keys(res).length; i++) {
          this.deviceInfo[res[i]['karadiS_NO']] = res[i];
          this.deviceOrderByImei[res[i]['deviceImei']] = res[i];
        }
      },
      (err) => {}
    );
  }

  checkImei(imei) {
    const data = {
      Imei: imei,
      // Imei: '',
      // UserId: '',
      // Name: '',
      // DeviceId: '',
      // Lat: '',
      // Long: '',
      // Adress: '',
      // ObjectId: '',
    };
    this.service.checkImei(data).subscribe(
      (res) => {
        if (res == 200) {
          this.disableAddButton = true;
          this.toastr.error('Imei დაკავებულია');
        }
      },
      (err) => {
        if (err.error == 404) {
          this.disableAddButton = false;
        }
      }
    );
  }
}
