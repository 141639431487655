import { Component, OnInit } from '@angular/core';
import { ShareDataService } from '../services/share-data.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-device-kontactors',
  templateUrl: './device-kontactors.component.html',
  styleUrls: ['./device-kontactors.component.scss'],
})
export class DeviceKontactorsComponent implements OnInit {


  userDetails: any;
  userId: any;
  pingMessage: any;
  deviceLog: {};
  deviceFullLog: {};
  targetData: any;
  selectedDeviceId: string;
  selectedDeviceData: {};
  Pa: any;
  Pb: any;
  Pc: any;
  Pfa: any;
  Pfb: any;
  Pfc: any;
  Pt: any;
  selectedContaktor: string;
  ChartvisDroisWanacvleba: any;
  GamortvisDroisWanacvleba: any;
  riggaresheChartva: any;
  riggaresheGamortva: any;



  constructor(
    private service: UserService,
    private shareDataService: ShareDataService
  ) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
      },
      (err) => {
        console.log(err);
      }
    );



    this.targetData =
      this.shareDataService.currentApprovalStageMessage4.subscribe((msg) => {
        this.selectedDeviceId = msg.split(';')[0];
        this.selectedContaktor = msg.split(';')[1]?.toLocaleLowerCase();
        this.getPingMessage(this.userId);
      });


  }



  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x + ';' + 'fromAdmin').subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        this.deviceFullLog = {};
        this.selectedDeviceData = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          this.deviceFullLog[this.pingMessage[i].imei] = this.pingMessage[i];
        }
        // tslint:disable-next-line:prefer-const
        this.selectedDeviceData = this.deviceFullLog[this.selectedDeviceId];
        console.log(this.selectedDeviceData);
        console.log(this.selectedContaktor);
        this.ChartvisDroisWanacvleba =
          this.selectedDeviceData[
            this.selectedContaktor + 'ChartvisDroisWanacvleba'
          ];
        this.GamortvisDroisWanacvleba =
          this.selectedDeviceData[
            this.selectedContaktor + 'GamortvisDroisWanacvleba'
          ];
        this.riggaresheChartva =
          this.selectedDeviceData[this.selectedContaktor + 'rigGaresheChartva'];
        this.riggaresheGamortva =
          this.selectedDeviceData[
            this.selectedContaktor + 'rigGaresheGamortva'
          ];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
