import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ShareDataService } from './services/share-data.service';
import { UserService } from './services/user.service';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { AudioService } from './services/audio.service';
import { AudioReferenceDataType } from './types/AudioTypes';
import { EventHistoryService } from './services/event-histori';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'newDevLcp';
  // EventHistory: any[];
  // EventHistoryId: number;
  // deviceErrors = [];
  // eventDbDict = {};
  audioSrc = '';
  // eventInterval: any;
  // userDetails: any;
  // userId: any;
  // debugInterval: any;
  // difference = 0;
  // newNotifLength = 0;
  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private sharData: ShareDataService,
    private audioService: AudioService,
    public eventHistoryService: EventHistoryService
  ) {
    // ეს ვარიაბლეი ინახავს ევენთი ID-ს რასაც შემდეგ ვიყენებთ შესამოწმებლად თუ deviceError  სიაში უკვე არსებობს
    // იგივე ID-ის მქონე ერრორი, აუდიო არ ჩაირთვება, თუ არ არსებობს, მაშინ აუდიო ჩაირთვება და ლოკალ სტორიჯზე ventHistoryLength-განახლდება\
   
  }

  ngOnInit(): void {
    // this.eventHistoryService.newNotifLength = Number(
    //   localStorage.getItem('eventHistoryLength')
    // );
    // this.getUserProfile();

    // this.eventHistoryService.eventInterval = setInterval(() => {
    //   this.getUserProfile();
    // }, 5000);
    // // es
    this.createOnline$().subscribe((isOnline) => {
      if (isOnline == false) {
        this.playSound('nointernet.mp3', 'ინტერნეტი გაითიშა');
        this.toastr.error('ინტერნეტი გაითიშა !!!');
      }
    });

    setTimeout(() => {
      this.audioSrc = this.eventHistoryService.audioSrc;
      console.log(this.audioSrc);
    }, 500);
  }

  ngOnDestroy() {
    if (this.eventHistoryService.debugInterval) {
      clearInterval(this.eventHistoryService.eventInterval);
    }
  }

  CheckAudioEvent(
    newindex: number,
    audio: any,
    errorMessage: string[], // errorMessage არის სტრინგების არრაი რომელიც შეიცავს რეფრენც ერრორებს,
    audioLocation: string
  ) {
    // ვამოწმებთ თუ ბაზიდან შემოსული ერრორი ემთხვევა ამ სიაში არსებულ ერრორს და იმის მიხედვით
    // ვსახავთ შესაბამის ერრორს ეკრანზე და ვრთავთ აუდიოს this.playSound-ფუნცქის დაახმარებით

    if (
      errorMessage.find((val: string) => {
        return this.eventHistoryService.EventHistory[newindex][
          'evenet'
        ].includes(val);
      })
    ) {
      if (audio.paused) {
        this.playSound(
          audioLocation,
          this.eventHistoryService.EventHistory[newindex]['evenet']
        );
        console.log(audioLocation, errorMessage);
      }

      this.toastr.info(
        this.eventHistoryService.EventHistory[newindex]['evenet'] +
          ' ' +
          this.eventHistoryService.EventHistory[newindex]['dateTime'].replace(
            'T',
            ' '
          )
      );
    }
  }

  getDeviceEvents(userId) {
    this.service.deviceEvents(userId).subscribe(
      (res: any) => {
        // console.log('DEVISEHISTORY', this.EventHistory);
        this.eventHistoryService.EventHistory = res;
        // this.eventHistoryService.EventHistory = this.audioService.FakeObj;
        // // console.log(
        // //   this.audioService.FakeObj,
        // //   '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<'
        // // );
        if (this.eventHistoryService.EventHistory.length > 0) {
          this.eventHistoryService.EventHistoryId = Number(
            this.eventHistoryService.EventHistory[0].id
          );
          this.eventHistoryService.eventDbDict = {};
          let audio = document.getElementById('audio') as HTMLAudioElement;

          // if (this.eventHistoryService.newNotifLength === 0)
          //   this.eventHistoryService.newNotifLength =
          //     this.eventHistoryService.EventHistoryId;
          //

          let newindex = 0;
          console.log(
            this.eventHistoryService.newNotifLength !==
              this.eventHistoryService.EventHistory[0].id
          );
          if (
            this.eventHistoryService.newNotifLength !==
            this.eventHistoryService.EventHistory[0].id
          ) {
            // ციკლს ვუკეთებთ რეფრენც დეტას რომ გავუშვათ აუდიო ბაზიდან წამოსული ერორების საფუძველზე
            this.audioService.AudioRefrenceDataArray.forEach(
              (val: AudioReferenceDataType) => {
                const { id, errorMessage, audioLink } = val;

                // პარამეტრებს ვატარებთ ლოკალურ ფუნქციაში რომელიც პასუხისმგებელია აუდიოს გაშვებაზე
                this.CheckAudioEvent(newindex, audio, errorMessage, audioLink);
              }
            );
            if (
              this.eventHistoryService.EventHistory[newindex][
                'evenet'
              ].includes('ძაბვა ნორმაზე') &&
              this.eventHistoryService.EventHistory[newindex][
                'evenet'
              ].includes('მაღალია')
            ) {
              if (audio.paused) {
                this.playSound(
                  'qselshiDasashvebzeMagaliDzabvaa.mp3',
                  this.eventHistoryService.EventHistory[newindex]['evenet']
                );
              }
              this.toastr.info(
                this.eventHistoryService.EventHistory[newindex]['evenet'] +
                  ' ' +
                  this.eventHistoryService.EventHistory[newindex][
                    'dateTime'
                  ].replace('T', ' ')
              );
            }
            if (
              this.eventHistoryService.EventHistory[newindex][
                'evenet'
              ].includes('განათებულობა ზღვარზე დაბალია')
            ) {
              const imei =
                this.eventHistoryService.EventHistory[newindex].deviceImei;
              console.log(imei);
              this.service.getLastPingMessage(imei).subscribe(
                (res) => {
                  if (
                    (res['srOnOf'] == 0 ||
                      res['srOnOf'] == 2 ||
                      res['srOnOf'] == 4 ||
                      res['srOnOf'] == 6) &&
                    (res['lux'] != '0.0' || res['lux'] != '0')
                  ) {
                    if (audio.paused) {
                      this.playSound(
                        'ganatebuloba_dabalia.mp3',
                        this.eventHistoryService.EventHistory[newindex][
                          'evenet'
                        ]
                      );
                    }
                    this.toastr.info(
                      this.eventHistoryService.EventHistory[newindex][
                        'evenet'
                      ] +
                        ' ' +
                        this.eventHistoryService.EventHistory[newindex][
                          'dateTime'
                        ].replace('T', ' ')
                    );
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            }
          }

          // ახალ აიდის ვინახავთ ლოკალ სტორიჯში
          localStorage.setItem(
            'eventHistoryLength',
            this.eventHistoryService.EventHistory[0].id
          );
          let eventLength = Object.keys(
            this.eventHistoryService.EventHistory
          ).length;
          this.eventHistoryService.deviceErrors = [];
          for (let i = 0; i < eventLength; i++) {
            let DateTimeReplace =
              this.eventHistoryService.EventHistory[i]['evenet'] +
              ' ' +
              this.eventHistoryService.EventHistory[i]['dateTime'].replace(
                'T',
                ' '
              );
            if (
              !this.eventHistoryService.deviceErrors.includes(DateTimeReplace)
            ) {
              this.eventHistoryService.deviceErrors.push(DateTimeReplace);
            }
            this.eventHistoryService.eventDbDict[DateTimeReplace] =
              this.eventHistoryService.EventHistory[i]['id'];
          }
          this.sharData.deviceErrors(this.eventHistoryService.deviceErrors);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  playSound(mp3File, error) {
    // ვამოწმებთ თუ იგივე დევაიცი იგივე ემეითი და ერრორით (evenet) უკვე იყო ერრორ სტაკში
    //  evenet - უნდა იყოს event, მე არ დამიწერია ეს მზეობა.
    let newArray = this.eventHistoryService.EventHistory.map(
      ({ deviceImei, evenet, dateTime }) => {
        let dateObject = new Date(dateTime);
        let dataInMiliSeconds = dateObject.getTime();
        let currentDate = new Date();
        let currentMiliSeconds = currentDate.getTime();
        if (dataInMiliSeconds > currentMiliSeconds - 30 * 60 * 1000) {
          return { deviceImei, evenet };
        } else {
          return null;
        }
      }
    ).filter((val: any) => val !== null);

    var soundPlay = true;

    var dsableSound = localStorage.getItem('soundDisabled');
    // ამჯერათ ეს ვარიაბლე არ არის გამოყენებაში
    const hasDuplicates = newArray.some((obj, index, array) => {
      // სე ამოწმებს თუ არსებობს დუპლიკატი იმეის და ერრორის მქონე ობიექთი,ერრორების ლისტში
      return (
        array.findIndex(
          (innerObj) =>
            innerObj.deviceImei === obj.deviceImei &&
            innerObj.evenet === obj.evenet
        ) !== index
      );
    });
    if (dsableSound != undefined) {
      var soundArr = JSON.parse(dsableSound);
      if (soundArr != null) {
        var soundUsrArr = soundArr[this.eventHistoryService.userId];
        if (soundUsrArr !== null) {
          if (soundUsrArr.includes(error)) {
            soundPlay = false;
          }
        }
      }
    }

    if (soundPlay == true) {
      this.eventHistoryService.audioSrc =
        '../assets/audio/by-event-name/' + mp3File;

      let audio = document.getElementById('audio') as HTMLAudioElement;
      if (audio.paused) {
        var playPromise = audio.play();
        playPromise
          .then((val) => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            audio.play();
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    }
  }

  getUserProfile() {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.eventHistoryService.userDetails = res;
        this.eventHistoryService.userId =
          this.eventHistoryService.userDetails['id'];
        if (this.eventHistoryService.userId != undefined) {
          this.getDeviceEvents(this.eventHistoryService.userId);
        }
      },
      (err) => {
        this.eventHistoryService.userId = undefined;
      }
    );
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
