<div class="container-fluid">
  <div class="row">
    <div class="col-md-12" id="navbar-container">
      <div id="visible-items">
        <a class="logo" routerLink="/home">
          <img
            src="../../assets/img/LOGO.png"
            alt=""
            style="width: 30px; height: 30px;"
          />
        </a>

        <img
          id="mobile-cta"
          class="mobile-menu"
          src="../../assets/img/menu.svg"
          alt="Open Navigation"
          (click)="openMenu()"
        />
      </div>
      <nav [style.display]="menuButton">
        <img
          id="mobile-exit"
          class="mobile-menu-exit"
          src="../../assets/img/exit.svg"
          alt="Close Navigation"
          (click)="closeMenu()"
        />
        <ul class="primary-nav">
          <li>
            <a
              routerLink="/adminpanel"
              style="color: rgb(10, 240, 87);"
              *ngIf="this.userRole === 'Admin'"
            >
              ადმინისტრატორის პანელი
            </a>
          </li>
          <!-- <li class="current">
                  <a href="#">მოწყობილობები</a></li> -->
          <li>
            <a routerLink="/otherCommands" *ngIf="allowOtherComands == '1'">
              კონფიგურაცია && ბრძანებები
            </a>
          </li>
          <li><a routerLink="/report">რეპორტები</a></li>
          <li><a routerLink="/map">რუკა</a></li>
        </ul>
        <ul class="secondary-nav">
          <li><a routerLink="/home">კონტაქტი</a></li>
          <li><a routerLink="/home">პროფილი</a></li>
        </ul>
        <button
          class="btn btn-danger"
          (click)="onLogout()"
          style="float: right;"
        >
          გამოსვლა
        </button>
      </nav>
    </div>
  </div>
</div>
