<h1>UPLOAD</h1>
<div class="Block">
  <mat-form-field appearance="fill" class="add-device-option" style="margin-right: 5px;">
    <mat-label>მოწყობილობა</mat-label>
    <mat-select [formControl]="toppings" multiple [(ngModel)]="selectedDevice" (selectionChange)="progress = 0; sendedCommandStatus = false">
      <input matInput type="text" (keyup)="filterListCareUnit($event.target.value)" placeholder="ძებნა" style="border: 1px solid rgb(199, 196, 196); width: 99% !important;">
      <mat-select-trigger>
        {{toppings.value ? toppings.value[0] : ''}}
        <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
          (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping.split('__')[0]}}</mat-option>
    </mat-select>
  </mat-form-field>
  <input type='file' (change)="fileChanged($event)" accept=".bin">
  <button class="btn btn-success" (click)="uploadDocument(selectedDevice); start = true">ატვირთვა</button>
  <!-- <button class="btn btn-danger" (click)="sendProgramCmd(selectedDevice)">დაპროგრამება</button> -->
</div>
  <mat-progress-bar mode="determinate" [value]="progress"  class="mb-3 mt-3" [ngClass]="{'complete': sendedCommandStatus}"></mat-progress-bar>
<!-- <button class="btn-danger" (click)="spinnerCancel(selectedDevice)">cancel</button> -->

<p *ngIf="sendedCommandStatus">ბრძანება გაიგზავნა</p>

<p *ngIf="(sendedCommandStatus && !sendedCommandStatus) && (start == true && isSent == '2' && bootResult == 0)">
 მოწყობილობა არ განახლდა
</p>
<p *ngIf="start == true && isSent == '1' && bootResult == 1">
 მოწყობილობა წარმატებით განახლდა
 </p>