<div class="d-flex flex-row justify-content-between mt-3">
  <mat-form-field
  appearance="fill"
  class="add-device-option"
  style="margin-right: 5px"
>
  <mat-label>მოწყობილობა:</mat-label>
  <mat-select
    [formControl]="toppings"
    multiple
    [(ngModel)]="selectedDevice"
    (selectionChange)="onDeviceSelect(selectedDevice)"
  >
  <input matInput type="text" (keyup)="filterListCareUnit($event.target.value)" placeholder="ძებნა" style="border: 1px solid rgb(199, 196, 196); width: 99% !important;">
    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{
      topping
    }}</mat-option>
  </mat-select>
</mat-form-field>
<div>
  <div>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[0].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[1].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[2].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[3].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[4].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[5].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[6].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[7].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[8].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[9].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[10].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[11].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[12].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[13].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[14].result}}</span>


<span style="margin-right: 8px;font-size: 18px;">{{dataSource[15].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[16].result}}</span>
<span style="margin-right: 8px;font-size: 18px;">{{dataSource[17].result}}</span>
  </div>

</div>
<div class="d-flex flex-nowrap" style="height:40px">
  <button (click)="deviceCommands('cmd_request')" class="btn btn-success bnt-sm mr-3">განახლება</button>
  <button (click)="deviceCommands('&&DEV_debug')" class="btn btn-success bnt-sm">debug</button>
</div>
</div>


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="command">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element"> <button class="btn btn-success" (click)="sendCommands(element.command)">{{element.command}}</button> </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="returnedCoef">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element"> <input type="number" name="" id="" [value]="element.returnedCoef" width="30"> </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="secondCommand">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> <button class="btn btn-success" (click)="sendCommands(element.secondCommand,'Second')" >
          {{element.secondCommand}}</button> </td>
      </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="secondCoef">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> <input type="number" name="" [id]="element.secondCommand" [value]="element.secondCoef" width="30"> </td>
    </ng-container>

    <!-- <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element"> {{element.result}}
      </ng-container> -->
    
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element"> {{element.date}}
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>