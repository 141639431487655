import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-device-configuration',
  templateUrl: './device-configuration.component.html',
  styleUrls: ['./device-configuration.component.scss'],
})
export class DeviceConfigurationComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'name',
    'imei',
    'mobileNumber',
    'interval',
    'apn',
    'mainIp',
    'secondaryIp',
    'ntpServer',
    'voltageTrashHold',
    'gsmTrashHold',
    'programVersion',
    'deviceDateTime',
  ];
  dataSource: any;
  // tslint:disable-next-line:ban-types
  userDetails: Object;
  userId: any;
  interval: number;
  startPingMessage: any;
  deviceLog: {};
  constructor(private service: UserService, private http: HttpClient) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getStartPingMessage(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );
    // this.interval = setInterval(() => {
    //   this.getStartPingMessage(this.userId);
    // }, 3000);
  }
  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  // tslint:disable-next-line:typedef
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // tslint:disable-next-line:typedef
  getStartPingMessage(x) {
    this.service.StartPingMessage(x).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.startPingMessage = res;
        this.dataSource = new MatTableDataSource(this.startPingMessage);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
export interface StartMessages {
  imei: string;
  name: string;
  apn: string;
  csq: number;
  deviceDateTime: string;
  interval: number;
  mainIp: string;
  mobileNumber: string;
  ntpserver: string;
  secondaryIp: string;
}
