<div class="container">
  <div class="row">
    <div class="col-md-12">
      <ul class="list-unstyled p-values">
        <li class="p-value">
          <p>PA:</p>
          <h4 *ngIf="!PvalBool">{{ Pa | number : "1.0-2" }}</h4>
          <div class="inputDiv" *ngIf="PvalBool" style="display: flex">
            <div>
              <p>მაქსიმალური ზღვარი</p>
              <input [(ngModel)]="pValues.pa" *ngIf="PvalBool" />
            </div>
            <div>
              <p>მინიმალური ზღვარი</p>
              <input [(ngModel)]="pValues.paMin" *ngIf="PvalBool" />
            </div>
          </div>
        </li>
        <li class="p-value">
          <p>PB:</p>
          <h4 *ngIf="!PvalBool">{{ Pb | number : "1.0-2" }}</h4>
          <div class="inputDiv" *ngIf="PvalBool" style="display: flex">
            <div>
              <p>მაქსიმალური ზღვარი</p>
              <input [(ngModel)]="pValues.pb" *ngIf="PvalBool" />
            </div>
            <div>
              <p>მინიმალური ზღვარი</p>
              <input [(ngModel)]="pValues.pbMin" *ngIf="PvalBool" />
            </div>
          </div>
        </li>
        <li class="p-value">
          <p>PC:</p>
          <h4 *ngIf="!PvalBool">{{ Pc | number : "1.0-2" }}</h4>
          <div class="inputDiv" *ngIf="PvalBool" style="display: flex">
            <div>
              <p>მაქსიმალური ზღვარი</p>
              <input [(ngModel)]="pValues.pc" *ngIf="PvalBool" />
            </div>
            <div>
              <p>მინიმალური ზღვარი</p>
              <input [(ngModel)]="pValues.pcMin" *ngIf="PvalBool" />
            </div>
          </div>
        </li>
        <li>
          <p>Pfa:</p>
          {{ Pfa | number : "1.0-2" }}
        </li>
        <li>
          <p>Pfb:</p>
          {{ Pfb | number : "1.0-2" }}
        </li>
        <li>
          <p>Pfc:</p>
          {{ Pfc | number : "1.0-2" }}
        </li>
        <li>
          <p>Pt:</p>
          {{ Pt | number : "1.0-2" }}
        </li>
      </ul>
      <div class="button-container">
        <button
          style="background-color: rgb(226, 157, 45); color: beige"
          class="toggle-button"
          *ngIf="!PvalBool"
          (click)="PvalBool = !PvalBool"
        >
          რედაქტირება
        </button>
        <button
          class="toggle-button"
          style="background-color: rgb(237, 85, 25); color: rgb(255, 255, 255)"
          *ngIf="PvalBool"
          (click)="PvalBool = !PvalBool"
        >
          დახურვა
        </button>
        <button
          class="update-button"
          style="background-color: rgb(45, 226, 45); color: beige"
          *ngIf="PvalBool"
          (click)="UpdatePValue()"
        >
          შენახვა
        </button>
      </div>
    </div>
  </div>
</div>
