<form #form='ngForm' class="mb-4" autocomplete="off" (submit)="onSubmit(form)">
  <div class="form-group">
      <label> სახელი</label>
      <input class="form-control" #UserName="ngModel" name="UserName" [(ngModel)]="formModel.UserName" required>
  </div>
  <div class="form-group">
      <label>პაროლი</label>
      <input type="password" class="form-control" #Password="ngModel" name="Password" [(ngModel)]="formModel.Password" required>
  </div>
  <div class="form-row">
      <div class="form-group col-md-8 offset-md-2">
     <button type="submit" class="btn btn-lg btn-block" [disabled]="form.invalid">შესვლა</button>
      </div>
    </div>
</form>
