import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function graphicDataValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    let confirmValidation;

    if (
      parseInt(value[0] + value[1]) <= 24 &&
      value[2] == ':' &&
      parseInt(value[3] + value[4]) <= 60 &&
      value[5] == ',' &&
      parseInt(value[6] + value[7]) <= 24 &&
      value[8] == ':' &&
      parseInt(value[9] + value[10]) <= 60
    ) {
      confirmValidation = true;
    } else {
      confirmValidation = false;
    }

    const passwordValid = confirmValidation;

    return !passwordValid ? { passwordStrength: true } : null;
  };
}
