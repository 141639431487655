import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ShareDataService } from '../services/share-data.service';
import { google } from '@agm/core/services/google-maps-types';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MapFullInfoComponent } from '../device/map-full-info/map-full-info.component';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() selectedDevice = '';
  lat = 41.716667;
  lng = 44.783333;
  mapZoom = 4;
  // tslint:disable-next-line:align
  toppings = new FormControl();
  mapCordinates: any;
  userDetails: any;
  userId: any;
  devices = [];
  pingMessage: any;
  deviceLog: {};
  toppingList: string[];
  targetForExport: any;
  weight = '10';
  color = '#385898';

  srOnOffByImei = {};
  dateDiff = {};
  serverTime: number;
  SelectedColor: string;
  anotherArray: any;
  constructor(
    private http: HttpClient,
    private service: UserService,
    private shareDataService: ShareDataService,
    public router: Router,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.targetForExport =
      this.shareDataService.currentApprovalStageMessage.subscribe((msg) => {
        console.log(msg);
        if (msg !== '') {
          this.setMapFocus(msg);
        }
      });
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getPingMessage(this.userId);
        this.getMapCordinates(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  getMapCordinates(userId: string) {
    this.service.mapCordinates(userId).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.mapCordinates = res;
        this.devices = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:prefer-const
        this.serverTime = Date.parse(
          this.pingMessage[Object.keys(this.pingMessage).length - 1].date
        );
        this.deviceLog = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length - 1; i++) {
          if (!(this.pingMessage[i].name in this.deviceLog)) {
            this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
          }
          this.srOnOffByImei[this.pingMessage[i].imei] =
            this.pingMessage[i].srOnOf;

          this.dateDiff[this.pingMessage[i].imei] = this.getColor(
            this.pingMessage[i]
          );
        }
        this.toppingList = Object.keys(this.deviceLog);
        this.anotherArray = Object.keys(this.deviceLog);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  setMapFocus(deviceName) {
    // this.getMapCordinates(this.userId);
    // this.getPingMessage(this.userId);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.devices.length; i++) {
      // console.log(deviceName);  // 006 office6 N5 skolastan__ 3377
      if (this.devices[i].deviceName === deviceName) {
        this.lat = parseFloat(this.devices[i].lat);
        this.lng = parseFloat(this.devices[i].long);
        this.mapZoom = 18;
      }
    }
  }

  // tslint:disable-next-line:typedef
  setMapFocus1(deviceName) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.devices.length; i++) {
      if (this.devices[i].deviceName === deviceName[0].split('__ ')[0]) {
        this.lat = parseFloat(this.devices[i].lat);
        this.lng = parseFloat(this.devices[i].long);
        this.mapZoom = 18;
      }
    }
  }
  // tslint:disable-next-line:typedef
  getDateDiff(datetime, interval, deviceId, connectiionStatus, imei, name) {
    let substr = 0;
    if (interval <= 5) {
      substr = 10;
    } else {
      substr = 2;
    }
    let result = false;
    // const currentDate = new Date();
    const deviceDate = Date.parse('20' + datetime);
    const diffTime = Math.abs(this.serverTime.valueOf() - deviceDate);
    const diffMinute = diffTime / (1000 * 60);

    const dayDiff = diffTime / (1000 * 60 * 60 * 24);
    // tslint:disable-next-line:radix
    if (diffMinute > (interval / 60) * substr) {
      result = true;
      // this.updateDeviceStatus(deviceId,2);
    }
    // console.log( 'მოწყობილობის დრო: ' + datetime + '----------------' + 'ახლანდელი დრო' + deviceDate);
    return result;
  }

  getColor(x) {
    var result = '';
    var tarigi =
      this.getDateDiff(
        x.deviceDateTime,
        x.interval,
        x.deviceId,
        x.connectionStatus,
        x.imei,
        x.name
      ) == true;
    var witeli = x.ua == '0' && x.ub == '0' && x.uc == '0';
    var tetri = x.managementMode == '0';
    var yafisferi =
      ((x.srOnOf == '0' ||
        x.srOnOf == '2' ||
        x.srOnOf == '4' ||
        x.k1 == '0' ||
        x.k1 == '2' ||
        x.k1 == '4' ||
        x.k2 == '0' ||
        x.k2 == '2' ||
        x.k2 == '4') &&
        (x.ukudzabvaA.length > 36 ||
          x.ukudzabvaB.length > 36 ||
          x.ukudzabvaC.length > 36)) ||
      (x.srOnOf == '0' && (x.k1 != '0' || x.k2 != '0'));
    var mwvane = x.srOnOf == '1' || x.srOnOf == '3' || x.srOnOf == '5';

    var yviteli =
      (Number.parseFloat(x.a) < 0.3 && !x.a.toString().includes('N')) ||
      (Number.parseFloat(x.b) < 0.3 && !x.b.toString().includes('N')) ||
      (Number.parseFloat(x.c) < 0.3 && !x.c.toString().includes('N')) ||
      (Number.parseFloat(x.ua) < 0.3 && !x.ua.toString().includes('N')) ||
      (Number.parseFloat(x.ub) < 0.3 && !x.ub.toString().includes('N')) ||
      (Number.parseFloat(x.uc) < 0.3 && !x.uc.toString().includes('N')) ||
      (((Number.parseFloat(x.ia) < 0.3 && !x.ia.toString().includes('N')) ||
        (Number.parseFloat(x.ib) < 0.3 && !x.ib.toString().includes('N')) ||
        (Number.parseFloat(x.ic) < 0.3 && !x.ic.toString().includes('N')) ||
        (Number.parseFloat(x.i1) < 0.3 && !x.i1.toString().includes('N')) ||
        (Number.parseFloat(x.i2) < 0.3 && !x.i2.toString().includes('N')) ||
        (Number.parseFloat(x.i3) < 0.3 && !x.i3.toString().includes('N')) ||
        (Number.parseFloat(x.i4) < 0.3 && !x.i4.toString().includes('N')) ||
        (Number.parseFloat(x.i5) < 0.3 && !x.i5.toString().includes('N')) ||
        (Number.parseFloat(x.i6) < 0.3 && !x.i6.toString().includes('N')) ||
        (Number.parseFloat(x.i7) < 0.3 && !x.i7.toString().includes('N')) ||
        (Number.parseFloat(x.i8) < 0.3 && !x.i8.toString().includes('N')) ||
        (Number.parseFloat(x.i9) < 0.3 && !x.i9.toString().includes('N')) ||
        (Number.parseFloat(x.i10) < 0.3 && !x.i10.toString().includes('N')) ||
        (Number.parseFloat(x.i11) < 0.3 && !x.i11.toString().includes('N')) ||
        (Number.parseFloat(x.i12) < 0.3 && !x.i12.toString().includes('N'))) &&
        (x.srOnOf == '1' ||
          x.srOnOf == '3' ||
          x.srOnOf == '5' ||
          x.k1 == '1' ||
          x.k1 == '3' ||
          x.k1 == '5' ||
          x.k2 == '1' ||
          x.k2 == '3' ||
          x.k2 == '5')) ||
      (((Number.parseFloat(x.a) > 0.3 && !x.a.toString().includes('N')) ||
        (Number.parseFloat(x.b) > 0.3 && !x.b.toString().includes('N')) ||
        (Number.parseFloat(x.c) > 0.3 && !x.c.toString().includes('N')) ||
        (Number.parseFloat(x.ua) < 0.3 && !x.ua.toString().includes('N')) ||
        (Number.parseFloat(x.ub) < 0.3 && !x.ub.toString().includes('N')) ||
        (Number.parseFloat(x.uc) < 0.3 && !x.uc.toString().includes('N')) ||
        (Number.parseFloat(x.ia) > 0.3 && !x.ia.toString().includes('N')) ||
        (Number.parseFloat(x.ib) > 0.3 && !x.ib.toString().includes('N')) ||
        (Number.parseFloat(x.ic) > 0.3 && !x.ic.toString().includes('N')) ||
        (Number.parseFloat(x.i1) > 0.3 && !x.i1.toString().includes('N')) ||
        (Number.parseFloat(x.i2) > 0.3 && !x.i2.toString().includes('N')) ||
        (Number.parseFloat(x.i3) > 0.3 && !x.i3.toString().includes('N')) ||
        (Number.parseFloat(x.i4) > 0.3 && !x.i4.toString().includes('N')) ||
        (Number.parseFloat(x.i5) > 0.3 && !x.i5.toString().includes('N')) ||
        (Number.parseFloat(x.i6) > 0.3 && !x.i6.toString().includes('N')) ||
        (Number.parseFloat(x.i7) > 0.3 && !x.i7.toString().includes('N')) ||
        (Number.parseFloat(x.i8) > 0.3 && !x.i8.toString().includes('N')) ||
        (Number.parseFloat(x.i9) > 0.3 && !x.i9.toString().includes('N')) ||
        (Number.parseFloat(x.i10) > 0.3 && !x.i10.toString().includes('N')) ||
        (Number.parseFloat(x.i11) > 0.3 && !x.i11.toString().includes('N')) ||
        (Number.parseFloat(x.i12) > 0.3 && !x.i12.toString().includes('N'))) &&
        (x.srOnOf == '0' ||
          x.srOnOf == '2' ||
          x.srOnOf == '4' ||
          x.k1 == '0' ||
          x.k1 == '2' ||
          x.k1 == '4' ||
          x.k2 == '0' ||
          x.k2 == '2' ||
          x.k2 == '4'));

    if (tarigi == true) {
      witeli = false;
      tetri = false;
      mwvane = false;
      yviteli = false;
      yafisferi = false;
    }

    if (witeli == true) {
      tetri = false;
      mwvane = false;
      yviteli = false;
      yafisferi = false;
    }

    if (yviteli == true) {
      mwvane = false;
      tetri = false;
    }

    if (yafisferi == true) {
      mwvane = false;
      tetri = false;
    }

    tarigi
      ? (this.SelectedColor = '../../assets/img/grayMarker.svg')
      : witeli
      ? (this.SelectedColor = '../../assets/img/redMarker.svg')
      : tetri
      ? (this.SelectedColor = '../../assets/img/whiteMarker.svg')
      : yafisferi
      ? (this.SelectedColor = '../../assets/img/darkBrownMarker.svg')
      : yviteli
      ? (this.SelectedColor = '../../assets/img/yellowMarker.svg')
      : mwvane
      ? (this.SelectedColor = '../../assets/img/greenMarker.svg')
      : (this.SelectedColor = '../../assets/img/grayMarker.svg');
    return this.SelectedColor;
  }
  async handleFileInput(files: FileList) {
    const file = files.item(0);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* send data through http */
      this.service.uploadExcel(data).subscribe(
        (res) => console.log(res),
        (err) => console.log(err)
      );
    };
    reader.readAsBinaryString(file);
  }

  markerClicked(val: string) {
    this.devicesFullInfo(val);
  }

  devicesFullInfo(val: string) {
    if (val != 'all') {
      var objectId = val['objectid'];
    } else {
      objectId = val;
    }
    this.shareDataService.mapFullInfo(objectId);

    this._bottomSheet.open(MapFullInfoComponent);
  }

  filterListCareUnit(val) {
    this.toppingList = this.anotherArray.filter(
      (unit) => unit.indexOf(val) > -1
    );
  }

  copyToClipboard(lat: string, long: string): void {
    var text =
      'https://www.google.com/maps/search/?api=1&query= ' + lat + ',' + long;
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}
