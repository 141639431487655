<h1>მომხმარებლის რედაქტირება</h1>

<form class="example-form">
  <div style="display:flex;flex-direction: column;">
    <mat-form-field appearance="fill" class="add-device-option" style="margin-right: 5px;">
      <mat-label>მომხარებლი:</mat-label>
      <mat-select [formControl]="toppings" multiple [(ngModel)]="selectedUser" (selectionChange)="onDeviceSelect(selectedUser)">
        <mat-select-trigger>
          {{toppings.value ? toppings.value[0] : ''}}
          <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
            (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="add-device-option" style="margin-right: 5px;background-color: #aac9eb;">
      <mat-label>როლი:</mat-label>
      <mat-select [formControl]="roles" multiple [(ngModel)]="selectedRole" (selectionChange)="onRoleSelect(selectedRole)">
        <mat-option *ngFor="let role of roleList" [value]="role">{{role}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>


    <p>სახელი:</p>
    <input matInput  class="form-control"  [value]="selectedUser" [(ngModel)]="selectedUserName" name="name">
    <p>პაროლი:</p>
    <input matInput  class="form-control"  [value]="selectedUserPass" [(ngModel)]="selectedUserPass" name="imei">
    
   
   <div class="d-flex justify-content-between mt-2">
    <button  class="btn btn-primary" (click)="editDeviceInfo(selectedUserName,selectedUserPass,selectedUser,selectedRole)">შენახვა</button>
    <button class="btn btn-danger" (click)="deleteUser(selectedUser)">წაშლა</button>
   </div>
 
</form>
