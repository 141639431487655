import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formModel = {
    UserName: '',
    Password: '',
  };
  userId: string;
  deviceOrder: [];

  constructor(
    private service: UserService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  // tslint:disable-next-line:no-unused-expression
  ngOnInit(): void {
    if (localStorage.getItem('token') != null) {
      this.router.navigateByUrl('/home');
    }
  }

  // tslint:disable-next-line:typedef
  onSubmit(form: NgForm) {
    this.service.login(form.value).subscribe(
      (res: any) => {
        const payLoad = JSON.parse(window.atob(res.token.split('.')[1]));

        // tslint:disable-next-line:prefer-const
        // tslint:disable-next-line:member-ordering
        this.userId = payLoad.UserID;
        // let deviceOr = localStorage.getItem('DeviceOrder');
        // console.log(deviceOr);
        // if (deviceOr != undefined) {
        //   this.deviceOrder = JSON.parse(deviceOr) ? JSON.parse(deviceOr) : {};
        // }

        const selectedPage = localStorage.getItem('selectedPage');
        const deviceCommandLog = localStorage.getItem('commandLog');
        const soundDisabled = localStorage.getItem('soundDisabled');
        localStorage.clear();
        localStorage.setItem('commandLog', deviceCommandLog);
        // localStorage.setItem('DeviceOrder', JSON.stringify(this.deviceOrder));
        localStorage.setItem('selectedPage', selectedPage);
        localStorage.setItem('token', res.token);
        localStorage.setItem('soundDisabled', soundDisabled);
        this.router.navigateByUrl('/home');
      },
      (err) => {
        if (err.status === 400) {
          this.toastr.error(
            'მომხმარებლის სახელი ან პაროლი არასწორია.',
            'Authentication failed'
          );
        } else {
          console.log(err);
        }
      }
    );
  }
}
