<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="add-Device" *ngIf="showAddDevice">
        <h1>მოწყობილობის დამატება</h1>
        <mat-form-field appearance="fill" class="add-device-option">
          <mat-label>მომხმარებელი</mat-label>
          <mat-select
            [formControl]="toppings"
            multiple
            [(ngModel)]="selectedUser"
          >
            <mat-select-trigger>
              {{ toppings.value ? toppings.value[0] : "" }}
              <span
                *ngIf="toppings.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ toppings.value.length - 1 }}
                {{ toppings.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{
              topping
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <label for="imei" class="mt-2">იმეი:</label>
        <input
          type="text"
          placeholder="იმეი"
          [(ngModel)]="imei"
          class="form-control"
        />

        <div class="mt-3">
          <label for="KARADIS_NO">კარადის ნომერი:</label>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-select
              [(ngModel)]="selectedDevice"
              (selectionChange)="onBookChange($event)"
              placeholder="საწყობი"
            >
              <mat-option>საწყობი</mat-option>
              <mat-option
                *ngFor="let device of deviceInfo | keyvalue"
                [value]="device"
                >{{ device.key }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="mt-3">
          <label for="სახელი">სახელი:</label>
          <input
            type="text"
            placeholder="სახელი"
            [(ngModel)]="name"
            class="form-control"
          />
        </div>

        <div class="mt-3">
          <label for="სახელი">სერიული:</label>
          <input
            type="number"
            placeholder="სერიული"
            [(ngModel)]="serialNumber"
            class="form-control"
          />
        </div>

        <!-- <input type="text" placeholder="მოწყობილობის ID" [(ngModel)]="deviceId" class="form-control" > -->
        <!-- <input
          type="text"
          placeholder="Latitude"
          [(ngModel)]="lat"
          class="form-control"
        />
        <input
          type="text"
          placeholder="Longtitude"
          [(ngModel)]="long"
          class="form-control"
        />
        <input
          type="text"
          placeholder="მისამართი"
          [(ngModel)]="adress"
          class="form-control"
        /> -->
        <button
          class="btn btn-success mt-3"
          (click)="
            addDevice(
              selectedUser,
              imei,
              name,
              0,
              '41.694348',
              '44.906441',
              '7 ალექსანდრე თვალჭრელიძის I შესახვევი, თბილისი',
              serialNumber
            )
          "
        >
          დამატება
        </button>
      </div>
    </div>
    <div class="col-md-6" style="background-color: white">
      <h3 style="color: #000; text-align: center">Unverified Devices</h3>
      <div style="overflow-y: scroll; height: 500px">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <input
            type="text"
            [(ngModel)]="search"
            (ngModelChange)="onSearchChange($event)"
            placeholder="ძიება..."
          />
        </div>
        <mat-list role="list" *ngFor="let item of unverFieldDevicesFiltered">
          <mat-list-item role="listitem">
            <button class="btn btn-primary" (click)="addUnverified(item.imei)">
              {{ item.imei }}
            </button>
            <mat-icon
              (click)="deleteFromUnverified(item.imei)"
              style="color: red; cursor: pointer"
              >delete</mat-icon
            >
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</div>
