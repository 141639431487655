import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventHistoryService {

 EventHistory: any[] = [];
 EventHistoryId: number;
  eventInterval: any;
  deviceErrors:any 
  eventDbDict = {};
  audioSrc = '';
  userDetails: any;
  userId: any;
  debugInterval: any;
  difference = 0;
  newNotifLength = 0;
  

//   private userProfile = new BehaviorSubject<any>(null);
//   userProfile$ = this.userProfile.asObservable();

 

//   setUserProfile(profile: any) {
//     this.userProfile.next(profile);
//   }
}
