

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="dataLimitForm" (ngSubmit)="saveLimits()">
        <label for="data-name">მონაცემი:</label>
        <input id="data-name" type="text" formControlName="Name" disabled>

        <label for="upper-limit">ზედა ზღვარი:</label>
        <input id="upper-limit" type="number" formControlName="UpperLimit">

        <label for="low-limit">ქვედა ზღვარი:</label>
        <input id="low-limit" type="number" formControlName="LowLimit">

      <button type="submit" class="btn btn-success btn-sm" [disabled]="!dataLimitForm.valid">შენახვა</button>
      </form>
    </div>
  </div>
</div>

