<div class="mat-elevation-z8">
  <div class="Block mb-3" *ngIf="showCommand == true">
    <mat-form-field
      appearance="fill"
      class="add-device-option"
      style="margin-right: 5px"
    >
      <mat-label>მოწყობილობა</mat-label>
      <mat-select
        [formControl]="toppings"
        multiple
        [(ngModel)]="selectedDevice"
        (selectionChange)="checked($event.value)"
      >
      <input matInput type="text" (keyup)="filterListCareUnit($event.target.value)" placeholder="ძებნა" style="border: 1px solid rgb(199, 196, 196); width: 99% !important;">
        <mat-select-trigger>
          {{ toppings.value ? toppings.value[0] : "" }}
          <span
            *ngIf="toppings.value?.length > 1"
            class="example-additional-selection"
          >
            (+{{ toppings.value.length - 1 }}
            {{ toppings.value?.length === 2 ? "other" : "others" }})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{
          topping.split('__')[0]
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      *ngIf="hideBtn"
      class="btn btn-success"
      (click)="sendProgramCmd()"
      [disabled]="!VOForm.valid"
    >
      გაგზავნა
    </button>
    <!-- <button class="btn btn-danger" (click)="sendProgramCmd(selectedDevice)">დაპროგრამება</button> -->
  </div>
  <div class="selectedDevices-chips" *ngIf="showCommand == true">
    <mat-label class="mb-3"
      >გრაფიკი გაიგზავნება არჩეულ მოწყობილობებზე:</mat-label
    >
    <br />
    <mat-chip-list
      #chipLis
      class="example-chip"
      appearance="fill"
      style="padding: 5px"
    >
      <mat-chip
        class="example-box"
        *ngFor="let x of selectedDevices"
        color="primary"
        (removed)="remove(x)"
      >
        {{ x }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <mat-form-field style="width: 100%">
    <mat-label>ფილტრი</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input />
  </mat-form-field>
  <div
    class="btn btn-primary mb-3"
    style="display: flex; float: right"
    (click)="default()"
  >
    Default
  </div>
  <form [formGroup]="VOForm" autocomplete="off">
    <ng-container formArrayName="VORows">
      <table
        #table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="month">
          <th mat-header-cell *matHeaderCellDef>თვე\ დღე</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <mat-form-field style="width: 70px" appearance="none">
              <input
                matInput
                type="text"
                formControlName="month"
                [readonly]="true"
              />
            </mat-form-field></td
        ></ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="1-5">
          <th mat-header-cell *matHeaderCellDef>1-5</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <!-- <span [hidden]="VOForm.get('VORows').value[i].isEditable"> -->
            <mat-form-field
              style="width: 70px"
              [appearance]="
                VOForm.get('VORows').value[i].isEditable ? 'none' : 'legacy'
              "
            >
              <input
                matInput
                type="text"
                formControlName="first"
                placeholder="00:00,00:00"
                [readonly]="VOForm.get('VORows').value[i].isEditable"
              />
            </mat-form-field>
            <!-- </span> -->
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="6-10">
          <th mat-header-cell *matHeaderCellDef>6-10</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <!-- [appearance]="true ? 'outline': 'fill'" -->
            <!-- <span [hidden]="VOForm.get('VORows').value[i].isEditable"> -->
            <mat-form-field
              style="width: 70px"
              [appearance]="
                VOForm.get('VORows').value[i].isEditable ? 'none' : 'legacy'
              "
            >
              <input
                matInput
                type="text"
                formControlName="second"
                placeholder="00:00,00:00"
                [readonly]="VOForm.get('VORows').value[i].isEditable"
              />
            </mat-form-field>
            <!-- </span> -->
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="11-15">
          <th mat-header-cell *matHeaderCellDef>11-15</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <!-- <span [hidden]="VOForm.get('VORows').value[i].isEditable"> -->
            <mat-form-field
              style="width: 70px"
              [appearance]="
                VOForm.get('VORows').value[i].isEditable ? 'none' : 'legacy'
              "
            >
              <input
                matInput
                type="text"
                formControlName="third"
                placeholder="00:00,00:00"
                [readonly]="VOForm.get('VORows').value[i].isEditable"
              />
            </mat-form-field>
            <!-- </span> -->
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="16-20">
          <th mat-header-cell *matHeaderCellDef>16-20</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <!-- <span [hidden]="VOForm.get('VORows').value[i].isEditable"> -->
            <mat-form-field
              style="width: 70px"
              [appearance]="
                VOForm.get('VORows').value[i].isEditable ? 'none' : 'legacy'
              "
            >
              <input
                matInput
                type="text"
                formControlName="four"
                placeholder="00:00,00:00"
                [readonly]="VOForm.get('VORows').value[i].isEditable"
              />
            </mat-form-field>
            <!-- </span> -->
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="21-25">
          <th mat-header-cell *matHeaderCellDef>21-25</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <!-- <span [hidden]="VOForm.get('VORows').value[i].isEditable"> -->
            <mat-form-field
              style="width: 70px"
              [appearance]="
                VOForm.get('VORows').value[i].isEditable ? 'none' : 'legacy'
              "
            >
              <input
                matInput
                type="text"
                formControlName="five"
                [readonly]="VOForm.get('VORows').value[i].isEditable"
                placeholder="00:00,00:00"
              />
            </mat-form-field>
            <!-- </span> -->
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="26-">
          <th mat-header-cell *matHeaderCellDef>26-</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <!-- <span [hidden]="VOForm.get('VORows').value[i].isEditable"> -->
            <mat-form-field
              style="width: 70px"
              [appearance]="
                VOForm.get('VORows').value[i].isEditable ? 'none' : 'legacy'
              "
            >
              <input
                matInput
                type="text"
                formControlName="six"
                placeholder="00:00,00:00"
                [readonly]="VOForm.get('VORows').value[i].isEditable"
              />
            </mat-form-field>
            <!-- </span> -->
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>რედაქტირება</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [formGroup]="element"
          >
            <button
              [disabled]="!VOForm.valid"
              mat-icon-button
              class="material-icons app-toolbar-menu save-button"
              matTooltip="Save Changes"
              (click)="SaveVO(VOForm, i)"
              *ngIf="!VOForm.get('VORows').value[i].isEditable"
              color="primary"
            >
              <mat-icon>check_circle</mat-icon>
            </button>
            <button
              mat-icon-button
              class="material-icons app-toolbar-menu cancel-button"
              matTooltip="Cancel Changes"
              color="warn"
              (click)="CancelSVO(VOForm, i)"
              *ngIf="!VOForm.get('VORows').value[i].isEditable"
            >
              <mat-icon>cancel</mat-icon>
            </button>

            <button
              mat-icon-button
              class="material-icons app-toolbar-menu save-button"
              *ngIf="VOForm.get('VORows').value[i].isEditable"
              matTooltip="Edit"
              color="primary"
              (click)="EditSVO(VOForm, i)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </ng-container>
  </form>
  <mat-card
    *ngIf="isLoading"
    style="display: flex; justify-content: center; align-items: center"
  >
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>

  <!-- <mat-paginator-goto [length]="100" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
		(page)="paginationChange($event)"></mat-paginator-goto> -->
  <!-- <mat-paginator [pageSizeOptions]="[5, dataSource.data.length>8? dataSource.data.length:''  ]" showFirstLastButtons>
  </mat-paginator> -->
</div>