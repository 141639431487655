<app-nav></app-nav>
<app-device-list></app-device-list>

<!-- <p>home works!</p>
<nav class="navbar navbar-dark bg-dark">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
          <a  class="nav-link" routerLink="/adminpanel">Admin Panel</a>
      </li>
  </ul>
    <button class="btn btn-danger my-2 my-sm-0" (click)="onLogout()">
        გამოსვლა
    </button>
</nav>


<div class="card m-5" style="width:18rem" *ngIf="userDetails">
  <ul class="list-group">
      <li class="list-group-item">
          <strong>მომხმარებლის სახელი : </strong>{{userDetails.userName}}
      </li>
      <li class="list-group-item">
          <strong>სრული სახელი : </strong>{{userDetails.fullName}}
      </li>
      <li class="list-group-item">
          <strong>ელ-ფოსტა : </strong>{{userDetails.email}}
      </li>
  </ul>
  </div> -->
