import { Component, OnInit } from '@angular/core';
import { ShareDataService } from '../services/share-data.service';
import { UserService } from '../services/user.service';
import { DeviceServiceService } from '../services/device-service.service';

@Component({
  selector: 'app-device-extra-data',
  templateUrl: './device-extra-data.component.html',
  styleUrls: ['./device-extra-data.component.scss'],
})
export class DeviceExtraDataComponent implements OnInit {
  userDetails: any;
  userId: any;
  pingMessage: any;
  deviceLog: {};
  deviceFullLog: {};
  targetData: any;
  selectedDeviceId: string;
  selectedDeviceData: {};
  Pa: any;
  Pb: any;
  Pc: any;
  Pfa: any;
  Pfb: any;
  Pfc: any;
  Pt: any;

  PvalBool: boolean = false;
  pValues = {
    pa: '',
    pb: '',
    pc: '',
    paMin: '',
    pbMin: '',
    pcMin: '',
  };
  constructor(
    private service: UserService,
    private shareDataService: ShareDataService,
    private DeviceService: DeviceServiceService
  ) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
      },
      (err) => {
        console.log(err);
      }
    );

    this.targetData =
      this.shareDataService.currentApprovalStageMessage3.subscribe((msg) => {
        this.selectedDeviceId = msg;
        this.getPingMessage(this.userId);
      });
  }

  UpdatePValue() {
    let obj = {
      imei: this.selectedDeviceId,
      pa: `${this.pValues.pa};${this.pValues.paMin}`,
      pb: `${this.pValues.pb};${this.pValues.pbMin}`,
      pc: `${this.pValues.pc};${this.pValues.pcMin}`,
    };
    console.log(obj);
    this.DeviceService.UpdatePValues(obj).subscribe(
      (res) => {
        console.log(res);
        this.PvalBool = !this.PvalBool;
        this.getPingMessage(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x + ';' + 'fromAdmin').subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        this.deviceFullLog = {};
        this.selectedDeviceData = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          this.deviceFullLog[this.pingMessage[i].imei] = this.pingMessage[i];
        }
        // tslint:disable-next-line:prefer-const
        this.selectedDeviceData = this.deviceFullLog[this.selectedDeviceId];
        // tslint:disable-next-line:no-string-literal
        this.Pa = this.selectedDeviceData['pa'];
        this.pValues.pa = this.selectedDeviceData['pa'];
        // tslint:disable-next-line:no-string-literal
        this.Pb = this.selectedDeviceData['pb'];
        this.pValues.pb = this.selectedDeviceData['pb'];
        // tslint:disable-next-line:no-string-literal
        this.Pc = this.selectedDeviceData['pc'];
        this.pValues.pc = this.selectedDeviceData['pc'];
        // tslint:disable-next-line:no-string-literal
        this.Pfa = this.selectedDeviceData['pfa'];
        // tslint:disable-next-line:no-string-literal
        this.Pfb = this.selectedDeviceData['pfb'];
        // tslint:disable-next-line:no-string-literal
        this.Pfc = this.selectedDeviceData['pfc'];
        // tslint:disable-next-line:no-string-literal
        this.Pt = this.selectedDeviceData['pt'];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
