import { Component, OnInit, OnDestroy } from '@angular/core';

import { UserService } from '../services/user.service';
3;
import { EventHistoryService } from '../services/event-histori';
import { AudioService } from '../services/audio.service';
import { ToastrService } from 'ngx-toastr';
import { ShareDataService } from '../services/share-data.service';
import { AudioReferenceDataType } from '../types/AudioTypes';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  showAudio = true;

  constructor(
    private eventHistoryService: EventHistoryService,
    private service: UserService,
    private audioService: AudioService,
    private toastr: ToastrService,
    private sharData: ShareDataService
  ) {
    this.eventHistoryService.newNotifLength = Number(
      localStorage.getItem('eventHistoryLength')
    );
  }

  ngOnInit(): void {
    this.getUserProfile();

    this.eventHistoryService.eventInterval = setInterval(() => {
      this.getUserProfile();
      this.eventHistoryService.newNotifLength = Number(
        localStorage.getItem('eventHistoryLength')
      );
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.eventHistoryService.eventInterval) {
      clearInterval(this.eventHistoryService.eventInterval);
    }
  }

  getUserProfile() {
    this.service.getUserProfile().subscribe(
      (res) => {
        const userDetails = res;
        const userId = userDetails['id'];
        if (userId != undefined) {
          this.getDeviceEvents(userId);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  checkDifferenceTime(dateOne, dateTwo) {
    const dateTime1 = new Date(dateOne);
    const dateTime2 = new Date(dateTwo);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = Math.abs(
      dateTime1.getTime() - dateTime2.getTime()
    );

    // Convert milliseconds to seconds
    const differenceInSeconds = differenceInMilliseconds / 1000;
    return differenceInSeconds <= 5;
  }

  getDeviceEvents(userId) {
 
    this.service.deviceEvents(userId).subscribe(
      (res: any) => {
        // console.log('DEVISEHISTORY', this.EventHistory);
        this.eventHistoryService.EventHistory = res;
        // this.eventHistoryService.EventHistory = this.audioService.FakeObj
        if (this.eventHistoryService.EventHistory.length > 0) {
          this.eventHistoryService.EventHistoryId = Number(
            this.eventHistoryService.EventHistory[0].id
          );
          this.eventHistoryService.eventDbDict = {};
          let audio = document.getElementById('audio') as HTMLAudioElement;

        

          let newindex = 0;
      
     
          if (
            this.eventHistoryService.newNotifLength !==
            this.eventHistoryService.EventHistory[0].id
          ) {
            // ციკლს ვუკეთებთ რეფრენც დეტას რომ გავუშვათ აუდიო ბაზიდან წამოსული ერორების საფუძველზე
            this.audioService.AudioRefrenceDataArray.forEach(
              (val: AudioReferenceDataType) => {
                const { id, errorMessage, audioLink } = val;
            // თუ დრო ახლოს არის ერთმანეთან 
                let isTimeDiff = this.checkDifferenceTime(
                  this.eventHistoryService.EventHistory[0].dateTime,
                  this.eventHistoryService.EventHistory[1].dateTime
                );
                if (isTimeDiff) {
                  // პარამეტრებს ვატარებთ ლოკალურ ფუნქციაში რომელიც პასუხისმგებელია აუდიოს გაშვებაზე
                  this.CheckAudioEvent(
                    newindex,
                    audio,
                    errorMessage,
                    audioLink
                  );
                  localStorage.setItem(
                    'eventHistoryLength',
                    this.eventHistoryService.EventHistory[0].id
                  );
            
                  audio.addEventListener('ended', () => {
                  
                    this.CheckAudioEvent(
                      1,  
                      audio, 
                      errorMessage,
                      audioLink
                    );
                  }, { once: true });  
              
                 
              
           
                }
                else{
                  this.CheckAudioEvent(
                    newindex,
                    audio,
                    errorMessage,
                    audioLink
                  );
                  localStorage.setItem(
                    'eventHistoryLength',
                    this.eventHistoryService.EventHistory[0].id
                  );
                }
              }
            );
            if (
              this.eventHistoryService.EventHistory[newindex][
                'evenet'
              ].includes('ძაბვა ნორმაზე') &&
              this.eventHistoryService.EventHistory[newindex][
                'evenet'
              ].includes('მაღალია')
            ) {
              if (audio.paused) {
                this.playSound(
                  'qselshiDasashvebzeMagaliDzabvaa.mp3',
                  this.eventHistoryService.EventHistory[newindex]['evenet']
                );
              }
              this.toastr.info(
                this.eventHistoryService.EventHistory[newindex]['evenet'] +
                  ' ' +
                  this.eventHistoryService.EventHistory[newindex][
                    'dateTime'
                  ].replace('T', ' ')
              );
            }
            if (
              this.eventHistoryService.EventHistory[newindex][
                'evenet'
              ].includes('განათებულობა ზღვარზე დაბალია')
            ) {
              const imei =
                this.eventHistoryService.EventHistory[newindex].deviceImei;

              this.service.getLastPingMessage(imei).subscribe(
                (res) => {
                  if (
                    (res['srOnOf'] == 0 ||
                      res['srOnOf'] == 2 ||
                      res['srOnOf'] == 4 ||
                      res['srOnOf'] == 6) &&
                    (res['lux'] != '0.0' || res['lux'] != '0')
                  ) {
                    if (audio.paused) {
                      this.playSound(
                        'ganatebuloba_dabalia.mp3',
                        this.eventHistoryService.EventHistory[newindex][
                          'evenet'
                        ]
                      );
                    }
                    this.toastr.info(
                      this.eventHistoryService.EventHistory[newindex][
                        'evenet'
                      ] +
                        ' ' +
                        this.eventHistoryService.EventHistory[newindex][
                          'dateTime'
                        ].replace('T', ' ')
                    );
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            }
          }

          // ახალ აიდის ვინახავთ ლოკალ სტორიჯში

          let eventLength = Object.keys(
            this.eventHistoryService.EventHistory
          ).length;
          this.eventHistoryService.deviceErrors = [];
          for (let i = 0; i < eventLength; i++) {
            let DateTimeReplace =
              this.eventHistoryService.EventHistory[i]['evenet'] +
              ' ' +
              this.eventHistoryService.EventHistory[i]['dateTime'].replace(
                'T',
                ' '
              );
            if (
              !this.eventHistoryService.deviceErrors.includes(DateTimeReplace)
            ) {
              this.eventHistoryService.deviceErrors.push(DateTimeReplace);
            }
            this.eventHistoryService.eventDbDict[DateTimeReplace] =
              this.eventHistoryService.EventHistory[i]['id'];
          }
          this.sharData.deviceErrors(this.eventHistoryService.deviceErrors);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  playSound(mp3File, error) {
    // ვამოწმებთ თუ იგივე დევაიცი იგივე ემეითი და ერრორით (evenet) უკვე იყო ერრორ სტაკში
    //  evenet - უნდა იყოს event, მე არ დამიწერია ეს მზეობა.
    let newArray = this.eventHistoryService.EventHistory.map(
      ({ deviceImei, evenet, dateTime }) => {
        let dateObject = new Date(dateTime);
        let dataInMiliSeconds = dateObject.getTime();
        let currentDate = new Date();
        let currentMiliSeconds = currentDate.getTime();
        if (dataInMiliSeconds > currentMiliSeconds - 30 * 60 * 1000) {
          return { deviceImei, evenet };
        } else {
          return null;
        }
      }
    ).filter((val: any) => val !== null);

    var soundPlay = true;

    var dsableSound = localStorage.getItem('soundDisabled');
    // ამჯერათ ეს ვარიაბლე არ არის გამოყენებაში
    const hasDuplicates = newArray.some((obj, index, array) => {
      // სე ამოწმებს თუ არსებობს დუპლიკატი იმეის და ერრორის მქონე ობიექთი,ერრორების ლისტში
      return (
        array.findIndex(
          (innerObj) =>
            innerObj.deviceImei === obj.deviceImei &&
            innerObj.evenet === obj.evenet
        ) !== index
      );
    });
    if (dsableSound != undefined) {
      var soundArr = JSON.parse(dsableSound);
      if (soundArr != null) {
        var soundUsrArr = soundArr[this.eventHistoryService.userId];
        if (soundUsrArr !== null) {
          if (soundUsrArr.includes(error)) {
            // soundPlay = false;
          }
        }
      }
    }

    if (soundPlay == true) {
      this.eventHistoryService.audioSrc =
        '../assets/audio/by-event-name/' + mp3File;

      let audio = document.getElementById('audio') as HTMLAudioElement;
      if (audio.paused) {
        var playPromise = audio.play();
        playPromise
          .then((val) => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            audio.play();
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    }
  }

  CheckAudioEvent(
    newindex: number,
    audio: any,
    errorMessage: string[], // errorMessage არის სტრინგების არრაი რომელიც შეიცავს რეფრენც ერრორებს,
    audioLocation: string
  ) {
    // ვამოწმებთ თუ ბაზიდან შემოსული ერრორი ემთხვევა ამ სიაში არსებულ ერრორს და იმის მიხედვით
    // ვსახავთ შესაბამის ერრორს ეკრანზე და ვრთავთ აუდიოს this.playSound-ფუნცქის დაახმარებით

    if (
      errorMessage.find((val: string) => {
        return this.eventHistoryService.EventHistory[newindex][
          'evenet'
        ].includes(val);
      })
    ) {
      if (audio.paused) {
        this.playSound(
          audioLocation,
          this.eventHistoryService.EventHistory[newindex]['evenet']
        );
      }
      console.log(audioLocation);
      this.toastr.info(
        this.eventHistoryService.EventHistory[newindex]['evenet'] +
          ' ' +
          this.eventHistoryService.EventHistory[newindex]['dateTime'].replace(
            'T',
            ' '
          )
      );
    }
  }

 
}
