<div class="container">
  <div class="row">
    <div class="col-md-12">
      <ul>
        <li>
          <p>ჩართვის დროის წანაცვლება:</p> {{ChartvisDroisWanacvleba}}
        </li>
        <li>
          <p>გამორთვის დროის წანაცვლება:</p> {{GamortvisDroisWanacvleba}}
        </li>
        <li>
          <p>რიგგარეშე ჩართვა:</p> {{riggaresheChartva}}
        </li>
        <li>
          <p>რიგგარეშე გამორთვა:</p> {{riggaresheGamortva}}
        </li>
      </ul>
    </div>
  </div>
</div>