<img src="../../../assets/img/bg1.jpg" alt="">
<div class="wrapper" style="z-index: 1;">
  <div class="wrapped-div">
    <div class="tab-header">
      <h2 routerLink='/user/login' routerLinkActive='active'>შესვლა</h2>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
