<div style="width: 100%">
    <div class="d-flex justify-content-between">
      <mat-form-field>
        <mat-label>ფილტრი</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
      </mat-form-field>
      <button
        class="btn btn-outline-success btn-sm"
        style="border: none"
        (click)="exportexcel()"
      >
        Excel <mat-icon> cloud_download</mat-icon>
      </button>
    </div>
  
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort id="excel-table">
        <!-- Name Column -->
        <ng-container matColumnDef="DeviceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DeviceName</th>
          <td mat-cell *matCellDef="let row">{{ row.deviceName }}</td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="OBJECTID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>OBJECTID</th>
          <td mat-cell *matCellDef="let row">{{ row.objectId }}</td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="Longitude">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Longitude</th>
          <td mat-cell *matCellDef="let row">{{ row.longitude }}</td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="Latitude">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Latitude</th>
          <td mat-cell *matCellDef="let row">{{ row.latitude }}</td>
        </ng-container>
  
        <ng-container matColumnDef="KARADIS_NO">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>KARADIS_NO</th>
          <td mat-cell *matCellDef="let row">{{ row.karadiS_NO }}</td>
        </ng-container>
  
        <ng-container matColumnDef="RAIONI">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>RAIONI</th>
          <td mat-cell *matCellDef="let row">{{ row.raioni }}</td>
        </ng-container>
  
        <ng-container matColumnDef="DADGMULI_S">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DADGMULI_S</th>
          <td mat-cell *matCellDef="let row">{{ row.dadgmulI_S }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="KVEBIS_WYA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>KVEBIS_WYA</th>
          <td mat-cell *matCellDef="let row">{{ row.kvebiS_WYA }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="KARADIS_TI">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>KARADIS_TI</th>
          <td mat-cell *matCellDef="let row">{{ row.karadiS_TI }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="MARTVIS_BL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MARTVIS_BL</th>
          <td mat-cell *matCellDef="let row">{{ row.martviS_BL }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="MRICXVELIS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MRICXVELIS</th>
          <td mat-cell *matCellDef="let row">{{ row.mricxvelis }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="MRICXVEL_1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MRICXVEL_1</th>
          <td mat-cell *matCellDef="let row">{{ row.mricxveL_1 }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="SIDA_MOWYO">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SIDA_MOWYO</th>
          <td mat-cell *matCellDef="let row">{{ row.sidA_MOWYO }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="MDGOMAREOB">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MDGOMAREOB</th>
          <td mat-cell *matCellDef="let row">{{ row.mdgomareob }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="DAMIWEBA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DAMIWEBA</th>
          <td mat-cell *matCellDef="let row">{{ row.damiweba }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="SENISVNA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SENISVNA</th>
          <td mat-cell *matCellDef="let row">{{ row.senisvna }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="GAMAVALI_K">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>GAMAVALI_K</th>
          <td mat-cell *matCellDef="let row">{{ row.gamavalI_K }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="NUMBER_OF_">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>NUMBER_OF_</th>
          <td mat-cell *matCellDef="let row">{{ row.numbeR_OF_ }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="DISTRICT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DISTRICT</th>
          <td mat-cell *matCellDef="let row">{{ row.district }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="THE_INSTAL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>THE_INSTAL</th>
          <td mat-cell *matCellDef="let row">{{ row.thE_INSTAL }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="POWER_SOUR">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>POWER_SOUR</th>
          <td mat-cell *matCellDef="let row">{{ row.poweR_SOUR }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="TYPE_OF_TH">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>TYPE_OF_TH</th>
          <td mat-cell *matCellDef="let row">{{ row.typE_OF_TH }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="MANAGEMENT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MANAGEMENT</th>
          <td mat-cell *matCellDef="let row">{{ row.management }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="NUMBER_OF1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>NUMBER_OF1</th>
          <td mat-cell *matCellDef="let row">{{ row.numbeR_OF1 }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="TYPE_OF__1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>TYPE_OF__1</th>
          <td mat-cell *matCellDef="let row">{{ row.typE_OF__1 }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="INTERNAL_M">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>INTERNAL_M</th>
          <td mat-cell *matCellDef="let row">{{ row.internaL_M }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="GROUNDING">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>GROUNDING</th>
          <td mat-cell *matCellDef="let row">{{ row.grounding }}</td>
        </ng-container>
  
        <!-- Progress Column -->
        <ng-container matColumnDef="OUTPUT_CAB">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>OUTPUT_CAB</th>
          <td mat-cell *matCellDef="let row">{{ row.outpuT_CAB }}</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
  
      <mat-paginator
        [pageSizeOptions]="[20,50, 100, 2000]"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </div>