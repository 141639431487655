import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-audiodialog',
  templateUrl: './audiodialog.component.html',
  styleUrls: ['./audiodialog.component.scss'],
})
export class AudiodialogComponent {
  @Input() showAudio: boolean = false
  @Input() audioCallback: () => void
  isRouteLogin!: boolean
  constructor(private router: Router) {}

  AudioCheck() {
    if (this.audioCallback) {
      this.audioCallback()
    }
  }
}
