import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent implements OnInit {
  showAddDevice = true;

  toppings = new FormControl();

  toppingList: any[] = [];
  userList: any;
  UserId: any;
  addDeviceResult: any;
  userDetails: any;
  userId: any;
  deviceInfo = {};
  selectedDevice = '';
  selectedObjectId = '';
  unverfiedDevices: any = {};
  unverFieldDevicesFiltered = [];
  search = '';
  // fakeunverfiedDevices = [
  //   { imei: '14214' },
  //   { imei: '1124214' },
  //   { imei: '112412412' },
  //   { imei: '23432' },
  // ];
  imei;
  name;
  lat;
  long;
  adress;
  selectedUser;
  serialNumber;
  disableAddButton = true;
  constructor(
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getUserList();
    this.userInfo();
    this.getLastSerialNumber();
    this.unverifiedDevices();
  }

  onSearchChange(newValue: string) {
    this.search = newValue;
    this.searchImei();
  }

  searchImei() {
    this.unverFieldDevicesFiltered = this.unverfiedDevices.filter(
      (val: any) => {
        if (this.search == '') {
          return val;
        } else if (val.imei.toLowerCase().includes(this.search.toLowerCase())) {
          console.log(val);
          return val;
        }
      }
    );
  }

  // tslint:disable-next-line:typedef
  addDevice(userName, imei, name, deviceId, lat, long, adress, serialNumber) {
    // tslint:disable-next-line:forin
    // tslint:disable-next-line:prefer-for-of

    if (userName != '' && userName != undefined && userName != 'undefined') {
      for (let i = 0; i < Object.keys(this.userList).length; i++) {
        // tslint:disable-next-line:forin
        for (const x in this.userList[i]) {
          if (this.userList[i].name === userName[0]) {
            this.UserId = this.userList[i].id;
          }
        }
      }
    } else {
      this.UserId = this.userId;
    }

    this.userService
      .addDevice(
        this.UserId,
        imei,
        name,
        deviceId,
        lat,
        long,
        adress,
        this.selectedObjectId,
        serialNumber
      )
      .subscribe(
        (res) => {
          // tslint:disable-next-line:no-string-literal
          this.addDeviceResult = res;
          this.unverifiedDevices();
        },
        (err) => {
          if (err.error.text === 'Ok;Ok;Ok') {
            // tslint:disable-next-line:no-unused-expression

            this.toastr.success('მოწყობილობა წარმატებით დაემატა');
            this.deleteFromUnverified(imei);
          } else {
            this.toastr.error(
              'მოწყობილობის დამატება ვერ ხერდება, სერიული დაკავებულია'
            );
          }
        }
      );
  }

  // tslint:disable-next-line:typedef
  getUserList() {
    this.userService.getUserList().subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.userList = res;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(res).length; i++) {
          // tslint:disable-next-line:forin
          for (const x in res[i]) {
            if (!this.toppingList.includes(res[i].name)) {
              this.toppingList.push(res[i].name);
            }
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  avalaibleDevices() {
    this.userService.getMapAllInfo(this.userId).subscribe(
      (res) => {
        for (var i = 0; i < Object.keys(res).length; i++) {
          this.deviceInfo[res[i]['karadiS_NO']] = res[i];
        }
      },
      (err) => {}
    );
  }

  userInfo() {
    this.userService.getUserProfile().subscribe(
      (data) => {
        this.userDetails = data;
        this.userId = this.userDetails['id'];
        this.avalaibleDevices();
      },
      (err) => console.log(err)
    );
  }

  onBookChange(event: any) {
    this.selectedObjectId =
      event.value.value['objectid'] +
      ';' +
      event.value.value['deviceName'] +
      ';' +
      event.value.value['latitude'] +
      ';' +
      event.value.value['longitude'] +
      ';' +
      event.value.value['raioni'] +
      ';' +
      event.value.value['karadiS_NO'];
  }

  unverifiedDevices() {
    this.userService.getUnverifiedConnections().subscribe(
      (res) => {
        console.log(res);
        this.unverfiedDevices = res;
        this.unverFieldDevicesFiltered = this.unverfiedDevices;
      },
      (err) => {}
    );
  }

  addUnverified(val) {
    this.imei = val;
    this.lat = '0.000';
    this.long = '0.000';
    this.adress = 'თბილისი';
    this.name = val;
  }

  deleteFromUnverified(val) {
    this.userService.deleteFromUnverified(val).subscribe(
      (res) => {
        this.unverifiedDevices();
      },
      (err) => {
        this.unverifiedDevices();
      }
    );
  }
  getLastSerialNumber() {
    this.userService.getLastSerialNumber().subscribe(
      (res) => {
        this.serialNumber = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  checkImei(imei) {
    const data = {
      Imei: imei,
      // Imei: '',
      // UserId: '',
      // Name: '',
      // DeviceId: '',
      // Lat: '',
      // Long: '',
      // Adress: '',
      // ObjectId: '',
    };
    this.userService.checkImei(data).subscribe(
      (res) => {
        if (res == 200) {
          this.disableAddButton = true;
          this.toastr.error('Imei დაკავებულია');
        }
      },
      (err) => {
        if (err.error == 404) {
          this.disableAddButton = false;
        }
      }
    );
  }
}
