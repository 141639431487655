import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
    this.currentSection = this.sections.find(section => section.id === 'addDevice');
  }

showFiller = false;
sections = [
  { id: 'addDevice', title: 'მოწყობილობის დამატება' },
  { id: 'addUser', title: 'მომხმარებლის დამატება' },
  { id: 'bootLoader', title: 'მოწყობილობის დაპროგრამება' },
  { id: 'deleteDevice', title: 'მოწყობილობის წაშლა' },
  { id: 'editDevice', title: 'მოწყობილობის რედაქტირება' },
  { id: 'resetUserInfo', title: 'მომხმარებლის რედაქტირება' },
  { id: 'notificationPanel', title: 'ევენთების პანელი' },
  { id: 'deviceToUser', title: 'მოწყობილობის მართვა' },
  { id: 'deviceGraphic', title: 'მოწყობილობის გრაფიკი' },
  { id: 'deviceOrder', title: 'Device Order' },
];
currentSection: any 

toggleFiller() {
  this.showFiller = !this.showFiller;
}

toggleSection(section: any) {
  this.currentSection = this.currentSection === section ? null : section;
}

isActive(section: any) {
  return this.currentSection === section;
}


}
