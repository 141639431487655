import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  selectedRole: string;
  roleList = ['ადმინისტრატორი', 'დისპეჩერი'];
  constructor(public service: UserService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.service.formModel.reset();
  }
  // tslint:disable-next-line:typedef
  onSubmit(){
    this.service.register().subscribe(
      (res: any) => {
        if (res.succeeded){
        this.service.formModel.reset();
        this.toastr.success('რეგისტრაცია წარმატებით დასრულდა', 'Registration successful');
        } else {
          res.errors.forEach(element => {
            switch (element.code){
              case 'DuplicateUserName':
                this.toastr.error('მომხმარებლის სახელი დაკავებულია', 'Registration failed');
                // Username is already taken
                break;

                default:
              this.toastr.error(element.description, 'Registration failed');
              break;
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
