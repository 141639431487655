import { LiveAnnouncer } from '@angular/cdk/a11y';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment/moment.js';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { UserService } from 'src/app/services/user.service';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  fileName = 'ExcelSheet.xlsx';
  showReport: boolean;
  displayedColumns3: string[] = [
    // 'mobileNumber',
    'name',
    'deviceDateTime',
    'csq',
    // 'interval',
    'srOnOf',
    'managementMode',
    'door',
    'defence',
    'Ia',
    'Ib',
    'Ic',
    //  'closeState',
    'ua',
    'ub',
    'uc',
    'pa',
    'pb',
    'pc',
    'A',
    'B',
    'C',
    'numeratorCount',
    'PT',
    'PFA',
    'PFB',
    'PFC',
    'I1',
    'I2',
    'I3',
    'I4',
    'I5',
    'I6',
    'I7',
    'I8',
    'I9',
    'I10',
    'I11',
    'I12',
    'k1',
    'k2',
    'CurrentSensor',
    'NoiseLvl',
    'no2',
    //  'u2',
    'airTemp',
    'lux',
    'airHumidity',
    'airPollution',
    'WaltMainPower',
    'ElementWalt',
    'interval',
  ];

  displayedColumns: string[] = ['error', 'date'];

  turnOnDuration: string[] = ['Name', 'Duration', 'DateStart', 'DateEnd'];

  reportList = [
    'დაზიანება',
    'გრაფიკით ჩართვის ხანგრძლივობა',
    'გრაფიკით ჩართვის ხანგრძლივობა წუთებში',
    'რიგგარეშე ჩართვის ხანგრძლივობა',
    'მესიჯების ისტორია',
  ];

  datesToHighlight = [
    '2019-01-22T18:30:00.000Z',
    '2019-01-22T18:30:00.000Z',
    '2019-01-24T18:30:00.000Z',
    '2019-01-28T18:30:00.000Z',
    '2019-01-24T18:30:00.000Z',
    '2019-01-23T18:30:00.000Z',
    '2019-01-22T18:30:00.000Z',
    '2019-01-25T18:30:00.000Z',
  ];
  datesToHighlight1 = [
    '2019-01-22T18:30:00.000Z',
    '2019-01-22T18:30:00.000Z',
    '2019-01-24T18:30:00.000Z',
    '2019-01-28T18:30:00.000Z',
    '2019-01-24T18:30:00.000Z',
    '2019-01-23T18:30:00.000Z',
    '2019-01-22T18:30:00.000Z',
    '2019-01-25T18:30:00.000Z',
  ];
  today = new Date();
  time = '23:59';
  //2023/02/07
  year = this.today.getFullYear();
  day =
    this.today.getDate() < 10
      ? '0' + this.today.getDate()
      : this.today.getDate();
  month =
    this.today.getMonth() + 1 < 10
      ? '0' + (this.today.getMonth() + 1)
      : this.today.getMonth() + 1;

  date = new Date(this.year + '/' + this.month + '/' + this.day);

  dataSource: any;
  Starttime = '00:00';
  displayedColumns4: string[] = [];
  columnsToDisplay: string[] = [];
  Endtime = this.time;
  forHistoryGroup = new FormGroup({
    deviceName: new FormControl('', Validators.required),
    report: new FormControl('', Validators.required),
  });

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  selectedDevice: string;
  // tslint:disable-next-line:ban-types
  userDetails: Object;
  userId: any;
  pingMessage: any;
  deviceLog: {};
  toppings = new FormControl();
  topping: string;
  toppingList: any[] = [];
  CommandAnswe: any;
  showSpinner: boolean;
  selectedDate1 = this.date;
  selectedDate = this.date;
  startDate = this.year + '/' + this.month + '/' + this.day;
  endDate = this.year + '/' + this.month + '/' + this.day;
  totalSumFull: number;
  showExcelReport = false;
  deviceArr = [];
  sortByDate = {};
  deviceByMinute = {};
  sordByDateFilter: {};
  anotherArray: any[] = [];
  // tslint:disable-next-line:variable-name
  constructor(
    private http: HttpClient,
    private service: UserService,
    private _liveAnnouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getPingMessage(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /** Announce the change in sort state for assistive technology. */
  // tslint:disable-next-line:typedef
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  // testIng(testSubject: any) {
  //   console.log(testSubject);
  // }

  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
        }
        this.toppingList = Object.keys(this.deviceLog);

        this.anotherArray = this.toppingList;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  onChangeStartTime(event) {
    console.log('startTime', event);
  }

  // tslint:disable-next-line:typedef
  onChangeEndTime(event) {
    console.log('endTime', event);
  }
  // tslint:disable-next-line:typedef
  getDeviceLogHistory3(selectedDevice) {
    // const headers = { 'content-type': 'multipart/form-data'};
    this.showSpinner = true;
    var selectedDevices = this.forHistoryGroup.value.deviceName;
    let target = [];
    if (selectedDevices.includes('all')) {
      target = this.forHistoryGroup.value.deviceName;
    } else {
      for (var i = 0; i < selectedDevices.length; i++) {
        target.push(this.deviceLog[selectedDevices[i]]);
      }
    }
    const startTime = this.startDate + ' ' + this.Starttime;

    // tslint:disable-next-line:no-string-literal
    const endTime = this.endDate + ' ' + this.Endtime;

    // tslint:disable-next-line:no-string-literal

    const deviceReport = {
      DeviceImei: target,
      StartTime: startTime,
      EndTime: endTime,
    };

    const body = JSON.stringify(deviceReport);

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    this.http
      .post<any>(
        ' https://request2.mygps.ge:4490/api/DevicePingMessage/GetPingReport',
        body,
        {
          headers: headers,
        }
      )
      .subscribe(
        (res) => {
          // tslint:disable-next-line:no-string-literal
          this.CommandAnswe = res;
          // tslint:disable-next-line:no-string-literal
          this.showSpinner = false;
          this.showReport = false;

          if (res.length > 1) {
            this.dataSource = new MatTableDataSource(this.CommandAnswe);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            // tslint:disable-next-line:prefer-for-of
            // tslint:disable-next-line:no-string-literal
            this.totalSumFull =
              parseFloat(res[res.length - 1]['numeratorCount']) -
              parseFloat(res[0]['numeratorCount']);
            // tslint:disable-next-line:no-string-literal
          }
        },
        (err) => {
          this.showSpinner = false;
          this.showReport = false;
        }
      );
  }

  getDeviceLogHistory(selectedDevice) {
    // const headers = { 'content-type': 'multipart/form-data'};

    if (this.forHistoryGroup.value.report == 'მესიჯების ისტორია') {
      this.getDeviceLogHistory3(selectedDevice);
    } else {
      this.showSpinner = true;
      var selectedDevices = this.forHistoryGroup.value.deviceName;
      let target = [];
      if (selectedDevices.includes('all')) {
        target = this.forHistoryGroup.value.deviceName;
      } else {
        for (var i = 0; i < selectedDevices.length; i++) {
          target.push(this.deviceLog[selectedDevices[i]]);
        }
      }
      console.log(target);

      const startTime = this.startDate + ' ' + this.Starttime;

      // tslint:disable-next-line:no-string-literal
      const endTime = this.endDate + ' ' + this.Endtime;

      // tslint:disable-next-line:no-string-literal

      const deviceReport = {
        DeviceImei: target,
        StartTime: startTime,
        EndTime: endTime,
      };

      const body = JSON.stringify(deviceReport);

      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf-8'
      );

      if (this.forHistoryGroup.value.report == 'დაზიანება') {
        this.http
          .post<any>(
            ' https://request2.mygps.ge:4490/api/DevicePingMessage/getReport',
            body,
            {
              headers: headers,
            }
          )
          .subscribe(
            (res) => {
              // tslint:disable-next-line:no-string-literal
              this.CommandAnswe = res;
              // tslint:disable-next-line:no-string-literal

              this.showSpinner = false;
              this.showReport = false;
              if (res.length > 0) {
                this.dataSource = new MatTableDataSource(this.CommandAnswe);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

                // tslint:disable-next-line:prefer-for-of
                // tslint:disable-next-line:no-string-literal
                this.totalSumFull =
                  parseFloat(res[res.length - 1]['numeratorCount']) -
                  parseFloat(res[0]['numeratorCount']);
                // tslint:disable-next-line:no-string-literal
              }
            },
            (err) => {
              console.log(err);
              this.showSpinner = false;
              this.showReport = false;
            }
          );
      }

      if (
        this.forHistoryGroup.value.report == 'გრაფიკით ჩართვის ხანგრძლივობა' ||
        this.forHistoryGroup.value.report == 'რიგგარეშე ჩართვის ხანგრძლივობა'
      ) {
        this.service
          .report(target, this.forHistoryGroup.value.report, startTime, endTime)
          .subscribe(
            (data) => {
              this.CommandAnswe = data;
              this.dataSource = new MatTableDataSource(this.CommandAnswe);
              this.showSpinner = false;
              this.showReport = false;
            },
            (err) => {
              this.showSpinner = false;
              this.showReport = false;
            }
          );
      }
      if (
        this.forHistoryGroup.value.report ==
        'გრაფიკით ჩართვის ხანგრძლივობა წუთებში'
      ) {
        this.service
          .graphicReportMinutes(
            target,
            this.forHistoryGroup.value.report,
            startTime,
            endTime
          )
          .subscribe(
            (data) => {
              this.CommandAnswe = data.sort((a, b) =>
                a.name.localeCompare(b.name)
              );

              this.displayedColumns4 = [];
              this.sortByDate = {};

              for (let i = 0; i < this.CommandAnswe.length; i++) {
                this.deviceArr = [];

                if (this.sortByDate[this.CommandAnswe[i].date] == undefined) {
                  this.deviceArr.push(this.CommandAnswe[i]);
                } else {
                  this.deviceArr = this.sortByDate[this.CommandAnswe[i].date];
                  if (
                    this.deviceArr.find(
                      (x) => x.name == this.CommandAnswe[i].name
                    ) == undefined
                  ) {
                    this.deviceArr.push(this.CommandAnswe[i]);
                  }
                }
                this.sortByDate[this.CommandAnswe[i].date] = this.deviceArr;

                if (
                  !this.displayedColumns4.includes(this.CommandAnswe[i].name)
                ) {
                  this.displayedColumns4.push(this.CommandAnswe[i].name);
                }
              }

              var sort = Object.keys(this.sortByDate);
              for (let i = 0; i < this.displayedColumns4.length; i++) {
                for (let j = 0; j < Object.keys(this.sortByDate).length; j++) {
                  if (
                    this.sortByDate[sort[j]].find(
                      (d) => d.name == this.displayedColumns4[i]
                    ) == undefined
                  ) {
                    var objArr = this.sortByDate[sort[j]];
                    objArr.push({
                      date: '',
                      deviceE_ID: 0,
                      name: this.displayedColumns4[i],
                      miN_PER_DAY: 0,
                    });
                    this.sortByDate[sort[j]] = objArr.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    );
                  }
                }
              }
              this.sordByDateFilter = this.sortByDate;
              this.showSpinner = false;
              this.showReport = false;
            },
            (err) => {
              this.showSpinner = false;
              this.showReport = false;
            }
          );
      }
    }
  }

  keepOrder = (a, b) => {
    return a;
  };

  async exportexcel() {
    this.showExcelReport = true;

    if (this.forHistoryGroup.value.report == 'დაზიანება') {
      const filterData = this.dataSource.data.map(({ evenet, dateTime }) => ({
        დაზიანება: evenet,
        თარიღი: dateTime.replace('T', ' '),
      }));
      /* table id is passed over here */
      /* table id is passed over here */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filterData);
      XLSX.utils.sheet_add_aoa(ws, [['დაზიანება', 'თარიღი']], { origin: 'A1' }); // add header row

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const filename = 'Report_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx';
      await XLSX.writeFile(wb, filename);
    }
    if (
      this.forHistoryGroup.value.report == 'გრაფიკით ჩართვის ხანგრძლივობა' ||
      this.forHistoryGroup.value.report == 'რიგგარეშე ჩართვის ხანგრძლივობა'
    ) {
      console.log(this.dataSource.data);

      const filterData = this.dataSource.data.map(
        ({ date, name, turnOnTime }) => ({
          'გრაფიკით ჩართვის დრო': date.split('-')[1],
          'გრაფიკით გამორთვის დრო': date.split('-')[0],
          სახელი: name,
          ხანგრძლივობა: turnOnTime,
        })
      );
      /* table id is passed over here */
      /* table id is passed over here */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filterData);
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            'გრაფიკით ჩართვის დრო',
            'გრაფიკით გამორთვის დრო',
            'სახელი',
            'ხანგრძლივობა',
          ],
        ],
        { origin: 'A1' }
      ); // add header row

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const filename = 'Report_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx';
      await XLSX.writeFile(wb, filename);
    }
    if (this.forHistoryGroup.value.report == 'მესიჯების ისტორია') {
      const filterData = this.dataSource.data.map(
        ({
          name,
          airHumidity,
          airPollution,
          airTemp,
          csq,
          currentSensor,
          deviceDateTime,
          doorState,
          elementWalt,
          i1,
          i2,
          i3,
          i4,
          i5,
          i6,
          i7,
          i8,
          i9,
          i10,
          i11,
          i12,
          ia,
          ib,
          ic,
          interval,
          k1,
          k2,
          lux,
          managementMode,
          no2,
          noiseLvlv,
          numeratorCount,
          pa,
          pb,
          pc,
          pfa,
          pfb,
          pfc,
          pt,
          srOnOf,
          ua,
          ub,
          uc,
          ukudzabvaA,
          ukudzabvaB,
          ukudzabvaC,
          waltMainPower,
        }) => ({
          სახელი: name,
          ტენიანობა: airHumidity,
          დაბინძურება: airPollution,
          airTemp: airTemp,
          'Gsm სიგნალი': csq,
          'დენის სენსორი': currentSensor,
          'მოწყობილობის დრო': deviceDateTime,
          'კარადის კარი':
            doorState.split('.')[0] == '0'
              ? 'დაკეტილია განგაში არაა'
              : doorState.split('.')[0] == '1'
              ? 'ღიაა განგაში არაა'
              : doorState.split('.')[0] == '2'
              ? 'დაკეტილია განგაშია'
              : doorState.split('.')[0] == '3'
              ? 'ღიაა განგაშია'
              : '',
          elementWalt: elementWalt,
          I1: i1,
          I2: i2,
          I3: i3,
          I4: i4,
          I5: i5,
          I6: i6,
          I7: i7,
          I8: i8,
          I9: i9,
          I10: i10,
          I11: i11,
          I12: i12,
          Ia: ia,
          Ib: ib,
          Ic: ic,
          ინტერვალი: interval,
          K1:
            k1 == '0'
              ? 'გამორთულია/გრაფიკით'
              : k1 == '1'
              ? 'ჩართულია/გრაფიკით'
              : k1 == '2'
              ? 'გამორთულია/ხელით'
              : k1 == '3'
              ? 'ჩართულია/ხელით'
              : k1 == '4'
              ? 'გამორთლია/იუზ.გრაფიკით'
              : k1 == '5'
              ? 'ჩართულია/იუზ.გრაფიკით'
              : '',
          K2:
            k2 == '0'
              ? 'გამორთულია/გრაფიკით'
              : k2 == '1'
              ? 'ჩართულია/გრაფიკით'
              : k2 == '2'
              ? 'გამორთულია/ხელით'
              : k2 == '3'
              ? 'ჩართულია/ხელით'
              : k2 == '4'
              ? 'გამორთლია/იუზ.გრაფიკით'
              : k2 == '5'
              ? 'ჩართულია/იუზ.გრაფიკით'
              : '',
          განათებულობა: lux,
          'მართვის რეჟიმი':
            managementMode == '0'
              ? 'გრაფიკით'
              : managementMode == '1'
              ? 'გრაფიკით'
              : managementMode == '2'
              ? 'ხელით'
              : managementMode == '3'
              ? 'ხელით'
              : managementMode == '4'
              ? 'იუზ.გრაფიკით'
              : managementMode == '5'
              ? 'იუზ.გრაფიკით'
              : '',
          'აზოტის დიოქსიდი': no2,
          ხმაურიანობა: noiseLvlv,
          'მრიცხველის ჩვენება': numeratorCount,
          Pa: pa,
          Pb: pb,
          Pc: pc,
          Pfa: pfa,
          Pfb: pfb,
          Pfc: pfc,
          Pt: pt,
          'ჩართვა/გამორთვა': srOnOf,
          Ua: ua,
          Ub: ub,
          Uc: uc,
          'უკუძაბვა A': ukudzabvaA,
          'უკუძაბვა B': ukudzabvaB,
          'უკუძაბვა C': ukudzabvaC,
          WaltMainPower: waltMainPower,
        })
      );
      /* table id is passed over here */
      /* table id is passed over here */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filterData);
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            'სახელი',
            'ტენიანობა',
            'დაბინძურება',
            'airTemp',
            'Gsm სიგნალი',
            'დენის სენსორი',
            'მოწყობილობის დრო',
            'კარადის კარი',
            'elementWalt',
            'I1',
            'I2',
            'I3',
            'I4',
            'I5',
            'I6',
            'I7',
            'I8',
            'I9',
            'I10',
            'I11',
            'I12',
            'Ia',
            'Ib',
            'Ic',
            'ინტერვალი',
            'K1',
            'K2',
            'განათებულობა',
            'მართვის რეჟიმი',
            'აზოტის დიოქსიდი',
            'ხმაურიანობა',
            'მრიცხველის ჩვენება',
            'Pa',
            'Pb',
            'Pc',
            'Pfa',
            'Pfb',
            'Pfc',
            'Pt',
            'ჩართვა/გამორთვა',
            'Ua',
            'Ub',
            'Uc',
            'უკუძაბვა A',
            'უკუძაბვა B',
            'უკუძაბვა C',
            'WaltMainPower',
          ],
        ],
        { origin: 'A1' }
      ); // add header row

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const filename = 'Report_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx';

      await XLSX.writeFile(wb, filename);
    }

    if (
      this.forHistoryGroup.value.report ==
      'გრაფიკით ჩართვის ხანგრძლივობა წუთებში'
    ) {
      const element = document.getElementById(
        'excel-table'
      ) as HTMLTableElement;

      /* Retrieve the data from the table */
      const tableData = Array.from(element.rows).map((row, rowIndex) => {
        return Array.from(row.cells).map((cell, columnIndex) => {
          const cellValue = (cell as HTMLTableCellElement).innerText.trim();

          if (rowIndex === 0) {
            return cellValue;
          } else {
            if (columnIndex === 0) {
              return cellValue;
            } else {
              return parseInt(cellValue);
            }
          }
        });
      });

      /* Sort the data by the date column (assuming it's the first column) */
      tableData.sort((a, b) => {
        const dateA = new Date(a[0]);
        const dateB = new Date(b[0]);
        return dateA.getTime() - dateB.getTime();
      });

      /* Create a new worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableData, {
        header: ['სახელი', 'თარიღი'], // Replace with your column headers
        skipHeader: true, // Set to false if your table already includes header row
      });

      /* Generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      const filename = 'Report_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx';
      /* Save to file */
      XLSX.writeFile(wb, filename);
    }
    this.showExcelReport = false;
  }

  exportPdf(): void {
    const doc = new jsPDF();
    doc.addPage('a3', 'portrait');
    doc.setFont('arial_geo');
    doc.deletePage(1),
      autoTable(doc, {
        html: '#excel-table',
        styles: { font: 'arial_geo', fontSize: 8 },
      });
    doc.save('table.pdf');
  }

  // tslint:disable-next-line:typedef
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (
      this.forHistoryGroup.value.report !==
      'გრაფიკით ჩართვის ხანგრძლივობა წუთებში'
    ) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    if (
      this.forHistoryGroup.value.report ==
      'გრაფიკით ჩართვის ხანგრძლივობა წუთებში'
    ) {
      this.sortByDate = Object.entries(this.sordByDateFilter).reduce(
        (sortDate, [key, value]) => {
          if (
            key.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
          ) {
            sortDate[key] = value;
          }
          return sortDate;
        },
        {}
      );
    }
  }

  // tslint:disable-next-line:typedef
  getChangedValue(startDate: Date) {
    this.startDate = startDate.toLocaleDateString('en-ZA');
    this.selectedDate = startDate;

    if (this.startDate == this.endDate) {
      this.Endtime = this.time;
    } else {
      this.Endtime = '23:59';
    }
    // 'YY/MM/DD'
  }
  // tslint:disable-next-line:typedef
  getChangedValue1(enddate: Date) {
    this.endDate = enddate.toLocaleDateString('en-ZA');
    this.selectedDate1 = enddate;
    console.log('endDate ' + this.endDate);
  }
  // tslint:disable-next-line:typedef
  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.datesToHighlight
        .map((strDate) => new Date(strDate))
        .some(
          (d) =>
            d.getDate() === date.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        );
      return highlightDate ? 'special-date' : '';
    };
  }
  // tslint:disable-next-line:typedef
  dateClass1() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate1 = this.datesToHighlight
        .map((strDate) => new Date(strDate))
        .some(
          (d) =>
            d.getDate() === date.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        );
      return highlightDate1 ? 'special-date' : '';
    };
  }

  filterListCareUnit(val) {
    this.toppingList = this.anotherArray.filter(
      (unit) => unit.indexOf(val) > -1
    );
    console.log(this.toppingList);
  }
}
export interface StartMessages {
  mobileNumber: string;
  name: string;
  deviceDateTime: string;
  csq: number;
  interval: number;
  srOnOf: string;
  managementMode: number;
  plannedGraphicTurnOnOffTime: string;
  plannedGraphicOffsetMins: string;
  extraOrdinaryGraphicTurnOnoffTime: string;
  closeState: string;
  voltage: string;
  current: string;
  powerCoefficient: string;
  activePower: string;
  fullPower: string;
  numeratorActiveTestimony: string;
  numeratorFullTestimony: string;
  u1: string;
  u2: string;
  counterChipTemp: number;
  voltageCoef: number;
  powerCoef: number;
  date: Date;
}
