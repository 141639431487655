<div class="search">
  <div *ngIf="!filterToggle && showImei == false">
    <!-- <p>ფილტრი:</p>  -->
     <input type="text" [(ngModel)]="userFilter.name" placeholder="ძებნა" (keyup)="addNewItem('name',userFilter.name)">
     <button style="height:28px" class="btn btn-primary btn-sm" (click)="filterToggle = !filterToggle;showImei = true">
      <p *ngIf="filterToggle  && showImei == false">SERIAL</p>
      <p *ngIf="!filterToggle  && showImei == false">NAME</p>
      <p *ngIf="showImei">IMEI</p>
      <p *ngIf="showImei == null">mobileNumber</p>
    </button>
  </div>

  <div *ngIf="filterToggle && showImei == false">
    <p>ფილტრი:</p>  <input type="text" [(ngModel)]="userFilter.seriulI_NO" placeholder="ძებნა" (keyup)="addNewItem('serial',userFilter.seriulI_NO)" >
    <button  style="height:28px" class="btn btn-primary btn-sm" (click)="filterToggle = !filterToggle;showImei = false">
      <p *ngIf="filterToggle  && showImei == false">SERIAL</p>
      <p *ngIf="!filterToggle  && showImei == false">NAME</p>
      <p *ngIf="showImei">IMEI</p>
      <p *ngIf="showImei == null">mobileNumber</p>
    </button>
  </div>
  <div *ngIf="showImei == true">
    <p>ფილტრი:</p>  <input type="text" [(ngModel)]="userFilter.imei" placeholder="ძებნა" (keyup)="addNewItem('imei',userFilter.imei)">
    <button  style="height:28px" class="btn btn-primary btn-sm" (click)="filterToggle = !filterToggle;showImei = null">
      <p *ngIf="filterToggle  && showImei == false">SERIAL</p>
      <p *ngIf="!filterToggle  && showImei == false">NAME</p>
      <p *ngIf="showImei">IMEI</p>
      <p *ngIf="showImei == null">mobileNumber</p>
    </button>
  </div>
  <div *ngIf="showImei == null">
    <p>ფილტრი:</p>  <input type="text" [(ngModel)]="userFilter.mobileNumber" placeholder="ძებნა" (keyup)="addNewItem('mobileNumber',userFilter.mobileNumber)">
    <button  style="height:28px" class="btn btn-primary btn-sm" (click)="filterToggle = !filterToggle;showImei = false">
      <p *ngIf="filterToggle  && showImei == false">SERIAL</p>
      <p *ngIf="!filterToggle  && showImei == false">NAME</p>
      <p *ngIf="showImei">IMEI</p>
      <p *ngIf="showImei == null">mobileNumber</p>
    </button>
  </div>
</div>