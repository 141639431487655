<div class="row">
  <div class="col s6 offset-s3">
      <div class="card">
          <div class="card-image" >
            <img src="/assets/img/403.png" style="height:80%;width:80%">
          </div>
          <div class="card-content">
              <span class="card-title" style="color:#039be5">403- ACCESS DENIED</span>
              <p>you don,t have permission to access this resource.</p>
          </div>
      </div>
  </div>
</div>
