import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ShareDataService } from 'src/app/services/share-data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-device-order',
  templateUrl: './device-order.component.html',
  styleUrls: ['./device-order.component.scss'],
})
export class DeviceOrderComponent implements OnInit {
  // tslint:disable-next-line:ban-types
  userList: Object;
  toppingList: any;
  pingMessage: any;
  deviceLog: {};
  devices: string[];
  // tslint:disable-next-line:ban-types
  deviceIdName: Object;
  nonUserDevices: string[];
  righthArrow: boolean;
  leftarrow: boolean;
  deviceName: string;
  userId: any;
  NameDevId: {};
  nameIdForNonUserDevices: any;
  deviceGroup = [];
  monitorNames = [];
  targetDeviceGroup: any;
  selectedArrayIndex: any;
  targetDeviceGroup1: any;
  firstIndex = -1;
  secondIndex = -1;
  selectedArrayIndexRight: number;
  deviceOrderShareData: any;
  deviceGroupDict = {};
  deviceGroup1 = {};
  constructor(
    private userService: UserService,
    private shareData: ShareDataService
  ) {
    this.deviceOrderShareData =
      shareData.currentApprovalStageMessage6.subscribe((msg) => {
        this.OpenMonitor(parseInt(msg), 0, 'left');
      });
  }

  ngOnInit(): void {
    const payLoad = JSON.parse(
      window.atob(localStorage.getItem('token').split('.')[1])
    );
    // tslint:disable-next-line:prefer-const
    // tslint:disable-next-line:member-ordering
    this.userId = payLoad.UserID;
    this.getDeviceList(this.userId);
    this.deviceGroup = JSON.parse(
      localStorage.getItem('DeviceOrder')
    );
  }

  // tslint:disable-next-line:typedef
  getDeviceList(id: string) {
    const userId = id;
    this.userService.PingMessage(userId).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        this.NameDevId = {};

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          this.NameDevId[this.pingMessage[i].name] =
            this.pingMessage[i].deviceId;

          if (
            !(
              this.pingMessage[i].name + '__' + this.pingMessage[i].deviceId in
              this.deviceLog
            )
          ) {
            this.deviceLog[
              this.pingMessage[i].name + '__' + this.pingMessage[i].deviceId
            ] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[
              this.pingMessage[i].name + '__' + this.pingMessage[i].deviceId
            ] = this.pingMessage[i].imei;
          }
        }
        this.devices = Object.keys(this.deviceLog);
    
        if (this.deviceGroup == null) {
          this.deviceGroup = this.sliceIntoChunks(this.devices, 300);
        }else{
          this.deviceGroup = this.deviceGroup[this.userId];
        }
        for (let i = 0; i < this.deviceGroup.length; i++) {
          this.monitorNames.push('მონიტორი ' + i);
        }
    
        this.deviceGroup1 = JSON.parse(
          localStorage.getItem('DeviceOrder')
        );
         this.deviceGroup1[this.userId] = this.deviceGroup
        localStorage.setItem(
          'DeviceOrder',
          JSON.stringify(this.deviceGroup1)
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.deviceGroup[this.selectedArrayIndex] = event.container.data;
      var currentArr =  JSON.parse(
        localStorage.getItem('DeviceOrder')
      );
      
      currentArr[this.userId] = this.deviceGroup;
      localStorage.setItem(
        'DeviceOrder',
        JSON.stringify(currentArr)
      );

    } else {
      transferArrayItem(
        event.previousContainer.data, //dakla
        event.container.data, // imateba
        event.previousIndex,
        event.currentIndex
      );

      var leftBox =
        event.container.element.nativeElement['firstElementChild'].id; //sadac gadagvaq
      if (leftBox == 'left-box') {
        this.deviceGroup[this.selectedArrayIndexRight] =
          event.previousContainer.data;
        this.deviceGroup[this.selectedArrayIndex] = event.container.data;
      } else {
        this.deviceGroup[this.selectedArrayIndexRight] = event.container.data;
        this.deviceGroup[this.selectedArrayIndex] =
          event.previousContainer.data;
      }

    var currentArr =  JSON.parse(
      localStorage.getItem('DeviceOrder')
    );
    
    currentArr[this.userId] = this.deviceGroup;
      localStorage.setItem(
        'DeviceOrder',
        JSON.stringify(currentArr)
      );

      // localStorage.setItem('DeviceOrder' + this.userId, JSON.stringify(this.deviceGroup));
    }
  }

  sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  OpenMonitor(indexLeft: number, indexRight: number, position: string) {
    if (position == 'left') {
      this.firstIndex = indexLeft;
      this.targetDeviceGroup = this.deviceGroup[indexLeft];
    } else {
      this.secondIndex = indexRight;
      this.targetDeviceGroup1 = this.deviceGroup[indexRight];
    }
    this.selectedArrayIndex = indexLeft;
    this.selectedArrayIndexRight = indexRight;
  }
}