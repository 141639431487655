import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UserService } from '../services/user.service'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  menuButton: string
  userDetails: any
  EventHistory: {}
  EventHistoryLength: number
  allowOtherComands: any
  userId: string
  constructor(private router: Router, private service: UserService) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        const token = localStorage.getItem('token')
        const payLoad = JSON.parse(
          window.atob(localStorage.getItem('token').split('.')[1]),
        )
        // tslint:disable-next-line:prefer-const
        // tslint:disable-next-line:member-ordering
        this.userId = payLoad.UserID
        const deviceOrder = JSON.parse(localStorage.getItem('DeviceOrder'))
        const selectedPage = localStorage.getItem('selectedPage')
        const deviceCommandLog = localStorage.getItem('commandLog')
        const soundDisabled = localStorage.getItem('soundDisabled')
        localStorage.clear()
        localStorage.setItem('token', token)
        localStorage.setItem('DeviceOrder', JSON.stringify(deviceOrder))
        localStorage.setItem('selectedPage', selectedPage)
        localStorage.setItem('commandLog', deviceCommandLog)
        localStorage.setItem('soundDisabled', soundDisabled)
        // tslint:disable-next-line:no-string-literal
        this.userDetails = res['userName']
        // tslint:disable-next-line:no-string-literal
        this.checkUserLimits(res['id'])
      },
      (err) => {
        console.log(err)
      },
    )
  }
  // tslint:disable-next-line:typedef
  onLogout() {
    localStorage.removeItem('token')
    this.router.navigate([''])
  }

  // tslint:disable-next-line:typedef
  getDeviceEvents(userId) {
    this.service.deviceEvents(userId).subscribe(
      (res) => {
        this.EventHistory = {}
        // tslint:disable-next-line:no-string-literal
        this.EventHistory = res
        console.log('EVENTHISTORY', this.EventHistory)
        this.EventHistoryLength = Object.keys(this.EventHistory).length
      },
      (err) => {
        console.log(err)
      },
    )
  }

  // tslint:disable-next-line:typedef
  checkUserLimits(userId) {
    this.service.selectUserLimits(userId).subscribe(
      (res) => {
        this.allowOtherComands = res[0].split(';')[5]
      },
      (err) => {
        console.log(err)
      },
    )
  }
  // // tslint:disable-next-line:typedef
  // this.mobileBtn.addEventListener('click', () => {
  //   this.nav.classList.add('menu-btn');
  // });

  // this.mobileBtnExit.addEventListener('click', () => {
  //   this.nav.classList.remove('menu-btn');
  // });
  // tslint:disable-next-line:prefer-const
  // tslint:disable-next-line:member-ordering
  payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]))
  // tslint:disable-next-line:prefer-const
  // tslint:disable-next-line:member-ordering
  userRole = this.payLoad.role
  // tslint:disable-next-line:typedef
  openMenu() {
    this.menuButton = 'block'
  }

  // tslint:disable-next-line:typedef
  closeMenu() {
    this.menuButton = 'none'
  }
}
