import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineAll } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { smsCommandsType } from 'src/app/types/CommandTypes';

@Component({
  selector: 'app-device-other-commands',
  templateUrl: './device-other-commands.component.html',
  styleUrls: ['./device-other-commands.component.scss'],
})
export class DeviceOtherCommandsComponent implements OnInit, OnDestroy {
  commandTarget: string;
  CommandAnswe: any;
  pingMessage: any;
  setNtp: any;
  selectedDevice: string;
  toppings = new FormControl();
  Sms = new FormControl();
  rellayPortFormController = new FormControl();
  topping: string;
  toppingList: any[] = [];
  deviceLog: {};
  userDetails: any;
  userId: any;
  mainIp: string;
  mainPort: string;
  reservIp: string;
  reservPort: string;
  timeOn = '';
  timeOff = '';
  timeOnK1 = '';
  timeOffK1 = '';
  timeOnK2 = '';
  timeOffK2 = '';
  timeOnK3 = '';
  timeOffK3 = '';
  wanacvleba: string;
  wanacvlebaori: string;
  wanacvlebaK1: string;
  wanacvlebaoriK1: string;
  wanacvlebaK2: string;
  wanacvlebaoriK2: string;
  wanacvlebaK3: string;
  wanacvlebaoriK3: string;
  tabColour = 'primary';
  setPower = '384.001';
  setvoltage = '100.002';
  deviceObject = {};
  debugLog: any;
  sendingCommandHistory: string | string[];
  debugInterval: any;
  anotherArray: string[];
  //  sms commands ....
  @ViewChild('sendSms', { static: false }) sendSmsInput!: ElementRef;

  smsCommands: smsCommandsType[] = [
    {
      title: 'apn',
      key: 'apn',
    },
    {
      title: 'ip1',
      key: 'ip1',
    },
    {
      title: 'ip2',
      key: 'ip2',
    },
    {
      title: 'port1',
      key: 'port1',
    },
    {
      title: 'port2',
      key: 'port2',
    },
    {
      title: 'restart',
      key: 'restart',
    },
    {
      title: 'Turn On Rellay',
      key: '1',
    },
    {
      title: 'Turn Off Rellay',
      key: '0',
    },
    {
      title: 'Turn On Schedule',
      key: '2',
    },
  ];
  sendSms: string = '';
  //  Turn On/Off Rellay key არის დაგავშირებული rellayPorts ლისტთან
  rellayPorts: string[] = ['rel1', 'rel2', 'rel3'];
  singleRellayPort: string = '';
  smsCmd: smsCommandsType = { title: '', key: '' };
  //  sms commands ....

  showCalibration = false;
  interval: any;

  constructor(
    private http: HttpClient,
    private service: UserService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.getUserProfile();
    // this.interval = setInterval(() => {
    //   this.getUserProfile();
    //   // this.getDeviceEvents(this.userId);
    // }, 2000);
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params !== undefined && params !== null) {
        if (params.code === '2023') {
          this.showCalibration = true;
        }
      }
    });
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.debugInterval) {
      clearInterval(this.debugInterval);
    }
  }

  deviceCommands2(command) {
    var unix = Math.round(+new Date() / 1000).toString(16);

    if (command.includes('&&')) {
      command = '+cmd_' + command.split('&&')[1];
      unix = Math.round(+new Date() / 1000).toString(16);
      console.log('like a john dee');
      console.log(command);
      console.log('like a john dee');
    }
    command = command.replace('_', '_' + unix.toLocaleUpperCase() + '_');
  }

  // tslint:disable-next-line:typedef
  deviceCommands(command: string, target, event?) {
    for (var i = 0; i < target.length; i++) {
      var sendingCommand =
        localStorage.getItem('commandLog') != null
          ? localStorage.getItem('commandLog')
          : '';
      localStorage.setItem('commandLog', sendingCommand + ';' + command);
      // const headers = { 'content-type': 'multipart/form-data'};
      const body = new FormData();
      // tslint:disable-next-line:prefer-const
      let targetImei = this.deviceLog[target[i]];

      // if (event.path[0].id) {
      //   var clickedButton = document.getElementById(
      //     event.path[0].id
      //   ) as HTMLButtonElement;

      //   clickedButton.disabled = true;
      // } else {
      //   var clickedButton = document.getElementById(
      //     event.path[0].id
      //   ) as HTMLButtonElement;

      //   clickedButton.disabled = true;
      // }

      if (event !== undefined && event.path !== undefined) {
        if (event.path[1].id) {
          var clickedButton = document.getElementById(
            event.path[1].id
          ) as HTMLButtonElement;

          clickedButton.disabled = true;
        } else {
          var clickedButton = document.getElementById(
            event.path[0].id
          ) as HTMLButtonElement;

          clickedButton.disabled = true;
        }
      }

      if (command.includes('cmd_user_schedulers')) {
        // tslint:disable-next-line:no-string-literal
        const K0 =
          // tslint:disable-next-line:no-string-literal
          this.deviceObject[targetImei]['extraOrdinaryGraphicTurnOnoffTime'];

        const K1 =
          // tslint:disable-next-line:no-string-literal
          this.deviceObject[targetImei]['k1rigGaresheChartva'] +
          '-' +
          // tslint:disable-next-line:no-string-literal
          this.deviceObject[targetImei]['k1rigGaresheGamortva'];

        const K2 =
          // tslint:disable-next-line:no-string-literal
          this.deviceObject[targetImei]['k2rigGaresheChartva'] +
          '-' +
          // tslint:disable-next-line:no-string-literal
          this.deviceObject[targetImei]['k2rigGaresheGamortva'];

        const K3 =
          // tslint:disable-next-line:no-string-literal
          this.deviceObject[targetImei]['k3rigGaresheChartva'] +
          '-' +
          // tslint:disable-next-line:no-string-literal
          this.deviceObject[targetImei]['k3rigGaresheGamortva'];

        const commandArr = command.split('/');
        if (commandArr[0].includes('undefined')) {
          commandArr[0] = K0;
        }

        if (commandArr[1].includes('undefined')) {
          commandArr[1] = K1;
        }

        if (commandArr[2].includes('undefined')) {
          commandArr[2] = K2;
        }

        if (commandArr[3].includes('undefined')) {
          commandArr[3] = K3;
        }

        command = commandArr.join('/');
      }
      var unix = Math.round(+new Date() / 1000).toString(16);

      if (command.includes('&&')) {
        command = '+cmd_' + command.split('&&')[1];
        unix = Math.round(+new Date() / 1000).toString(16);
        console.log('like a john dee');
        console.log(command);
        console.log('like a john dee');
      }
      command = command.replace('_', '_' + unix.toLocaleUpperCase() + '_');
      this.service.deviceCommands(command, targetImei).subscribe(
        (res) => {
          // tslint:disable-next-line:no-string-literal
          this.CommandAnswe = res;
          clickedButton.disabled = false;
          if (res.length == 0 || res[0].responseType != 'Ok') {
            this.toastr.error('ბრძანება არ გაიგზავნა');
          } else {
            this.toastr.success('ბრძანება გაიგზავნა');
          }
        },
        (err) => {
          console.log(err);
          clickedButton.disabled = false;
          if (err) {
            this.toastr.error('ბრძანება არ გაიგზავნა');
          }
        }
      );
    }
  }

  getUserProfile() {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getPingMessage(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          if (!(this.pingMessage[i].name in this.deviceLog)) {
            this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[this.pingMessage[i].name] = this.pingMessage[i].imei;
          }

          this.deviceObject[this.pingMessage[i].imei] = this.pingMessage[i];
        }

        this.toppingList = Object.keys(this.deviceLog);

        this.anotherArray = Object.keys(this.deviceLog);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  deviceCommandsWanacvleba(wanacvleba: string, target, event) {
    const wanacvlebaArr = wanacvleba.split(';');

    var unix = Math.round(+new Date() / 1000).toString(16);
    let command = 'cmd_user_ofsets ' + wanacvleba;
    command = command.replace('_', '_' + unix.toLocaleUpperCase() + '_');

    const body = new FormData();
    // tslint:disable-next-line:prefer-const
    let targetImei = this.deviceLog[target];

    if (event !== undefined && event.path !== undefined) {
      if (event.path[1].id) {
        var clickedButton = document.getElementById(
          event.path[1].id
        ) as HTMLButtonElement;

        clickedButton.disabled = true;
      } else {
        var clickedButton = document.getElementById(
          event.path[0].id
        ) as HTMLButtonElement;

        clickedButton.disabled = true;
      }
    }

    this.service.deviceCommands(command, targetImei).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.CommandAnswe = res;
        if (clickedButton !== undefined) {
          clickedButton.disabled = false;
        }
        if (res.length == 0 || res[0].responseType != 'Ok') {
          this.toastr.error('ბრძანება არ გაიგზავნა');
        } else {
          this.toastr.success('ბრძანება გაიგზავნა');
        }
      },
      (err) => {
        console.log(err);
        if (clickedButton !== undefined) {
          clickedButton.disabled = false;
        }
        if (err) {
          this.toastr.error('ბრძანება არ გაიგზავნა');
        }
      }
    );
  }
  // tslint:disable-next-line:typedef
  onDeviceSelect(val) {
    // tslint:disable-next-line:prefer-const
    let targetImei = this.deviceLog[val];
    console.log(this.deviceObject[targetImei]);

    if (this.deviceObject[targetImei].plannedGraphicOffsetMins.includes('--')) {
      this.wanacvleba = this.deviceObject[targetImei].plannedGraphicOffsetMins
        .split('--')[0]
        .includes('+')
        ? this.deviceObject[targetImei].plannedGraphicOffsetMins
            .split('--')[0]
            .replace('+', '')
        : this.deviceObject[targetImei].plannedGraphicOffsetMins.split('--')[0];

      this.wanacvlebaori =
        '-' +
        this.deviceObject[targetImei].plannedGraphicOffsetMins.split('--')[1];
    } else if (
      this.deviceObject[targetImei].plannedGraphicOffsetMins.includes('-+')
    ) {
      this.wanacvleba = this.deviceObject[targetImei].plannedGraphicOffsetMins
        .split('-+')[0]
        .includes('+')
        ? this.deviceObject[targetImei].plannedGraphicOffsetMins
            .split('-+')[0]
            .replace('+', '')
        : this.deviceObject[targetImei].plannedGraphicOffsetMins.split('-+')[0];
      this.wanacvlebaori =
        this.deviceObject[targetImei].plannedGraphicOffsetMins.split('-+')[1];
    }

    this.wanacvlebaK1 =
      // tslint:disable-next-line:no-string-literal
      this.deviceObject[targetImei]['k1ChartvisDroisWanacvleba'].includes('+')
        ? this.deviceObject[targetImei].k1ChartvisDroisWanacvleba.replace(
            '+',
            ''
          )
        : this.deviceObject[targetImei].k1ChartvisDroisWanacvleba;
    this.wanacvlebaoriK1 =
      // tslint:disable-next-line:no-string-literal
      this.deviceObject[targetImei]['k1GamortvisDroisWanacvleba'].includes('+')
        ? this.deviceObject[targetImei].k1GamortvisDroisWanacvleba.replace(
            '+',
            ''
          )
        : this.deviceObject[targetImei].k1GamortvisDroisWanacvleba; // k1GamortvisDroisWanacvleba
    this.wanacvlebaK2 =
      // tslint:disable-next-line:no-string-literal
      // tslint:disable-next-line:no-string-literal
      this.deviceObject[targetImei]['k2ChartvisDroisWanacvleba'].includes('+')
        ? this.deviceObject[targetImei].k2ChartvisDroisWanacvleba.replace(
            '+',
            ''
          )
        : this.deviceObject[targetImei].k2ChartvisDroisWanacvleba; // k2ChartvisDroisWanacvleba
    this.wanacvlebaoriK2 =
      // tslint:disable-next-line:no-string-literal
      // tslint:disable-next-line:no-string-literal
      this.deviceObject[targetImei]['k2GamortvisDroisWanacvleba'].includes('+')
        ? this.deviceObject[targetImei].k2GamortvisDroisWanacvleba.replace(
            '+',
            ''
          )
        : this.deviceObject[targetImei].k2GamortvisDroisWanacvleba; // k2GamortvisDroisWanacvleba
    this.wanacvlebaK3 =
      // tslint:disable-next-line:no-string-literal
      this.deviceObject[targetImei]['k3ChartvisDroisWanacvleba'].includes('+')
        ? this.deviceObject[targetImei].k3ChartvisDroisWanacvleba.replace(
            '+',
            ''
          )
        : this.deviceObject[targetImei].k3ChartvisDroisWanacvleba; // k3ChartvisDroisWanacvleba
    this.wanacvlebaoriK3 =
      // tslint:disable-next-line:no-string-literal
      this.deviceObject[targetImei]['k3GamortvisDroisWanacvleba'].includes('+')
        ? this.deviceObject[targetImei].k3GamortvisDroisWanacvleba.replace(
            '+',
            ''
          )
        : this.deviceObject[targetImei].k3GamortvisDroisWanacvleba; // k3GamortvisDroisWanacvleba
  }

  tabClick(event) {
    this.toppings.reset();
    this.setNtp = '';
    this.mainIp = '';
    this.mainPort = '';
    this.reservIp = '';
    this.reservPort = '';
    if (event == 2) {
      this.mainIp = '45.9.47.42';
      this.mainPort = '13000';
      this.reservIp = '185.81.92.189';
      this.reservPort = '13000';
    }

    this.timeOn = '';
    this.timeOnK1 = '';
    this.timeOnK2 = '';
    this.timeOnK3 = '';
    this.timeOffK3 = '';
    this.timeOff = '';
    this.timeOffK1 = '';
    this.timeOffK2 = '';
    this.wanacvleba = '';
    this.wanacvlebaK1 = '';
    this.wanacvlebaK2 = '';
    this.wanacvlebaK3 = '';
    this.wanacvlebaori = '';
    this.wanacvlebaoriK1 = '';
    this.wanacvlebaoriK2 = '';
    this.wanacvlebaoriK3 = '';
    this.toppingList = this.anotherArray;
  }

  getDebugLog(target) {
    this.sendingCommandHistory = (localStorage.getItem('commandLog') !== null
      ? localStorage.getItem('commandLog')
      : ''
    ).includes(';')
      ? localStorage.getItem('commandLog').split(';').reverse()
      : '';

    if (target.length > 0) {
      this.debugInterval = setInterval(() => {
        this.sendingCommandHistory = (localStorage.getItem('commandLog') !==
        null
          ? localStorage.getItem('commandLog')
          : ''
        ).includes(';')
          ? localStorage.getItem('commandLog').split(';').reverse()
          : '';
        let targetImei = this.deviceLog[target];
        this.service.debugLog(targetImei).subscribe(
          (res) => {
            if (res.length > 0) {
              this.debugLog = res;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }, 3000);
    } else {
      clearInterval(this.debugInterval);
    }
  }

  filterListCareUnit(val) {
    this.toppingList = this.anotherArray.filter(
      (unit) => unit.indexOf(val) > -1
    );
    console.log(this.toppingList);
  }

  // testingSms2(
  //   command: smsCommandsType,
  //   sendSmsInput: string,
  //   selectedNames: string
  // ) {
  //   for (const selectedName of selectedNames) {
  //     const targetImei = this.deviceLog[selectedName];
  //     const isReleSwitcher = this.checkingReleSwittcher(command.title);

  //     const data = {
  //       Imei: targetImei,
  //       Name: selectedName,
  //       Command: this.buildCommandString(command, sendSmsInput, isReleSwitcher),
  //       SerialNumber: this.deviceObject[targetImei].seriulI_NO,
  //     };
  //     console.log(data);
  //     this.clearSendSms();
  //   }
  // }

  // ვაწყობთ გასგზავნ ობიექტს checkingReleWittcher ის return-ის მიხედვით
  buildCommandString(
    command: smsCommandsType,
    sendSmsInput: string,
    isReleSwitcher: boolean
  ): string {
    if (isReleSwitcher) {
      return `${this.singleRellayPort} ${command.key} `;
    } else {
      return `${command.key}${sendSmsInput && ' ' + sendSmsInput} `;
    }
  }

  // The checkingReleSwittcher function checks if the command starts with 'Turn'.
  // ვამოწმებთ თუ რელეს title იწყება Turn-ით და ვაბრუნებთ ბულიანს
  checkingReleSwittcher(commandTitle: string): boolean {
    return commandTitle.startsWith('Turn');
  }
  //  ინფუთის გაწმენდა გაგზავნის შემდეგ
  clearSendSms() {
    if (this.sendSmsInput) {
      this.sendSmsInput.nativeElement.value = '';
    }
  }
  sendSms2(
    command: smsCommandsType,
    sendSmsInput: string,
    selectedNames: string
  ) {
    for (const selectedName of selectedNames) {
      const targetImei = this.deviceLog[selectedName];
      const isReleSwitcher = this.checkingReleSwittcher(command.title);

      const data = {
        Imei: targetImei,
        Name: selectedName,
        Command: this.buildCommandString(command, sendSmsInput, isReleSwitcher),
        SerialNumber: this.deviceObject[targetImei].seriulI_NO,
      };

      this.clearSendSms();
      this.service.sms(data).subscribe(
        (res) => {
          console.log(res);
          this.toastr.success('ბრძანება გაიგზავნა');
        },
        (err) => {}
      );
    }
  }
}
