import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { ShareDataService } from 'src/app/services/share-data.service';
import { UserService } from 'src/app/services/user.service';
import { graphicDataValidator } from './validator.directive';
export interface PeriodicElement {
  month: string;
  first: string;
  second: string;
  third: string;
  four: string;
  five: string;
  six: string;
}
let ELEMENT_DATA: PeriodicElement[] = [
  {
    month: 'იანვარი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'თებერვალი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'მარტი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'აპრილი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'მაისი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'ივნისი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'ივლისი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'აგვისტო',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'სექტემბერი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'ოქტომბერი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'ნოემბერი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
  {
    month: 'დეკემბერი',
    first: '0',
    second: '0',
    third: '0',
    four: '0',
    five: '0',
    six: '0',
  },
];

let Default_Data: PeriodicElement[] = [
  {
    month: 'იანვარი',
    first: '17:49,08:09',
    second: '17:54,08:09',
    third: '17:58,08:08',
    four: '18:06,08:06',
    five: '18:11,08:03',
    six: '18:17,07:58',
  },
  {
    month: 'თებერვალი',
    first: '18:26,07:52',
    second: '18:36,07:47',
    third: '18:39,07:41',
    four: '18:45,07:35',
    five: '18:51,07:27',
    six: '18:56,07:21',
  },
  {
    month: 'მარტი',
    first: '19:02,07:14',
    second: '19:06,07:07',
    third: '19:13,06:57',
    four: '19:19,06:49',
    five: '19:25,06:39',
    six: '19:30,06:29',
  },
  {
    month: 'აპრილი',
    first: '19:37,06:20',
    second: '19:41,06:14',
    third: '19:48,06:04',
    four: '19:53,05:57',
    five: '19:59,05:49',
    six: '20:02,05:41',
  },
  {
    month: 'მაისი',
    first: '20:10,05:35',
    second: '20:15,05:29',
    third: '20:20,05:23',
    four: '20:25,05:18',
    five: '20:29,05:14',
    six: '20:34,05:11',
  },
  {
    month: 'ივნისი',
    first: '20:39,05:08',
    second: '20:43,05:05',
    third: '20:46,05:05',
    four: '20:48,05:05',
    five: '20:48,05:06',
    six: '20:48,05:07',
  },
  {
    month: 'ივლისი',
    first: '20:48,05:10',
    second: '20:47,05:13',
    third: '20:45,05:16',
    four: '20:41,05:21',
    five: '20:37,05:25',
    six: '20:31,05:31',
  },
  {
    month: 'აგვისტო',
    first: '20:25,05:36',
    second: '20:19,05:41',
    third: '20:12,05:46',
    four: '20:05,05:52',
    five: '19:57,05:57',
    six: '19:49,06:02',
  },
  {
    month: 'სექტემბერი',
    first: '19:45,06:08',
    second: '19:31,06:13',
    third: '19:22,06:19',
    four: '19:14,06:24',
    five: '19:05,06:29',
    six: '18:56,06:34',
  },
  {
    month: 'ოქტომბერი',
    first: '18:47,06:40',
    second: '18:39,06:46',
    third: '18:31,06:52',
    four: '18:23,06:57',
    five: '18:16,07:02',
    six: '18:07,07:10',
  },
  {
    month: 'ნოემბერი',
    first: '18:01,07:16',
    second: '17:55,07:22',
    third: '17:50,07:29',
    four: '17:46,07:35',
    five: '17:42,07:40',
    six: '17:40,07:46',
  },
  {
    month: 'დეკემბერი',
    first: '17:38,07:50',
    second: '17:38,07:56',
    third: '17:38,08:00',
    four: '17:39,08:03',
    five: '17:41,08:06',
    six: '17:44,08:09',
  },
];

@Component({
  selector: 'app-device-ghrapic',
  templateUrl: './device-ghrapic.component.html',
  styleUrls: ['./device-ghrapic.component.scss'],
})
export class DeviceGhrapicComponent implements OnInit {
  displayedColumns: string[] = [
    'month',
    '1-5',
    '6-10',
    '11-15',
    '16-20',
    '21-25',
    '26-',
    'action',
  ];
  dataSource = new MatTableDataSource<any>();
  toppings = new FormControl();
  topping: string;
  toppingList: any[] = [];
  selectedDevice = [];
  pingMessage: any;
  deviceLog: {};
  userDetails: any;
  userId: any;
  CommandAnswe: any;
  interval: number;
  // tslint:disable-next-line:ban-types
  isSent: string;
  start: boolean;
  isLoading = true;

  pageNumber: number = 1;
  VOForm: FormGroup;
  isEditableNew: boolean = true;
  selectedDevices = [];
  newGraphic = [];
  oldData: any;
  hideBtn = false;
  selectedDeviceId: string;
  targetData: any;
  showCommand = true;
  anotherArray = [];
  constructor(
    private fb: FormBuilder,
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    private service: UserService,
    private toastr: ToastrService,
    private shareDataService: ShareDataService
  ) {
    this.targetData = this.shareDataService.currentApprovalStageMessage5
      .pipe(take(2))
      .subscribe((msg) => {
        if (msg.length > 2 && this.showCommand) {
          this.showCommand = false;
          this.selectedDeviceId = msg;
          this.dataSource = null;
          this.checked('001', this.selectedDeviceId.replace(';', ''));
          // this.DeviceSheduler(msg.replace(';', ''));
          // msg = "";
          // this.selectedDeviceId = "";
        } else {
          this.showCommand = true;
        }
      });
  }

  ngOnInit(): void {
    this.showCommand = true;
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getPingMessage(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );
    this.VOForm = this._formBuilder.group({
      VORows: this._formBuilder.array([]),
    });

    this.VOForm = this.fb.group({
      VORows: this.fb.array(
        ELEMENT_DATA.map((val) =>
          this.fb.group({
            month: new FormControl(val.month),
            first: new FormControl(val.first, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            second: new FormControl(val.second, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            third: new FormControl(val.third, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            four: new FormControl(val.four, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            five: new FormControl(val.five, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            six: new FormControl(val.six, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            action: new FormControl('existingRecord'),
            isEditable: new FormControl(true),
            isNewRow: new FormControl(false),
          })
        )
      ), //end of fb array
    }); // end of form group cretation
    this.isLoading = false;
    this.dataSource = new MatTableDataSource(
      (this.VOForm.get('VORows') as FormArray).controls
    );
    this.dataSource.paginator = this.paginator;

    const filterPredicate = this.dataSource.filterPredicate;
    this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
      return filterPredicate.call(this.dataSource, data.value, filter);
    };

    //Custom filter according to name column
    // this.dataSource.filterPredicate = (data: {name: string}, filterValue: string) =>
    //   data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginatorList = document.getElementsByClassName(
      'mat-paginator-range-label'
    );

    // this.onPaginateChange(this.paginator, this.paginatorList);

    // this.paginator.page.subscribe(() => {
    //   // this is page change event
    //   this.onPaginateChange(this.paginator, this.paginatorList);
    // });
  }

  applyFilter(event: Event) {
    //  debugger;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // this function will enabled the select field for editd
  EditSVO(VOFormElement, i) {
    // VOFormElement.get('VORows').at(i).get('name').disabled(false)
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);

    this.oldData = VOFormElement.get('VORows').value;
    // this.isEditableNew = true;
    this.hideBtn = true;
  }

  // On click of correct button in table (after click on edit) this method will call
  SaveVO(VOFormElement, i) {
    // alert('SaveVO')
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
    this.newGraphic = VOFormElement.get('VORows').value;
  }

  // On click of cancel button in the table (after click on edit) this method will call and reset the previous data
  CancelSVO(VOFormElement, i) {
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);
    document.execCommand('undo', false, null);
  }

  paginatorList: HTMLCollectionOf<Element>;
  idx: number;
  // onPaginateChange(paginator: MatPaginator, list: HTMLCollectionOf<Element>) {
  //   setTimeout(
  //     (idx) => {
  //       let from = paginator.pageSize * paginator.pageIndex + 1;

  //       let to =
  //         paginator.length < paginator.pageSize * (paginator.pageIndex + 1)
  //           ? paginator.length
  //           : paginator.pageSize * (paginator.pageIndex + 1);

  //       let toFrom = paginator.length == 0 ? 0 : `${from} - ${to}`;
  //       let pageNumber =
  //         paginator.length == 0
  //           ? `0 of 0`
  //           : `${paginator.pageIndex + 1} of ${paginator.getNumberOfPages()}`;
  //       let rows = `Page ${pageNumber} (${toFrom} of ${paginator.length})`;

  //       if (list.length >= 1) list[0].innerHTML = rows;
  //     },
  //     0,
  //     paginator.pageIndex
  //   );
  // }

  initiateVOForm(): FormGroup {
    return this.fb.group({
      position: new FormControl(234),
      name: new FormControl(''),
      weight: new FormControl(''),
      symbol: new FormControl(''),
      action: new FormControl('newRecord'),
      isEditable: new FormControl(false),
      isNewRow: new FormControl(true),
    });
  }

  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x + ';' + 'fromAdmin').subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          if (
            !(
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress in
              this.deviceLog
            )
          ) {
            this.deviceLog[
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress
            ] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress +
                this.pingMessage[i].devieId
            ] = this.pingMessage[i].imei;
          }
        }
        this.toppingList = Object.keys(this.deviceLog);
        this.anotherArray = Object.keys(this.deviceLog);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  sendProgramCmd() {
    // const headers = { 'content-type': 'multipart/form-data'};
    var selectedDevicesLen = this.selectedDevices.length;

    if (selectedDevicesLen > 0) {
      let body = 'cmd_setgrafic>';
    
      for (let i = 0; i < this.newGraphic.length; i++) {
       body +=
          this.newGraphic[i]['first'] +
          ';' +
          this.newGraphic[i]['second'] +
          ';' +
          this.newGraphic[i]['third'] +
          ';' +
          this.newGraphic[i]['four'] +
          ';' +
          this.newGraphic[i]['five'] +
          ';' +
          this.newGraphic[i]['six'] +
          ';';
      }
      body += '<';

      for (let i = 0; i < selectedDevicesLen; i++) {
        const target = this.deviceLog[this.selectedDevices[i]];
        var unix = Math.round(+new Date() / 1000).toString(16);

        body = body.replace('_', '_' + unix.toLocaleUpperCase() + '_');
        this.service.deviceCommands(body, target).subscribe(
          (res) => {
            // tslint:disable-next-line:no-string-literal
            this.CommandAnswe = res;
            if (res.length == 0 || res[0].response == 'Ok') {
              this.toastr.error('ბრძანება არ გაიგზავნა');
            } else {
              this.toastr.success('ბრძანება გაიგზავნა');
            }
          },
          (err) => {
            console.log(err);
            if (err) {
              this.toastr.error('ბრძანება არ გაიგზავნა');
            }
          }
        );
      }
    }
  }

  checked(x, source?) {
    var target = '';
    if (source) {
      target = source;
    } else {
      this.selectedDevices = x;
      target = this.deviceLog[this.selectedDevices[0]];
    }
    if (typeof target == 'string') {
      var unix = Math.round(+new Date() / 1000).toString(16);
      var command = 'req_grafic';
      command = command.replace('_', '_' + unix.toLocaleUpperCase() + '_');
      this.service.deviceCommands(command, target).subscribe(
        (res) => {
          // tslint:disable-next-line:no-string-literal
          this.CommandAnswe = res;
          console.log(target);
          if (res.length == 0 || res[0].responseType != 'Ok') {
            this.toastr.error('ბრძანება არ გაიგზავნა');
          } else {
            this.DeviceSheduler(target);
          }
        },
        (err) => {
          console.log(err);
          if (err) {
            this.toastr.error('ბრძანება არ გაიგზავნა');
          }
        }
      );
    } else {
      this.dataSource = null;
    }
  }
  remove(device: string): void {
    this.selectedDevice = this.selectedDevice.filter((val) => val !== device);
    this.selectedDevices = this.selectedDevices.filter((val) => val !== device);
  }
  plitToChunks(array, parts) {
    let result = [];
    for (let i = parts; i > 0; i--) {
      result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  }

  DeviceSheduler(target) {
    this.service.getDeviceSheduler(target).subscribe(
      (res) => {
        try {
          var rawDataArray = res[0]['rawData'].split(';');
          rawDataArray.splice(rawDataArray.indexOf(''), 1);
          var newArray = this.plitToChunks(rawDataArray, 12);
          for (let i = 0; i < ELEMENT_DATA.length; i++) {
            ELEMENT_DATA[i].first = newArray[i][0];
            ELEMENT_DATA[i].second = newArray[i][1];
            ELEMENT_DATA[i].third = newArray[i][2];
            ELEMENT_DATA[i].four = newArray[i][3];
            ELEMENT_DATA[i].five = newArray[i][4];
            ELEMENT_DATA[i].six = newArray[i][5];
          }
          this.VOForm = this._formBuilder.group({
            VORows: this._formBuilder.array([]),
          });

          this.VOForm = this.fb.group({
            VORows: this.fb.array(
              ELEMENT_DATA.map((val) =>
                this.fb.group({
                  month: new FormControl(val.month),
                  first: new FormControl(val.first, [
                    Validators.pattern('^[:;,0-9]{1,11}$'),
                    graphicDataValidator(),
                  ]),
                  second: new FormControl(val.second, [
                    Validators.pattern('^[:;,0-9]{1,11}$'),
                    graphicDataValidator(),
                  ]),
                  third: new FormControl(val.third, [
                    Validators.pattern('^[:;,0-9]{1,11}$'),
                    graphicDataValidator(),
                  ]),
                  four: new FormControl(val.four, [
                    Validators.pattern('^[:;,0-9]{1,11}$'),
                    graphicDataValidator(),
                  ]),
                  five: new FormControl(val.five, [
                    Validators.pattern('^[:;,0-9]{1,11}$'),
                    graphicDataValidator(),
                  ]),
                  six: new FormControl(val.six, [
                    Validators.pattern('^[:;,0-9]{1,11}$'),
                    graphicDataValidator(),
                  ]),
                  action: new FormControl('existingRecord'),
                  isEditable: new FormControl(true),
                  isNewRow: new FormControl(false),
                })
              )
            ), //end of fb array
          });
          this.dataSource = new MatTableDataSource(
            (this.VOForm.get('VORows') as FormArray).controls
          );
        } catch (error) {
          this.dataSource = null;
        }
      },
      (err) => {
        console.log(err + '#####');
      }
    );
  }

  default() {
    this.hideBtn = true;

    this.VOForm = this._formBuilder.group({
      VORows: this._formBuilder.array([]),
    });

    this.VOForm = this.fb.group({
      VORows: this.fb.array(
        Default_Data.map((val) =>
          this.fb.group({
            month: new FormControl(val.month),
            first: new FormControl(val.first, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            second: new FormControl(val.second, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            third: new FormControl(val.third, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            four: new FormControl(val.four, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            five: new FormControl(val.five, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            six: new FormControl(val.six, [
              Validators.pattern('^[:;,0-9]{1,11}$'),
              graphicDataValidator(),
            ]),
            action: new FormControl('existingRecord'),
            isEditable: new FormControl(true),
            isNewRow: new FormControl(false),
          })
        )
      ), //end of fb array
    });
    this.dataSource = new MatTableDataSource(
      (this.VOForm.get('VORows') as FormArray).controls
    );

    this.newGraphic = Default_Data;
  }

  filterListCareUnit(val) {
    this.toppingList = this.anotherArray.filter((unit) => unit.indexOf(val) > -1);

  }
}
