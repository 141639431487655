import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatCheckbox } from '@angular/material/checkbox';
import { ShareDataService } from 'src/app/services/share-data.service';
import { UserService } from 'src/app/services/user.service';
import { AllDeviceInfoComponent } from '../all-device-info/all-device-info.component';
import { DeviceOrderComponent } from '../device-order/device-order.component';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
})
export class DeviceListComponent implements OnInit, OnDestroy {
  isAudioOpen: boolean = true;
  hideMap: boolean;
  toppings: FormGroup;
  isChecked = false;
  showEditDevice: boolean;
  disableCommands = false;

  isLoading = false;
  // tslint:disable-next-line:ban-types
  pingMessage: Object;
  // tslint:disable-next-line:ban-types
  userDetails: Object;
  Id: any;
  userId: any;
  deviceLog: {};
  selectedDeviceId: any;
  commandTarget = '';
  // interval: number;
  interval: any;
  requestSendingTrigger = true;
  userFilter: any = { name: '' };
  editInfoData: any;
  DeviceChecked: any;
  allCheckBox: string;
  selectedDevice: any;
  EventHistory: {};
  EventHistoryId: number;
  deviceErrors = [];
  eventDbDict = {};
  allowComandPanel: any;
  allowTurnOnOff: any;
  allowGraphic: any;
  deviceGroup = [];
  jsonData: any;
  startIndex: number;
  endIndex: any;
  selectedPage: any;
  selectedBox: number;
  audioSrc = '';
  newErrors: string[];
  deviceGroupDict = {};
  deviceGroupDict1 = {};
  selectedPageDict = {};
  selectedBoxDict = {};
  selectedBoxDict1 = {};
  updateState = true;
  serverTime: any;
  volume = 'volume_up';
  disabledArr = [];
  soundDisabled = {};
  selectionStatus = 'single';
  selectMultiple = false;
  color = 'accent';
  panelOpenState = true;
  isCleanLoading = false;
  requestTrigger = true;
  @ViewChild('myCheckbox') myCheckbox: MatCheckbox;
  deviceList: any[];

  notSended: any[];
  showNotSendedCommands = false;
  intervalRef: any;

  constructor(
    fb: FormBuilder,
    private service: UserService,
    public shareDataService: ShareDataService,
    private _bottomSheet: MatBottomSheet
  ) {
    this.toppings = fb.group({
      pepperoni: false,
      extracheese: false,
      mushroom: false,
    });
    shareDataService.currentApprovalStageMessage7.subscribe((msg) => {
      this.deviceErrors = Array.from(msg);
      // this.newErrors =  Array.from(msg);

      // for (let i = 0 ; i < Number.parseInt(this.newErrors[this.newErrors.length - 1]); i++){
      // var box =  document.getElementById(this.newErrors[i].split(' ')[0]) as HTMLElement;
      // box.classList.add('test-class');
      // box.classList.add('bounce');
      // }
    });
  }
  AudioCheck(type: string) {
    let audio = document.getElementById('audio') as HTMLAudioElement;
    if (type === 'start') {
      audio.play();
    } else {
      audio.pause();
    }

    this.isAudioOpen = !this.isAudioOpen;
    localStorage.setItem('isAudioOn', JSON.stringify(this.isAudioOpen));
  }

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.checkUserLimits(this.userId);
        if (this.requestSendingTrigger) {
          // console.log("NG ON true")
          this.getPingMessage(this.userId);
        }
        this.jsonData = JSON.parse(localStorage.getItem('DeviceOrder'));
        if (this.jsonData == null || this.jsonData[this.userId] == null) {
          this.getDeviceList(this.userId);
        } else {
          var deleteDublicates = [...new Set(this.jsonData[this.userId][0])];
          // console.log(deleteDublicates);
          this.jsonData[this.userId][0] = deleteDublicates;
          localStorage.setItem('DeviceOrder', JSON.stringify(this.jsonData));
        }
        this.deviceGroup = this.simplifyArray(this.jsonData[this.userId]);

        this.selectedBoxDict = JSON.parse(localStorage.getItem('selectedPage'));

        if (this.selectedBoxDict == undefined || this.selectedBoxDict == null) {
          this.selectedPageDict[this.userId] = 0;
          localStorage.setItem(
            'selectedPage',
            JSON.stringify(this.selectedPageDict)
          );
          this.selectedBox = 0;
        } else {
          if (
            this.selectedBoxDict[this.userId] == null ||
            this.selectedBoxDict[this.userId] == undefined
          ) {
            // console.log('################################');

            this.selectedBoxDict[this.userId] = 0;

            localStorage.setItem(
              'selectedPage',
              JSON.stringify(this.selectedBoxDict)
            );
            this.selectedBox = 0;
          } else {
            // this.selectedBox = parseInt(this.selectedBoxDict[this.userId]);
            // this.selectedPageDict[this.userId] = this.selectedBox;
            // localStorage.setItem('selectedPage', JSON.stringify(this.selectedPageDict));
          }
        }

        this.pageChange(this.selectedBox);
      },
      (err) => {
        console.log(err);
      }
    );
    // @ts-ignore

    this.interval = setInterval(() => {
      if (this.requestSendingTrigger) {
        // console.log("Interval true ")

        this.getPingMessage(this.userId);

        this.jsonData = JSON.parse(localStorage.getItem('DeviceOrder'))[
          this.userId
        ];

        this.deviceGroup = this.simplifyArray(this.jsonData);
        this.selectedBoxDict1 = JSON.parse(
          localStorage.getItem('selectedPage')
        );
        this.selectedBox = parseInt(this.selectedBoxDict1[this.userId]);
        this.pageChange(this.selectedBox);
        this.checDBForSound();
        this.notSendedCommands();
      }
      // this.getDeviceEvents(this.userId);
    }, 5000);
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  clearInterval(): void {
    clearInterval(this.intervalRef);
  }

  // tslint:disable-next-line:typedef
  checked(x, event, index: number) {
    // tslint:disable-next-line:no-unused-expression
    this.shareDataService.deviceIndex[index] =
      !this.shareDataService.deviceIndex[index];
    if (this.selectMultiple) {
      this.shareDataService.deviceList.push(x);
      // console.log(this.shareDataService.deviceList);

      if (event.checked) {
        this.selectedDeviceId = x;
      }

      if (event.checked && !this.commandTarget.includes(x)) {
        this.commandTarget += x + ';';
      }

      const saveLocal = localStorage.setItem(x, event.checked.toString());

      if (!event.checked) {
        this.commandTarget = this.commandTarget.replace(x + ';', '');
      }
    } else {
      // if (event.checked) {
      //   this.selectedDeviceId = x;
      // }

      // if (event.checked && !this.commandTarget.includes(x)) {
      //   this.commandTarget += x + ';';
      // }

      // const saveLocal = localStorage.setItem(x, event.checked.toString());

      // if (!event.checked) {
      //   this.commandTarget = this.commandTarget.replace(x + ';', '');
      // }
      this.removeFromLocalStorage('true');
      this.removeFromLocalStorage('false');
      localStorage.setItem(x, event.checked);
      this.commandTarget = x + ';';

      this.selectedDeviceId = x;
    }
  }

  logOnlyAfter7(res) {
    var currentDate = new Date();
    var currentHour = currentDate.getHours();

    // Check if the current hour is between 7 PM (19:00) and 8 AM (8:00)
    if (currentHour >= 19 || currentHour < 8) {
      var logEntry = currentDate.toLocaleString();

      // You can then log the message using your preferred method, for example:
      // console.log(logEntry);
      // console.log(res);

      // If you want to log it to a file (server-side JavaScript), you would use a file system module
      // Example with Node.js:
      // const fs = require('fs');
      // fs.appendFileSync('log_file.txt', logEntry);
    }
  }

  cleanCash() {
    localStorage.removeItem('DeviceOrder');
    localStorage.removeItem('eventHistoryLength');
    localStorage.removeItem('selectedPage');
    localStorage.removeItem('soundDisabled');
    localStorage.removeItem('commandLog');
    localStorage.clear();

    sessionStorage.clear();

    if ('caches' in window) {
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name);
        }
      });
    }
    document.cookie.split(';').forEach(function (c) {
      document.cookie =
        c.trim().split('=')[0] +
        '=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    });
    window.location.reload();
  }
  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // console.log(res);
        // console.log('PING MESSIJS AGZAVNIS ');
        // console.log('this.pingMessage >>>>>>>>>>>>>>>>>>>>');
        // console.log(this.pingMessage);
        // console.log('this.pingMessage >>>>>>>>>>>>>>>>>>>>');
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        this.DeviceChecked = {};
        this.allCheckBox = '';

        // console.log(res);
        this.logOnlyAfter7(res);
        // this.cleanCash();
        this.serverTime = Date.parse(
          this.pingMessage[Object.keys(this.pingMessage).length - 1].date
        );
        // console.log('SERVER TIME ');
        // console.log(this.serverTime);
        // console.log(
        //   this.pingMessage[Object.keys(this.pingMessage).length - 1].date
        // );
        // console.log('SERVER TIME ');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length - 1; i++) {
          // tslint:disable-next-line:no-string-literal
          // console.log(this.pingMessage[i].color);
          this.pingMessage[i]['userId'] = JSON.parse(
            localStorage.getItem(this.pingMessage[i].imei)
          );
          // console.log(this.pingMessage);
          // tslint:disable-next-line:no-string-literal
          this.pingMessage[i]['adress'] = localStorage.getItem(
            this.pingMessage[i].deviceId
          );

          if (this.jsonData[this.userId] != undefined) {
            this.deviceGroup = this.simplifyArray(this.jsonData[this.userId]);
          } else {
            this.deviceGroup = this.simplifyArray(this.jsonData);
          }

          if (
            this.deviceGroup.indexOf(this.pingMessage[i].deviceId.toString()) ==
            -1
          ) {
            let dataArr = JSON.parse(localStorage.getItem('DeviceOrder'));

            dataArr[this.userId][0].push(
              this.pingMessage[i]['name'] +
                '__' +
                this.pingMessage[i]['deviceId']
            );

            localStorage.setItem('DeviceOrder', JSON.stringify(dataArr));
          }
          this.pingMessage[i]['position'] = this.deviceGroup.indexOf(
            this.pingMessage[i].deviceId.toString()
          );
          // console.log(this.pingMessage);

          this.deviceLog[this.pingMessage[i].imei] = this.pingMessage[i];

          // console.log(this.deviceLog);
          this.allCheckBox += localStorage.getItem(this.pingMessage[i].imei);
        }

        if (this.allCheckBox.includes('true')) {
          this.disableCommands = false;
          this.isChecked = true;
        } else {
          this.disableCommands = true;
          this.isChecked = false;
        }
        if (this.allCheckBox.includes('true')) {
          this.disableCommands = false;
          this.isChecked = true;
        } else {
          this.disableCommands = true;
          this.isChecked = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  insertEventToDb(id, iemi, event, eventType) {
    this.service.inserEvents(id, iemi, event, this.userId, eventType).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  getDateDiff(datetime, interval, deviceId, connectiionStatus, imei, name) {
    // console.log('connectiionStatus');
    // console.log(connectiionStatus);
    // console.log('connectiionStatus');
    let substr = 0;
    if (interval <= 5) {
      substr = 10;
    } else {
      substr = 2;
    }
    let result = false;
    // const currentDate = new Date();
    const deviceDate = Date.parse('20' + datetime);
    const diffTime = Math.abs(this.serverTime.valueOf() - deviceDate);
    // console.log('Diff time');
    // console.log(diffTime);
    // console.log('Diff time');

    const diffMinute = diffTime / (1000 * 60);

    const dayDiff = diffTime / (1000 * 60 * 60 * 24);
    // tslint:disable-next-line:radix
    if (diffMinute > (interval / 60) * substr) {
      result = true;
      // this.updateDeviceStatus(deviceId,2);
    }

    if (result == true && connectiionStatus == 0 && this.updateState == true) {
      this.updateState = false;
      this.updateDeviceStatus(deviceId, 2, imei, name);
    }
    // console.log( 'მოწყობილობის დრო: ' + datetime + '----------------' + 'ახლანდელი დრო' + deviceDate);
    return result;
  }

  updateDeviceStatus(deviceId, status, imei, name) {
    this.service.deviceConnectionStatus(deviceId, status, imei, name).subscribe(
      (res) => {
        this.updateState = true;
      },
      (err) => {}
    );
  }

  // tslint:disable-next-line:typedef
  editInfo(x) {
    this.editInfoData = x;
  }
  // tslint:disable-next-line:typedef
  addNewItem(newItem) {
    if (newItem.split(';^')[0] === 'name') {
      this.userFilter.name = newItem.split(';^')[1];
    }
    if (newItem.split(';^')[0] === 'stationId') {
      this.userFilter.stationId = newItem.split(';^')[1];
    }
    if (newItem.split(';^')[0] === 'imei') {
      this.userFilter.imei = newItem.split(';^')[1];
    }
    if (newItem.split(';^')[0] === 'mobileNumber') {
      this.userFilter.mobileNumber = newItem.split(';^')[1];
    }

    if (newItem.split(';^')[0] === 'serial') {
      this.userFilter.seriulI_NO = newItem.split(';^')[1];
    }
  }

  // Preserve original property order
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  // Order by ascending property value
  valueAscOrder = (
    a: KeyValue<number, object>,
    b: KeyValue<number, object>
  ): number => {
    return a.value['name'].localeCompare(b.value['name']);
  };

  // Order by descending property key
  keyDescOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  indexOrderAsc = (
    akv: KeyValue<string, any>,
    bkv: KeyValue<string, any>
  ): number => {
    const a = akv.value.position;
    const b = bkv.value.position;

    return a > b ? 1 : b > a ? -1 : 0;
  };
  // tslint:disable-next-line:typedef
  clickedMap(value) {
    this.selectedDevice = value.target.id;
    this.shareDataService.mapFocus(this.selectedDevice);
  }
  // this.pingMessage[i].name + '__ ' +
  // this.pingMessage[i].stationId + ' ' + this.pingMessage[i].adress

  // tslint:disable-next-line:typedef
  deleteErrorMessage(val) {
    // console.log('CLEAN');
    this.isCleanLoading = true;
    let id = '';
    if (val !== 'all') {
      this.deviceErrors.splice(val, 1);
      id = this.eventDbDict[val];
    } else {
      id = val;
    }
    localStorage.removeItem('soundDisabled');
    this.service.DeleteEventInAccount(this.userId).subscribe(
      (res) => {
        this.service.deviceEvents(this.userId).subscribe(
          (data) => {
            this.EventHistory = data;
            this.deviceErrors = [];
            this.isCleanLoading = false;
          },
          (err) => {
            console.log(err);
            this.isCleanLoading = false;
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  checkUserLimits(userId) {
    this.service.selectUserLimits(userId).subscribe(
      (res) => {
        this.allowComandPanel = res[0].split(';')[4];
        this.allowTurnOnOff = res[0].split(';')[2];
        this.allowGraphic = res[0].split(';')[3];
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  openMenu(val) {
    if (localStorage.getItem(val) === 'true') {
      localStorage.setItem(val, 'false');
    } else {
      localStorage.setItem(val, 'true');
    }
  }

  simplifyArray = (arr) => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (Array.isArray(element)) {
        for (let j = 0; j < element.length; j++) {
          res.push(element[j].split('__')[1]);
        }
      } else {
        res.push(element.split('__')[1]);
      }
    }
    return res;
  };

  pageChange(index) {
    if (this.jsonData[index] != undefined) {
      this.startIndex = this.deviceGroup.indexOf(
        this.jsonData[index][0].split('__')[1]
      );
      this.endIndex =
        this.deviceGroup.indexOf(
          this.jsonData[index][this.jsonData[index].length - 1].split('__')[1]
        ) + 1;

      this.selectedBoxDict1 = JSON.parse(localStorage.getItem('selectedPage'));
      this.selectedBoxDict1[this.userId] = index;
      localStorage.setItem(
        'selectedPage',
        JSON.stringify(this.selectedBoxDict1)
      );
    }
  }
  openBottomSheet(): void {
    this._bottomSheet.open(DeviceOrderComponent);
    this.shareDataService.selectedEditDeviceOrder(this.selectedPage);
  }

  // tslint:disable-next-line:typedef
  getDeviceList(id: string) {
    const userId = id;
    this.service.PingMessage(userId).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        let pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        let deviceLog = {};
        let NameDevId = {};

        // tslint:disable-next-line:prefer-for-of
        this.shareDataService.deviceIndex = new Array(
          Object.keys(this.pingMessage).length
        ).fill(false);

        for (let i = 0; i < Object.keys(pingMessage).length; i++) {
          NameDevId[pingMessage[i].name] = pingMessage[i].deviceId;

          if (
            !(pingMessage[i].name + '__' + pingMessage[i].deviceId in deviceLog)
          ) {
            deviceLog[pingMessage[i].name + '__' + pingMessage[i].deviceId] =
              pingMessage[i].imei;
          } else {
            deviceLog[pingMessage[i].name + '__' + pingMessage[i].deviceId] =
              pingMessage[i].imei;
          }
        }
        let devices = Object.keys(deviceLog);
        devices = devices.sort((a, b) => a.localeCompare(b));
        var deviceDict = JSON.parse(localStorage.getItem('DeviceOrder'));

        this.deviceGroup = this.sliceIntoChunks(devices, 300);

        if (deviceDict !== null) {
          deviceDict[this.userId] = this.deviceGroup;
          localStorage.setItem('DeviceOrder', JSON.stringify(deviceDict));
        } else {
          this.deviceGroupDict1[this.userId] = this.deviceGroup;
          localStorage.setItem(
            'DeviceOrder',
            JSON.stringify(this.deviceGroupDict1)
          );
        }

        this.jsonData = JSON.stringify(this.deviceGroup);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  devicesFullInfo() {
    this._bottomSheet.open(AllDeviceInfoComponent);
    this.shareDataService.pingMessage(this.pingMessage);
  }

  checDBForSound() {
    var disabledSounds = JSON.parse(localStorage.getItem('soundDisabled'));

    if (disabledSounds != null) {
      if (disabledSounds[this.userId] != null) {
        disabledSounds[this.userId].forEach((x) => {
          document.getElementById(x).innerText = 'volume_off';
          document.getElementById(x).style.color = 'red';
        });
      }
    }
  }

  disableVolume(val) {
    // console.log(val) შემომავალ ფაზაზე ძაბვის ნორმა დაირღვა 2024-01-15 17:20:17.087
    var checkstrarr = val.split(' ');
    checkstrarr.pop();
    checkstrarr.pop();
    var newstr = checkstrarr.join(' ');

    if (document.getElementById(newstr).innerText == 'volume_up') {
      document.getElementById(newstr).innerText = 'volume_off';
      document.getElementById(newstr).style.color = 'red';

      var storageData = JSON.parse(localStorage.getItem('soundDisabled'));

      if (storageData != null) {
        if (storageData[this.userId] != null) {
          this.disabledArr = storageData[this.userId];
        }
      }

      if (!this.disabledArr.includes(newstr)) {
        this.disabledArr.push(newstr);
      }

      this.soundDisabled[this.userId] = this.disabledArr;

      localStorage.setItem('soundDisabled', JSON.stringify(this.soundDisabled));
    } else {
      document.getElementById(newstr).innerText = 'volume_up';
      document.getElementById(newstr).style.color = 'green';

      var disabledSounds = JSON.parse(localStorage.getItem('soundDisabled'));
      var arr = disabledSounds[this.userId];

      var index = arr.indexOf(newstr);
      if (index > -1) {
        arr.splice(index, 1);
      }
      disabledSounds[this.userId] = arr;
      localStorage.setItem('soundDisabled', JSON.stringify(disabledSounds));
    }
  }

  getColor(x) {
    var result = '';

    var tarigi =
      this.getDateDiff(
        x.value.deviceDateTime,
        x.value.interval,
        x.value.deviceId,
        x.value.connectionStatus,
        x.value.imei,
        x.value.name
      ) == true;
    var witeli = x.value.ua == '0' && x.value.ub == '0' && x.value.uc == '0';
    var tetri = x.value.managementMode == '0';
    var yafisferi =
      ((x.value.srOnOf == '0' ||
        x.value.srOnOf == '2' ||
        x.value.srOnOf == '4' ||
        x.value.k1 == '0' ||
        x.value.k1 == '2' ||
        x.value.k1 == '4' ||
        x.value.k2 == '0' ||
        x.value.k2 == '2' ||
        x.value.k2 == '4') &&
        (x.value.ukudzabvaA.length > 36 ||
          x.value.ukudzabvaB.length > 36 ||
          x.value.ukudzabvaC.length > 36)) ||
      (x.value.srOnOf == '0' && (x.value.k1 != '0' || x.value.k2 != '0'));
    var mwvane =
      x.value.srOnOf == '1' || x.value.srOnOf == '3' || x.value.srOnOf == '5';

    var yviteli =
      (Number.parseFloat(x.value.a) < 0.3 &&
        !x.value.a.toString().includes('N')) ||
      (Number.parseFloat(x.value.b) < 0.3 &&
        !x.value.b.toString().includes('N')) ||
      (Number.parseFloat(x.value.c) < 0.3 &&
        !x.value.c.toString().includes('N')) ||
      (Number.parseFloat(x.value.ua) < 0.3 &&
        !x.value.ua.toString().includes('N')) ||
      (Number.parseFloat(x.value.ub) < 0.3 &&
        !x.value.ub.toString().includes('N')) ||
      (Number.parseFloat(x.value.uc) < 0.3 &&
        !x.value.uc.toString().includes('N')) ||
      (((Number.parseFloat(x.value.ia) < 0.3 &&
        !x.value.ia.toString().includes('N')) ||
        (Number.parseFloat(x.value.ib) < 0.3 &&
          !x.value.ib.toString().includes('N')) ||
        (Number.parseFloat(x.value.ic) < 0.3 &&
          !x.value.ic.toString().includes('N')) ||
        (Number.parseFloat(x.value.i1) < 0.3 &&
          !x.value.i1.toString().includes('N')) ||
        (Number.parseFloat(x.value.i2) < 0.3 &&
          !x.value.i2.toString().includes('N')) ||
        (Number.parseFloat(x.value.i3) < 0.3 &&
          !x.value.i3.toString().includes('N')) ||
        (Number.parseFloat(x.value.i4) < 0.3 &&
          !x.value.i4.toString().includes('N')) ||
        (Number.parseFloat(x.value.i5) < 0.3 &&
          !x.value.i5.toString().includes('N')) ||
        (Number.parseFloat(x.value.i6) < 0.3 &&
          !x.value.i6.toString().includes('N')) ||
        (Number.parseFloat(x.value.i7) < 0.3 &&
          !x.value.i7.toString().includes('N')) ||
        (Number.parseFloat(x.value.i8) < 0.3 &&
          !x.value.i8.toString().includes('N')) ||
        (Number.parseFloat(x.value.i9) < 0.3 &&
          !x.value.i9.toString().includes('N')) ||
        (Number.parseFloat(x.value.i10) < 0.3 &&
          !x.value.i10.toString().includes('N')) ||
        (Number.parseFloat(x.value.i11) < 0.3 &&
          !x.value.i11.toString().includes('N')) ||
        (Number.parseFloat(x.value.i12) < 0.3 &&
          !x.value.i12.toString().includes('N'))) &&
        (x.value.srOnOf == '1' ||
          x.value.srOnOf == '3' ||
          x.value.srOnOf == '5' ||
          x.value.k1 == '1' ||
          x.value.k1 == '3' ||
          x.value.k1 == '5' ||
          x.value.k2 == '1' ||
          x.value.k2 == '3' ||
          x.value.k2 == '5')) ||
      (((Number.parseFloat(x.value.a) > 0.3 &&
        !x.value.a.toString().includes('N')) ||
        (Number.parseFloat(x.value.b) > 0.3 &&
          !x.value.b.toString().includes('N')) ||
        (Number.parseFloat(x.value.c) > 0.3 &&
          !x.value.c.toString().includes('N')) ||
        (Number.parseFloat(x.value.ua) < 0.3 &&
          !x.value.ua.toString().includes('N')) ||
        (Number.parseFloat(x.value.ub) < 0.3 &&
          !x.value.ub.toString().includes('N')) ||
        (Number.parseFloat(x.value.uc) < 0.3 &&
          !x.value.uc.toString().includes('N')) ||
        (Number.parseFloat(x.value.ia) > 0.3 &&
          !x.value.ia.toString().includes('N')) ||
        (Number.parseFloat(x.value.ib) > 0.3 &&
          !x.value.ib.toString().includes('N')) ||
        (Number.parseFloat(x.value.ic) > 0.3 &&
          !x.value.ic.toString().includes('N')) ||
        (Number.parseFloat(x.value.i1) > 0.3 &&
          !x.value.i1.toString().includes('N')) ||
        (Number.parseFloat(x.value.i2) > 0.3 &&
          !x.value.i2.toString().includes('N')) ||
        (Number.parseFloat(x.value.i3) > 0.3 &&
          !x.value.i3.toString().includes('N')) ||
        (Number.parseFloat(x.value.i4) > 0.3 &&
          !x.value.i4.toString().includes('N')) ||
        (Number.parseFloat(x.value.i5) > 0.3 &&
          !x.value.i5.toString().includes('N')) ||
        (Number.parseFloat(x.value.i6) > 0.3 &&
          !x.value.i6.toString().includes('N')) ||
        (Number.parseFloat(x.value.i7) > 0.3 &&
          !x.value.i7.toString().includes('N')) ||
        (Number.parseFloat(x.value.i8) > 0.3 &&
          !x.value.i8.toString().includes('N')) ||
        (Number.parseFloat(x.value.i9) > 0.3 &&
          !x.value.i9.toString().includes('N')) ||
        (Number.parseFloat(x.value.i10) > 0.3 &&
          !x.value.i10.toString().includes('N')) ||
        (Number.parseFloat(x.value.i11) > 0.3 &&
          !x.value.i11.toString().includes('N')) ||
        (Number.parseFloat(x.value.i12) > 0.3 &&
          !x.value.i12.toString().includes('N'))) &&
        (x.value.srOnOf == '0' ||
          x.value.srOnOf == '2' ||
          x.value.srOnOf == '4' ||
          x.value.k1 == '0' ||
          x.value.k1 == '2' ||
          x.value.k1 == '4' ||
          x.value.k2 == '0' ||
          x.value.k2 == '2' ||
          x.value.k2 == '4'));
    var mwvaneaUechvelad =
      (x.value.srOnOf == '1' ||
        x.value.srOnOf == '3' ||
        x.value.srOnOf == '5' ||
        x.value.k1 == '1' ||
        x.value.k1 == '3' ||
        x.value.k1 == '5' ||
        x.value.k2 == '1' ||
        x.value.k2 == '3' ||
        x.value.k2 == '5') &&
      (Number.parseFloat(x.value.ua) > 0.3 ||
        x.value.ua.toString().includes('N')) &&
      (Number.parseFloat(x.value.ub) > 0.3 ||
        x.value.ub.toString().includes('N')) &&
      (Number.parseFloat(x.value.uc) > 0.3 ||
        x.value.uc.toString().includes('N')) &&
      (Number.parseFloat(x.value.ia) > 0.3 ||
        x.value.ia.toString().includes('N')) &&
      (Number.parseFloat(x.value.ib) > 0.3 ||
        x.value.ib.toString().includes('N')) &&
      (Number.parseFloat(x.value.ic) > 0.3 ||
        x.value.ic.toString().includes('N')) &&
      (Number.parseFloat(x.value.ia) > 0.3 ||
        x.value.ia.toString().includes('N')) &&
      (Number.parseFloat(x.value.ib) > 0.3 ||
        x.value.ib.toString().includes('N')) &&
      (Number.parseFloat(x.value.ic) > 0.3 ||
        x.value.ic.toString().includes('N')) &&
      (Number.parseFloat(x.value.i1) > 0.3 ||
        x.value.i1.toString().includes('N')) &&
      (Number.parseFloat(x.value.i2) > 0.3 ||
        x.value.i2.toString().includes('N')) &&
      (Number.parseFloat(x.value.i3) > 0.3 ||
        x.value.i3.toString().includes('N')) &&
      (Number.parseFloat(x.value.i4) > 0.3 ||
        x.value.i4.toString().includes('N')) &&
      (Number.parseFloat(x.value.i5) > 0.3 ||
        x.value.i5.toString().includes('N')) &&
      (Number.parseFloat(x.value.i6) > 0.3 ||
        x.value.i6.toString().includes('N')) &&
      (Number.parseFloat(x.value.i7) > 0.3 ||
        x.value.i7.toString().includes('N')) &&
      (Number.parseFloat(x.value.i8) > 0.3 ||
        x.value.i8.toString().includes('N')) &&
      (Number.parseFloat(x.value.i9) > 0.3 ||
        x.value.i9.toString().includes('N')) &&
      (Number.parseFloat(x.value.i10) > 0.3 ||
        x.value.i10.toString().includes('N')) &&
      (Number.parseFloat(x.value.i11) > 0.3 ||
        x.value.i11.toString().includes('N')) &&
      (Number.parseFloat(x.value.i12) > 0.3 ||
        x.value.i12.toString().includes('N'));

    if (tarigi == true) {
      witeli = false;
      tetri = false;
      mwvane = false;
      yviteli = false;
      yafisferi = false;
      mwvaneaUechvelad = false;
    }

    if (witeli == true) {
      tetri = false;
      mwvane = false;
      yviteli = false;
      yafisferi = false;
      mwvaneaUechvelad = false;
    }

    if (yviteli == true) {
      mwvane = false;
      tetri = false;
    }

    if (yafisferi == true) {
      mwvane = false;
      tetri = false;
      mwvaneaUechvelad = false;
    }
    // console.log(x.value.color);
    if (
      x.value.color == 'yellow' ||
      x.value.color == 'Yellow' ||
      x.color == 'yellow' ||
      x.color == 'Yellow'
    ) {
      // console.log('ყვითელი');
      mwvane = false;
      mwvaneaUechvelad = false;
      tetri = false;
      yviteli = true;
    }

    tarigi
      ? (result = '#c3c4c7')
      : witeli
      ? (result = '#dc3545')
      : tetri
      ? (result = '#fff')
      : yafisferi
      ? (result = '#5C4033')
      : yviteli
      ? (result = 'yellow')
      : mwvane
      ? (result = 'rgb(96, 214, 96)')
      : (result = '');

    return mwvaneaUechvelad ? 'rgb(96, 214, 96)' : result;
  }

  setAll(completed: boolean) {
    this.shareDataService.isCheckedMultiple = completed;
    if (!completed) {
      this.shareDataService.deviceList = [];
    }

    // this.selectedDeviceId = this.pingMessage[i].imei;
    this.selectionStatus = completed ? 'multiple' : 'single';
    this.isChecked = completed ? true : false;
    //  this.shareDataService.deviceList
    for (var i = 0; i < Object.keys(this.pingMessage).length; i++) {
      var imei = this.pingMessage[i]['imei'];

      var conStatus = this.pingMessage[i]['name'];
      if (completed == true) {
        if (imei !== null && conStatus != 2) {
          this.shareDataService.deviceIndex[i] = true;
          this.shareDataService.deviceList.push(this.pingMessage[i]['imei']);

          if (this.selectedDeviceId == undefined) {
            this.selectedDeviceId = this.pingMessage[i]['imei'];
          }
        }
      }
      if (!completed) {
        if (imei !== null) {
          this.shareDataService.deviceIndex[i] = false;
        }
      }
    }
  }

  resetDisableSounds() {
    this.service.deviceEvents(this.userId).subscribe(
      (data) => {
        this.EventHistory = data;
        this.deviceErrors = [];
        this.isCleanLoading = false;
      },
      (err) => {
        console.log(err);
        this.isCleanLoading = false;
      }
    );
  }

  // changeSelectAllState() {
  //   this.selectMultiple = !this.selectMultiple;
  //   if (this.selectMultiple == false) {
  //     this.myCheckbox.checked = false;
  //   }
  //   this.removeFromLocalStorage('true');
  //   this.removeFromLocalStorage('false');
  // }

  toogleSlider() {
    this.requestSendingTrigger = !this.requestSendingTrigger;
  }

  changeSelectAllState() {
    this.selectMultiple = !this.selectMultiple;
  }

  startNetworkRequests() {
    this.requestTrigger = true;
  }

  stopNetworkRequests() {
    this.requestTrigger = false;
  }

  removeFromLocalStorage(value: string) {
    for (var i = 0; i < localStorage.length; i++) {
      var key = localStorage.key(i);
      if (localStorage.getItem(key) === value) {
        localStorage.removeItem(key);
        // decrement i so we don't skip any items
        i--;
      }
    }
  }

  notSendedCommands(): void {
    this.service.getNonSendedCommands().subscribe(
      (data) => {
        this.notSended = [];
        for (var i = 0; i < Object.keys(data).length; i++) {
          this.notSended.push(this.deviceLog[data[i].imei].name);
        }
      },
      (err) => console.log(err)
    );
  }
}
