<div class="container-fluid">
  <!-- <button (click)="test()">TEST TEST TEST TEST TEST</button> -->
  <div class="row">
    <div class="col-md-3 col-3 padding-0" id="user-list-column">
      <div class="userList">
        <h1>მომხმარებელი</h1>
        <div
          *ngFor="let name of toppingList"
          class="user"
          (click)="getDeviceList(name)"
        >
          <a>{{ name }} <mat-icon>arrow_forward</mat-icon></a>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-4 padding-0" id="user-devices-column">
      <div class="userDevices">
        <h2>მომხმარებლის მოწყობილობები</h2>
        <div *ngFor="let name of devices" class="devices">
          <a
            >{{ name.key }}
            <span
              ><mat-checkbox
                id="{{ name.key }}"
                (change)="checked('fromUserDevice', name.value, $event)"
              ></mat-checkbox></span
          ></a>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-1 padding-0" id="arrow-column">
      <div class="arrows">
        <h2>---</h2>
        <div id="icon-container">
          <mat-icon
            style="color: rgb(73, 73, 252)"
            (click)="addDeviceToUser('')"
            >arrow_forward</mat-icon
          >
          <mat-icon
            style="color: rgb(73, 73, 252)"
            (click)="addDeviceToUser('user')"
            >arrow_back</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="col-md-4 col-4 padding-0" id="avalaible-devices-column">
      <div class="avaleibleDevices">
        <h2>აირჩიე მოწყობილობა</h2>
        <div *ngFor="let name of nonUserDevices" class="nonUserDevices">
          <a
            >{{ name.key }}
            <span
              ><mat-checkbox
                id="{{ name.key }}"
                (change)="checked1('nonUserDevices', name.value, $event)"
              ></mat-checkbox></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <button class="btn btn-success">შენახვა</button> -->
