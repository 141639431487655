<app-nav *ngIf="router.url != '/home'"></app-nav>
<!-- this creates a google map on the page with the given lat/lng from -->
<!-- the component as the initial center of the map: -->
<agm-map  [zoom]="mapZoom" [latitude]="lat" [longitude]="lng" [fullscreenControl]='true' [mapTypeControl]='true'  [class.mapWidth]="router.url == '/home'">
  <agm-marker  *ngFor="let data of devices; let i = index"
  (markerClick)="markerClicked(data)"
  [color]="(srOnOffByImei[data.deviceImei] == 'SROF') ? 'green':'blue'"
  [latitude]="data.lat" [longitude]="data.long"
  [title]="'სახელი: ' + data.deviceName + ',' + '\n'
  + 'Long: ' + data.long + ',' + '\n'
  + 'Lat: ' +  data.lat +   ',' + '\n'
  + 'მისამართი: ' + data.adress + ',' + '\n'"
  [iconUrl] ="
  {
    url:   dateDiff[data.deviceImei] ? dateDiff[data.deviceImei]: '../../assets/img/grayMarker.svg',
    
    scaledSize: {
        width: 40,
        height: 60
    }
}"
  >
    <!-- <ng-container>
      <agm-polyline  [strokeColor]="color" [strokeWeight]="weight" >
         <agm-polyline-point *ngFor="let test of latlng" [latitude]="test[1]"
         [longitude]="test[0]" >
         </agm-polyline-point>
     </agm-polyline>
   </ng-container> -->
   <agm-info-window #infoWindow>
    <div>
      <button class="btn btn-outline-dark btn-sm" (click)="copyToClipboard(data.lat,data.long)">Copy</button>
      <br />
      <a href="https://www.google.com/maps/search/?api=1&query={{data.lat}},{{data.long}}" target="_blank">Open in Google Maps</a>

    </div>
  </agm-info-window>
  </agm-marker>
  <!-- <agm-marker  *ngFor="let data of points; let i = index"
  [latitude]="data[1]" [longitude]="data[0]"
  [title]="'ბოძი'"
  [iconUrl] ="
  {
   url: (dateDiff[data.deviceImei] == true) ?  '../../assets/img/redDot.svg' : ( (srOnOffByImei[data.deviceImei] == 'SRON') ?  '../../assets/img/greenDot.svg':'../../assets/img/grayDot.svg'),
    scaledSize: {
        width: 15,
        height: 15
    }
}"
  >
    <ng-container>
      <agm-polyline  [strokeColor]="color" [strokeWeight]="weight" >
         <agm-polyline-point *ngFor="let test of latlng" [latitude]="test[1]"
         [longitude]="test[0]" >
         </agm-polyline-point>
     </agm-polyline>
   </ng-container>

  </agm-marker> -->
</agm-map>

<div class="mapDeviceList" *ngIf="router.url != '/home'">
  <mat-form-field appearance="fill" class="add-device-option">
    <mat-label>მოწყობილობა</mat-label>
    <mat-select [formControl]="toppings" multiple [(ngModel)]="selectedDevice" (selectionChange)="setMapFocus1(selectedDevice)">
      <mat-select-trigger>
        {{toppings.value ? toppings.value[0] : ''}}
        <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
          (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <input matInput type="text" (keyup)="filterListCareUnit($event.target.value)" placeholder="ძებნა" style="border: 1px solid rgb(199, 196, 196); width: 99% !important;">
      <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping.split('__')[0]}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div  class="all-device-info">
 <button class="btn btn-light" (click)="markerClicked('all')">info</button>
</div>




  <!-- <div>
    <div style="background-color: black;">
      <button type="button" (click)="fileInput.click()">Select file</button>
      <input #fileInput type="file" accept=".xlsx" (change)="handleFileInput($event.target.files)" style="display: none;">
    </div> -->
