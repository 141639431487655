<h1>რედაქტირება</h1>
<form class="example-form">
  <mat-form-field
    appearance="fill"
    class="add-device-option"
    style="margin-right: 5px"
  >
    <mat-label>მოწყობილობა:</mat-label>

    <div>
      <mat-select>
        <input
          matInput
          type="text"
          (keyup)="filterListCareUnit($event.target.value)"
          placeholder="ძებნა"
          style="
            border: 1px solid rgb(199, 196, 196);
            width: 90% !important;
            padding: 5px;
            height: 30px;
          "
        />
        <!-- <mat-select-trigger>
        {{ toppings.value ? toppings.value[0] : "" }}
        <span
          *ngIf="toppings.value?.length > 1"
          class="example-additional-selection"
        >
          (+{{ toppings.value.length - 1 }}
          {{ toppings.value?.length === 2 ? "other" : "others" }})
        </span>
      </mat-select-trigger> -->
        <mat-option *ngFor="let topping of noramDeviceLog" [value]="topping">
          <p
            style="width: 100%; padding: 8px; text-align: center"
            (click)="onDeviceSelect(topping)"
          >
            {{ topping.name }}
          </p></mat-option
        >
      </mat-select>
    </div>
  </mat-form-field>
  <p>სახელი:</p>
  <input
    matInput
    class="form-control"
    [value]="selectedDeviceName"
    [(ngModel)]="selectedDeviceName"
    name="name"
  />
  <p>სერიული ნომერი:</p>
  <input
    matInput
    class="form-control"
    [value]="SerialNumber"
    [(ngModel)]="SerialNumber"
    name=" SerialNumber"
  />
  <p>იმეი:</p>
  <input
    matInput
    class="form-control"
    [value]="selectedDeviceImei"
    [(ngModel)]="selectedDeviceImei"
    name="imei"
    (keyup)="checkImei(selectedDeviceImei)"
  />
  <div class="mt-3">
    <label for="KARADIS_NO">კარადის ნომერი:</label>
    <mat-form-field appearance="fill" class="add-device-option">
      <mat-select
        [(ngModel)]="selectedKarada"
        (selectionChange)="onBookChange($event)"
        placeholder="საწყობი"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option>საწყობი</mat-option>
        <mat-option
          *ngFor="let device of deviceInfo | keyvalue"
          [value]="device.key"
          >{{ device.key }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <!-- <p>LAT:</p>
  <input
    matInput
    class="form-control"
    [value]="selectedDeviceLat"
    [(ngModel)]="selectedDeviceLat"
    name="Lat"
  />
  <p>LONG:</p>
  <input
    matInput
    class="form-control"
    [value]="selectedDeviceLong"
    [(ngModel)]="selectedDeviceLong"
    name="Long"
  />
  <p>მისამართი:</p>
  <input
    matInput
    class="form-control"
    [value]="selectedDeviceAdress"
    [(ngModel)]="selectedDeviceAdress"
    name="adress"
  /> -->
  <p>კომენტარი:</p>
  <textarea
    name=""
    id=""
    cols="30"
    rows="2"
    [(ngModel)]="selectedComment"
    #textvalue
  ></textarea>
  <br />
  <button
    [disabled]="disableAddButton"
    style="margin-top: 12px"
    class="btn btn-primary"
    (click)="editDeviceInfo(selectedDeviceName)"
  >
    შენახვა
  </button>
</form>
