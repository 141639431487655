<app-nav></app-nav>
<div class="deviceCommandspage">
  <mat-tab-group dynamicHeight (selectedIndexChange)="tabClick($event)">
    <!-- <mat-tab label="კალიბრაცია">
      <app-device-calibration></app-device-calibration>
    </mat-tab> -->
    <mat-tab label="NTP სერვერი">
      <div class="example-large-box mat-elevation-z4" style="display: flex">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
              (selectionChange)="getDebugLog(selectedDevice)"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option
                *ngFor="let topping of toppingList"
                [value]="topping"
                >{{ topping }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <input
            required
            ngModel
            type="text"
            style="width: 200px"
            [(ngModel)]="setNtp"
            class="form-control"
            name="Ntp"
            #Ntp="ngModel"
          />
          <div class="alert alert-danger" *ngIf="Ntp.touched && !Ntp.valid">
            შეიყვანეთ სწორი NTP სერვერი
          </div>
          <button
            class="btn btn-sm btn-success"
            (click)="
              deviceCommands('cmd_setntp ' + setNtp, selectedDevice, $event)
            "
            [disabled]="!Ntp.valid || !selectedDevice"
            id="ntp-send"
          >
            გაგზავნა
          </button>
        </div>
        <div>
          <ul
            style="
              height: 20vw;
              width: 60vw;
              background-color: black;
              overflow-y: scroll;
            "
          >
            <li
              *ngFor="let x of debugLog"
              class="ml-3"
              style="color: rgb(35, 209, 35)"
            >
              <span style="color: red" class="mr-3">{{ x.dateTime }} </span>
              {{ x.rawData }} <br />
              <div class="mt-1"></div>
            </li>
          </ul>
        </div>
        <div
          style="width: 20vw; height: 20vw; overflow-y: scroll; display: flex"
        >
          <ul class="ml-3">
            <p style="text-align: center">გაგზავნილი ბრძანებები</p>

            <li
              *ngFor="let x of sendingCommandHistory"
              style="font-weight: bold"
            >
              {{ x }}
            </li>
            <div class="mt-2"></div>
          </ul>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="APN">
      <div class="example-large-box mat-elevation-z4">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option
                *ngFor="let topping of toppingList"
                [value]="topping"
                >{{ topping }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <input
            ngModel
            required
            name="apn"
            class="form-control"
            #apn="ngModel"
            type="text"
            style="width: 200px"
            [(ngModel)]="setNtp"
            pattern="[a-zA-Z.:/0-9]*"
          />
          <div class="alert alert-danger" *ngIf="apn.touched && !apn.valid">
            შეიყვანეთ სწორი APN
          </div>
        </div>
        <button
          class="btn btn-sm btn-success"
          (click)="
            deviceCommands('cmd_setapn ' + setNtp, selectedDevice, $event)
          "
          [disabled]="!apn.valid || !selectedDevice"
          id="apn-send"
        >
          გაგზავნა
        </button>
      </div>
    </mat-tab>
    <mat-tab label="IP/PORT">
      <div class="example-large-box mat-elevation-z4">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option
                *ngFor="let topping of toppingList"
                [value]="topping"
                >{{ topping }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div></div>
          <p>ძირითადი IP/PORT</p>
          <div style="display: flex">
            <input
              ngModel
              required
              class="form-control"
              name="firstIp"
              #firstIp="ngModel"
              type="text"
              style="width: 160px"
              [(ngModel)]="mainIp"
              pattern="[a-zA-Z./0-9]*"
            />
            <h1>/</h1>
            <input
              ngModel
              required
              class="form-control"
              name="firstIp"
              #firstPort="ngModel"
              type="text"
              style="width: 80px"
              [(ngModel)]="mainPort"
              pattern="[0-9]*"
            />
          </div>

          <p>სათადარიგო IP/PORT</p>
          <div style="display: flex">
            <input
              ngModel
              required
              class="form-control"
              name="secondIp"
              #secondIp="ngModel"
              type="text"
              style="width: 160px"
              [(ngModel)]="reservIp"
              pattern="[a-zA-Z./0-9]*"
            />
            <h1>/</h1>
            <input
              ngModel
              required
              class="form-control"
              name="secondIp"
              #secondPort="ngModel"
              type="text"
              style="width: 80px"
              [(ngModel)]="reservPort"
              pattern="[0-9]*"
            />
          </div>
          <div
            class="alert alert-danger"
            *ngIf="
              (firstIp.touched || secondIp.touched) &&
              (!firstIp.valid || !secondIp.valid)
            "
          >
            მონაცემები არასწორია
          </div>
        </div>
        <button
          class="btn btn-sm btn-success"
          [disabled]="!firstIp.valid || !secondIp.valid || !selectedDevice"
          id="ip-send"
          (click)="
            deviceCommands(
              'cmd_setipports ' +
                mainIp +
                ':' +
                mainPort +
                '/' +
                reservIp +
                ':' +
                reservPort,
              selectedDevice,
              $event
            )
          "
        >
          გაგზავნა
        </button>
      </div>
    </mat-tab>
    <mat-tab label="რიგგარეშე ჩართვა/გამორთვა">
      <div class="example-large-box mat-elevation-z4">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option
                *ngFor="let topping of toppingList"
                [value]="topping"
                >{{ topping }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div></div>
          <div
            style="
              display: flex;
              color: red;
              font-weight: bold;
              text-align: center;
            "
          >
            <div style="margin-right: 10px">
              <p>მთავარი ჩართვა</p>
              <ngb-timepicker [(ngModel)]="timeOn"></ngb-timepicker>
            </div>
            <div>
              <p>მთავარი გამორთვა</p>
              <ngb-timepicker [(ngModel)]="timeOff"></ngb-timepicker>
            </div>
          </div>

          <div
            style="
              display: flex;
              text-align: center;
              color: rgb(255, 136, 0);
              font-weight: bold;
            "
          >
            <div style="margin-right: 10px">
              <p>K1 ჩართვა</p>
              <ngb-timepicker [(ngModel)]="timeOnK1"></ngb-timepicker>
            </div>
            <div>
              <p>K1 გამორთვა</p>
              <ngb-timepicker [(ngModel)]="timeOffK1"></ngb-timepicker>
            </div>
          </div>
          <div
            style="
              display: flex;
              text-align: center;
              color: green;
              font-weight: bold;
            "
          >
            <div style="margin-right: 10px">
              <p>K2 ჩართვა</p>
              <ngb-timepicker [(ngModel)]="timeOnK2"></ngb-timepicker>
            </div>
            <div>
              <p>K2 გამორთვა</p>
              <ngb-timepicker [(ngModel)]="timeOffK2"></ngb-timepicker>
            </div>
          </div>
          <!-- <div
            style="
              display: flex;
              text-align: center;
              color: blue;
              font-weight: bold;
            "
          >
            <div style="margin-right: 10px">
              <p>K3 ჩართვა</p>
              <ngb-timepicker [(ngModel)]="timeOnK3"></ngb-timepicker>
            </div>
            <div>
              <p>K3 გამორთვა</p>
              <ngb-timepicker [(ngModel)]="timeOffK3"></ngb-timepicker>
            </div>
          </div> -->
        </div>
        <button
          id="sheduler-send"
          class="btn btn-sm btn-success"
          [disabled]="!selectedDevice"
          (click)="
            deviceCommands(
              'cmd_user_schedulers ' +
                (timeOn['hour'] < 10 ? '0' + timeOn['hour'] : timeOn['hour']) +
                ':' +
                (timeOn['minute'] < 10
                  ? '0' + timeOn['minute']
                  : timeOn['minute']) +
                '-' +
                (timeOff['hour'] < 10
                  ? '0' + timeOff['hour']
                  : timeOff['hour']) +
                ':' +
                (timeOff['minute'] < 10
                  ? '0' + timeOff['minute']
                  : timeOff['minute']) +
                '/' +
                (timeOnK1['hour'] < 10
                  ? '0' + timeOnK1['hour']
                  : timeOnK1['hour']) +
                ':' +
                (timeOnK1['minute'] < 10
                  ? '0' + timeOnK1['minute']
                  : timeOnK1['minute']) +
                '-' +
                (timeOffK1['hour'] < 10
                  ? '0' + timeOffK1['hour']
                  : timeOffK1['hour']) +
                ':' +
                (timeOffK1['minute'] < 10
                  ? '0' + timeOffK1['minute']
                  : timeOffK1['minute']) +
                '/' +
                (timeOnK2['hour'] < 10
                  ? '0' + timeOnK2['hour']
                  : timeOnK2['hour']) +
                ':' +
                (timeOnK2['minute'] < 10
                  ? '0' + timeOnK2['minute']
                  : timeOnK2['minute']) +
                '-' +
                (timeOffK2['hour'] < 10
                  ? '0' + timeOffK2['hour']
                  : timeOffK2['hour']) +
                ':' +
                (timeOffK2['minute'] < 10
                  ? '0' + timeOffK2['minute']
                  : timeOffK2['minute']) +
                '/' +
                (timeOnK3['hour'] < 10
                  ? '0' + timeOnK3['hour']
                  : timeOnK3['hour']) +
                ':' +
                (timeOnK3['minute'] < 10
                  ? '0' + timeOnK3['minute']
                  : timeOnK3['minute']) +
                '-' +
                (timeOffK3['hour'] < 10
                  ? '0' + timeOffK3['hour']
                  : timeOffK3['hour']) +
                ':' +
                (timeOffK3['minute'] < 10
                  ? '0' + timeOffK3['minute']
                  : timeOffK3['minute']),
              selectedDevice,
              $event
            )
          "
        >
          გაგზავნა
        </button>
      </div>
    </mat-tab>
    <mat-tab label="ჩართვა/გამორთვის წანაცვლება">
      <div class="example-large-box mat-elevation-z4">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
              (selectionChange)="onDeviceSelect(selectedDevice)"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option
                *ngFor="let topping of toppingList"
                [value]="topping"
                >{{ topping }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div></div>
          <div>
            <div>
              <p>მთავარი ჩართვის წანაცვლება:</p>
              <input
                type="number"
                ngModel
                name="wanacvlebaOne"
                #wanacvlebaOne="ngModel"
                max="99"
                step="1"
                value="0"
                style="width: 200px"
                [(ngModel)]="wanacvleba"
              />
            </div>

            <div>
              <p>მთავარი გამორთვის წანაცვლება:</p>
              <input
                type="number"
                ngModel
                names="wanacvlebaTwo"
                #wanacvlebaTwo="ngModel"
                value="0"
                max="99"
                style="width: 200px"
                [(ngModel)]="wanacvlebaori"
              />
            </div>
          </div>

          <br />
          <div>
            <p>K1 ჩართვის წანაცვლება:</p>
            <input
              type="number"
              ngModel
              name="wanacvlebaOne"
              #wanacvlebaK1One="ngModel"
              max="99"
              step="1"
              value="0"
              style="width: 200px"
              [(ngModel)]="wanacvlebaK1"
            />
            <p>K1 გამორთვის წანაცვლება:</p>
            <input
              type="number"
              ngModel
              names="wanacvlebaTwo"
              #wanacvlebaK1Two="ngModel"
              value="0"
              max="99"
              style="width: 200px"
              [(ngModel)]="wanacvlebaoriK1"
            />
          </div>
          <br />
          <div>
            <p>K2 ჩართვის წანაცვლება:</p>
            <input
              type="number"
              ngModel
              name="wanacvlebaOne"
              #wanacvlebaK2One="ngModel"
              max="99"
              step="1"
              value="0"
              style="width: 200px"
              [(ngModel)]="wanacvlebaK2"
            />
            <p>K2 გამორთვის წანაცვლება:</p>
            <input
              type="number"
              ngModel
              names="wanacvlebaTwo"
              #wanacvlebaK2Two="ngModel"
              value="0"
              max="99"
              style="width: 200px"
              [(ngModel)]="wanacvlebaoriK2"
            />
          </div>
          <br />
          <!-- <div>
            <p>K3 ჩართვის წანაცვლება:</p>
            <input
              type="number"
              ngModel
              name="wanacvlebaOne"
              #wanacvlebaK3One="ngModel"
              max="99"
              step="1"
              value="0"
              style="width: 200px"
              [(ngModel)]="wanacvlebaK3"
            />
            <p>K3 გამორთვის წანაცვლება:</p>
            <input
              type="number"
              ngModel
              names="wanacvlebaTwo"
              #wanacvlebaK3Two="ngModel"
              value="0"
              max="99"
              style="width: 200px"
              [(ngModel)]="wanacvlebaoriK3"
            />
          </div> -->
        </div>
        <button
          [disabled]="!selectedDevice"
          id="wanacvleba-send"
          class="btn btn-sm btn-success"
          (click)="
            deviceCommandsWanacvleba(
              (wanacvleba.toString().includes('-')
                ? '-' + ('0' + wanacvleba.toString().replace('-', '')).slice(-2)
                : '+' +
                  ('0' + wanacvleba.toString().replace('+', '')).slice(-2)) +
                (wanacvlebaori.toString().includes('-')
                  ? '-' +
                    ('0' + wanacvlebaori.toString().replace('-', '')).slice(-2)
                  : '+' +
                    ('0' + wanacvlebaori.toString().replace('+', '')).slice(
                      -2
                    )) +
                '/' +
                (wanacvlebaK1.toString().includes('-')
                  ? '-' +
                    ('0' + wanacvlebaK1.toString().replace('-', '')).slice(-2)
                  : '+' +
                    ('0' + wanacvlebaK1.toString().replace('+', '')).slice(
                      -2
                    )) +
                (wanacvlebaoriK1.toString().includes('-')
                  ? '-' +
                    ('0' + wanacvlebaoriK1.toString().replace('-', '')).slice(
                      -2
                    )
                  : '+' +
                    ('0' + wanacvlebaoriK1.toString().replace('+', '')).slice(
                      -2
                    )) +
                '/' +
                (wanacvlebaK2.toString().includes('-')
                  ? '-' +
                    ('0' + wanacvlebaK2.toString().replace('-', '')).slice(-2)
                  : '+' +
                    ('0' + wanacvlebaK2.toString().replace('+', '')).slice(
                      -2
                    )) +
                (wanacvlebaoriK2.toString().includes('-')
                  ? '-' +
                    ('0' + wanacvlebaoriK2.toString().replace('-', '')).slice(
                      -2
                    )
                  : '+' +
                    ('0' + wanacvlebaoriK2.toString().replace('+', '')).slice(
                      -2
                    )) +
                '/' +
                (wanacvlebaK3.toString().includes('-')
                  ? '-' +
                    ('0' + wanacvlebaK3.toString().replace('-', '')).slice(-2)
                  : '+' +
                    ('0' + wanacvlebaK3.toString().replace('+', '')).slice(
                      -2
                    )) +
                (wanacvlebaoriK3.toString().includes('-')
                  ? '-' +
                    ('0' + wanacvlebaoriK3.toString().replace('-', '')).slice(
                      -2
                    )
                  : '+' +
                    ('0' + wanacvlebaoriK3.toString().replace('+', '')).slice(
                      -2
                    )),
              selectedDevice,
              $event
            )
          "
        >
          გაგზავნა
        </button>
      </div>
    </mat-tab>
    <mat-tab label="ძაბვის კოეფიციენტი">
      <div class="example-large-box mat-elevation-z4">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option
                *ngFor="let topping of toppingList"
                [value]="topping"
                >{{ topping }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <input
            required
            ngModel
            name="power"
            #power="ngModel"
            type="text"
            style="width: 200px"
            [value]="setPower"
            [(ngModel)]="setPower"
          />
          <div class="alert alert-danger" *ngIf="power.touched && !power.valid">
            შეიყვანეთ ძაბვის კოეფიციენტი
          </div>
        </div>
        <button
          class="btn btn-sm btn-success"
          (click)="
            deviceCommands(
              'cmd_setvoltagecoef ' + setPower,
              selectedDevice,
              $event
            )
          "
          [disabled]="!power.valid || !selectedDevice"
          id="voltage-send"
        >
          გაგზავნა
        </button>
      </div>
    </mat-tab>
    <mat-tab label="დენის კოეფიციენტი">
      <div class="example-large-box mat-elevation-z4">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option
                *ngFor="let topping of toppingList"
                [value]="topping"
                >{{ topping }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <input
            ngModel
            name="powerTwo"
            #powerTwo="ngModel"
            type="text"
            required
            style="width: 200px"
            [value]="setPower"
            [(ngModel)]="setvoltage"
          />
          <div
            class="alert alert-danger"
            *ngIf="powerTwo.touched && !powerTwo.valid"
          >
            შეიყვანეთ დენის კოეფიციენტი
          </div>
        </div>
        <button
          id="current-send"
          class="btn btn-sm btn-success"
          (click)="
            deviceCommands(
              'cmd_setcurrentcoef ' + setvoltage,
              selectedDevice,
              $event
            )
          "
          [disabled]="!powerTwo.valid || !selectedDevice"
        >
          გაგზავნა
        </button>
      </div>
    </mat-tab>

    <mat-tab label="მრიცხველის ჩვენების რედაქტირება">
      <div class="example-large-box mat-elevation-z4">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option
                *ngFor="let topping of toppingList"
                [value]="topping"
                >{{ topping }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <input type="text" #editWat />
        </div>
        <button
          id="reset-wat-send"
          class="btn btn-sm btn-success"
          (click)="
            deviceCommands(
              'DEV_Write_whr:' + editWat.value,
              selectedDevice,
              $event
            )
          "
          [disabled]="!powerTwo.valid || !selectedDevice"
        >
          გაგზავნა
        </button>
      </div>
    </mat-tab>
    <mat-tab label="SMS">
      <div class="example-large-box mat-elevation-z4">
        <div>
          <mat-form-field appearance="fill" class="add-device-option">
            <mat-label>მოწყობილობა</mat-label>
            <mat-select
              [formControl]="toppings"
              multiple
              [(ngModel)]="selectedDevice"
            >
              <mat-select-trigger>
                {{ toppings.value ? toppings.value[0] : "" }}
                <span
                  *ngIf="toppings.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ toppings.value.length - 1 }}
                  {{ toppings.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <input
                matInput
                type="text"
                (keyup)="filterListCareUnit($event.target.value)"
                placeholder="ძებნა"
                style="
                  border: 1px solid rgb(199, 196, 196);
                  width: 99% !important;
                "
              />
              <mat-option *ngFor="let topping of toppingList" [value]="topping"
                >{{ topping }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>ბრძანება</mat-label>
            <mat-select [(ngModel)]="smsCmd" [formControl]="Sms">
              <mat-option *ngFor="let cmd of smsCommands" [value]="cmd">
                {{ cmd.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <input
            [ngClass]="[smsCmd.title.startsWith('Turn') ? 'hiddeInput' : '']"
            type="text"
            #sendSms
            [disabled]="smsCmd.title == 'restart' || smsCmd.title == 'config'"
          />
          <mat-form-field
            appearance="fill"
            [ngClass]="[!smsCmd.title.startsWith('Turn') ? 'hiddeInput' : '']"
          >
            <mat-label>რელე</mat-label>

            <mat-select
              [formControl]="rellayPortFormController"
              [(ngModel)]="singleRellayPort"
            >
              <mat-option *ngFor="let port of rellayPorts" [value]="port">
                {{ port }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          id="sms-send"
          class="btn btn-sm btn-success"
          (click)="sendSms2(smsCmd, sendSms.value, selectedDevice)"
          [disabled]="!powerTwo.valid || !selectedDevice"
        >
          გაგზავნა
        </button>
        <button
          (click)="deviceCommands2('&&DEV_debug')"
          class="btn btn-success bnt-sm"
        >
          debug
        </button>
      </div>
    </mat-tab>

    <mat-tab label="კალიბრაცია">
      <app-device-calibration></app-device-calibration>
    </mat-tab>
  </mat-tab-group>
  <!-- <button class="btn btn-danger" routerLink="/home">
    გამოსვლა
    </button> -->
</div>
<div>
  <app-device-configuration></app-device-configuration>
</div>
