import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-user-info',
  templateUrl: './edit-user-info.component.html',
  styleUrls: ['./edit-user-info.component.scss'],
})
export class EditUserInfoComponent implements OnInit {
  toppings = new FormControl();
  roles = new FormControl();
  userList: any;
  toppingList: any[] = [];
  selectedUser: string;
  selectedUserName: string;
  selectedUserPass: string;
  selectedRole: string;
  userIdList: {};
  roleList = ['ადმინისტრატორი', 'დისპეჩერი'];
  constructor(
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getUserList();
  }

  // tslint:disable-next-line:typedef
  getUserList() {
    this.userService.getUserList().subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.userList = res;
        this.userIdList = {};
        console.log(res);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(res).length; i++) {
          // tslint:disable-next-line:forin
          for (const x in res[i]) {
            if (!this.toppingList.includes(res[i].name)) {
              this.toppingList.push(res[i].name);
              this.userIdList[res[i].name] = res[i].id;
            }
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  onDeviceSelect(x) {
    console.log(x);
  }

  // tslint:disable-next-line:typedef
  editDeviceInfo(userName, userPass, oldUserName, userRole) {
    console.log(userName);
    console.log(userPass);
    let data = '';
    const id = this.userIdList[oldUserName];
    data = id + ';' + userName + ';' + userPass + ';' + userRole;

    this.userService.editUserInfo(data).subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        if (res['succeeded'] === true) {
          this.toastr.success('მონაცემები განახლდა');
        } else {
          this.toastr.error('მონაცემების განახლება ვერ ხერხდება');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  onRoleSelect(val) {
    console.log(val);
  }

  deleteUser(userName: any) {
    const id = this.userIdList[userName[0]];
    this.userService.deleteUser(id).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => console.log(err)
    );
  }
}
