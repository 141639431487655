import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remove'
})
export class RemovePipe implements PipeTransform {
  transform(value: string, args: string): string {
    var resultArr = value.split(" ");
    resultArr.pop();
    resultArr.pop();
    return resultArr.join(" ");
  }
}