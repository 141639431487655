<h1>მოწყობილობის კონფიგურაცია</h1>

<mat-form-field appearance="standard">
  <mat-label>ფილტრი</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="ძებნა" #input  >
</mat-form-field>

<div class="mat-elevation-z8">
  <table  mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="imei">
      <th mat-header-cell *matHeaderCellDef> იმეი </th>
      <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.imei}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> სახელი </th>
      <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="apn">
      <th mat-header-cell *matHeaderCellDef> APN </th>
      <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.apn}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <!-- <ng-container matColumnDef="csq">
      <th mat-header-cell *matHeaderCellDef> მიღების დონე </th>
      <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.csq + '%'}} </td>
    </ng-container> -->
     <!-- Symbol Column -->
     <ng-container matColumnDef="deviceDateTime">
      <th mat-header-cell *matHeaderCellDef> თარიღი </th>
      <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.deviceDateTime}} </td>
    </ng-container>
     <!-- Symbol Column -->
     <ng-container matColumnDef="interval">
      <th mat-header-cell *matHeaderCellDef> განახლების ინტერვალი </th>
      <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.interval}} </td>
    </ng-container>
     <!-- Symbol Column -->
     <ng-container matColumnDef="mainIp">
      <th mat-header-cell *matHeaderCellDef> ძირითადი IP/PORT </th>
      <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.mainIp}} </td>
    </ng-container>
     <!-- Symbol Column -->
     <ng-container matColumnDef="mobileNumber">
      <th mat-header-cell *matHeaderCellDef> ტელ.No</th>
      <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.mobileNumber}} </td>
    </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="ntpServer">
        <th mat-header-cell *matHeaderCellDef> NTP სერვერი</th>
        <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.ntpServer}} </td>
      </ng-container>
       <!-- Symbol Column -->
       <ng-container matColumnDef="secondaryIp">
        <th mat-header-cell *matHeaderCellDef> სათარიგო IP/PORT</th>
        <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.secondaryIp}} </td>
      </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="programVersion">
          <th mat-header-cell *matHeaderCellDef> პროგრამის ვერსია</th>
          <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.programVersion}} </td>
        </ng-container>

            <!-- Symbol Column -->
       <ng-container matColumnDef="voltageTrashHold">
        <th mat-header-cell *matHeaderCellDef> U_TR</th>
        <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.voltageTrashHold}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="gsmTrashHold">
        <th mat-header-cell *matHeaderCellDef>GSM_TR</th>
        <td mat-cell *matCellDef="let startPingMessage"> {{startPingMessage.gsmTrashHold}} </td>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      </ng-container>


    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
</div>