import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-device-calibration',
  templateUrl: './device-calibration.component.html',
  styleUrls: ['./device-calibration.component.scss'],
})
export class DeviceCalibrationComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'command',
    'returnedCoef',
    'secondCommand',
    'secondCoef',
    'date',
  ];
  dataSource = ELEMENT_DATA;
  imeiRes = {};
  pingMessage: any;
  deviceLog: {};
  deviceFullLog: {};
  toppingList: string[];
  anotherArray: string[];
  selectedDevice;
  toppings = new FormControl();
  userDetails: Object;
  userId: any;
  imei: any;
  interval: number;
  commandTarget: string;
  isDisabled: boolean;
  constructor(private service: UserService) {}

  ngOnInit(): void {
    this.getCalibrationData();

    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getPingMessage(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );

    this.interval = setInterval(() => {
      this.getCalibrationData();
    }, 3000);
  }
  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  getCalibrationData() {
    this.service.getCalibrationData().subscribe(
      (res) => {
        for (var i = 0; i < Object.keys(res).length; i++) {
          this.imeiRes[res[i].imei] = res[i];
        }
        this.onDeviceSelect(this.imei);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x + ';' + 'fromAdmin').subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        this.deviceFullLog = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          if (
            !(
              this.pingMessage[i].name +
                '__ ' +
                this.pingMessage[i].seriulI_NO in
              this.deviceLog
            )
          ) {
            this.deviceLog[
              this.pingMessage[i].name + '__ ' + this.pingMessage[i].seriulI_NO
            ] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[
              this.pingMessage[i].name + '__ ' + this.pingMessage[i].seriulI_NO
            ] = this.pingMessage[i].imei;
          }
          this.deviceFullLog[this.pingMessage[i].imei] = this.pingMessage[i];
        }
        this.toppingList = Object.keys(this.deviceLog);
        this.anotherArray = Object.keys(this.deviceLog);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  onDeviceSelect(x) {
    this.imei = this.deviceLog[x];
    if (this.imei != 'undefined' && this.imei != undefined) {
      localStorage.setItem('selectedImeiCalibration', this.imei);
    }
    this.imei = localStorage.getItem('selectedImeiCalibration');

    if (this.imei != 'undefined' && this.imei != undefined) {
      ELEMENT_DATA[0].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['dzabvaA']
      );
      ELEMENT_DATA[0].result =
        '   UA:  ' + this.imeiRes[this.imei]['dzabvisDayA'];
      ELEMENT_DATA[0].date = this.imeiRes[this.imei]['dateTime'].toLowerCase();

      ELEMENT_DATA[1].returnedCoef = Number.parseFloat(
        this.imeiRes[this.imei]['dzabvaB']
      );
      ELEMENT_DATA[1].result =
        '   UB:  ' + this.imeiRes[this.imei]['dzabvisDayB'];

      ELEMENT_DATA[2].returnedCoef = Number.parseFloat(
        this.imeiRes[this.imei]['dzabvaC']
      );
      ELEMENT_DATA[2].result =
        '   UC:  ' + this.imeiRes[this.imei]['dzabvisDayC'];

      ELEMENT_DATA[3].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['deniOfsetA']
      );
      ELEMENT_DATA[3].result =
        '   IA:  ' + this.imeiRes[this.imei]['dayenebaOfsetA'];

      ELEMENT_DATA[4].returnedCoef = Number.parseFloat(
        this.imeiRes[this.imei]['deniOfsetB']
      );
      ELEMENT_DATA[4].result =
        '   IB:  ' + this.imeiRes[this.imei]['dayenebaOfsetB'];

      ELEMENT_DATA[5].returnedCoef = Number.parseFloat(
        this.imeiRes[this.imei]['deniOfsetC']
      );
      ELEMENT_DATA[5].result =
        '   IC:  ' + this.imeiRes[this.imei]['dayenebaOfsetC'];

      ELEMENT_DATA[6].returnedCoef = Number.parseFloat(
        this.imeiRes[this.imei]['deniAdisplay']
      );
      // ELEMENT_DATA[6].result = '  I1_coef:  ' + this.imeiRes[this.imei]["dayenebaDisplayA"];

      ELEMENT_DATA[7].returnedCoef = Number.parseFloat(
        this.imeiRes[this.imei]['deniBdisplay']
      );
      // ELEMENT_DATA[7].result = '  I2_coef:  ' + this.imeiRes[this.imei]["dayenebaDisplayB"];

      ELEMENT_DATA[8].returnedCoef = Number.parseFloat(
        this.imeiRes[this.imei]['deniCdisplay']
      );
      // ELEMENT_DATA[8].result = '  I3_coef:  ' + this.imeiRes[this.imei]["dayenebaDisplayC"];

      ELEMENT_DATA[9].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['actSimdzA']
      );
      ELEMENT_DATA[9].result =
        '   PA:  ' + this.imeiRes[this.imei]['dayenebaActSimdzlavreA'];

      ELEMENT_DATA[10].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['actSimdzB']
      );
      ELEMENT_DATA[10].result =
        '   PB:  ' + this.imeiRes[this.imei]['dayenebaActSimdzlavreB'];

      ELEMENT_DATA[11].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['actSimdzC']
      );
      ELEMENT_DATA[11].result =
        '   PC:  ' + this.imeiRes[this.imei]['dayenebaActSimdzlavreC'];

      ELEMENT_DATA[12].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['sruliSimdzA']
      );
      ELEMENT_DATA[12].result =
        '   SA:  ' + this.imeiRes[this.imei]['dayenebaSruliSimdzA'];

      ELEMENT_DATA[13].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['sruliSimdzB']
      );
      ELEMENT_DATA[13].result =
        '   SB:  ' + this.imeiRes[this.imei]['dayenebaSruliSimdzB'];

      ELEMENT_DATA[14].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['sruliSimdzC']
      );
      ELEMENT_DATA[14].result =
        '   SC:  ' + this.imeiRes[this.imei]['dayenebaSruliSimdzC'];

      ELEMENT_DATA[15].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['varAgain']
      );
      ELEMENT_DATA[15].result =
        '   varA:  ' + this.imeiRes[this.imei]['dayenebaVarAgain'];

      ELEMENT_DATA[16].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['varBgain']
      );
      ELEMENT_DATA[16].result =
        '   varB:  ' + this.imeiRes[this.imei]['dayenebaVarBgain'];

      ELEMENT_DATA[17].returnedCoef = Number.parseInt(
        this.imeiRes[this.imei]['varCgain']
      );
      ELEMENT_DATA[17].result =
        '   varC:  ' + this.imeiRes[this.imei]['dayenebaVarCgain'];
    }

    this.dataSource = ELEMENT_DATA;
  }

  filterListCareUnit(val) {
    this.toppingList = this.anotherArray.filter(
      (unit) => unit.indexOf(val) > -1
    );
    console.log(this.toppingList);
  }

  sendCommands(command, source?) {
    if (source == 'Second') {
      var val = (document.getElementById(command) as HTMLInputElement).value;
      if (command == 'ძაბვის დაყენება A') {
        command = 'set_V_gain_A:' + val;
      }
      if (command == 'ძაბვის დაყენება B') {
        command = 'set_V_gain_B:' + val;
      }

      if (command == 'ძაბვის დაყენება C') {
        command = 'set_V_gain_C:' + val;
      }

      if (command == 'ოფსეტი დაყენება A') {
        command = 'set_I_ofs_A:' + val;
      }

      if (command == 'ოფსეტი დაყენება B') {
        command = 'set_I_ofs_B:' + val;
      }

      if (command == 'ოფსეტი დაყენება C') {
        command = 'set_I_ofs_C:' + val;
      }
      if (command == 'დისპლეი დაყენება A') {
        command = 'DEV_I_DISP_COEFF_A:' + val;
      }
      if (command == 'დისპლეი დაყენება B') {
        command = 'DEV_I_DISP_COEFF_B:' + val;
      }
      if (command == 'დისპლეი დაყენება C') {
        command = 'DEV_I_DISP_COEFF_C:' + val;
      }

      if (command == 'აქტიური დაყენება A') {
        command = 'set_W_gain_A:' + val;
      }

      if (command == 'აქტიური დაყენება B') {
        command = 'set_W_gain_B:' + val;
      }

      if (command == 'აქტიური დაყენება C') {
        command = 'set_W_gain_C:' + val;
      }

      if (command == 'სრული დაყენება A') {
        command = 'set_VA_gain_A:' + val;
      }

      if (command == 'სრული დაყენება B') {
        command = 'set_VA_gain_B:' + val;
      }

      if (command == 'სრული დაყენება C') {
        command = 'set_VA_gain_C:' + val;
      }

      if (command == 'Set Var-a gain') {
        command = 'set_VAR_gain_A:' + val;
      }

      if (command == 'Set Var-b gain') {
        command = 'set_VAR_gain_B:' + val;
      }
      if (command == 'Set Var-c gain') {
        command = 'set_VAR_gain_C:' + val;
      }
      console.log(command);
    } else {
      if (command == 'U_A_gain') {
        command = 'ret_V_gain_A';
      }
      if (command == 'U_B_gain') {
        command = 'ret_V_gain_B';
      }

      if (command == 'U_C_gain') {
        command = 'ret_V_gain_C';
      }

      if (command == 'დენი A ოფსეტი') {
        command = 'ret_I_ofs_A';
      }

      if (command == 'დენი B ოფსეტი') {
        command = 'ret_I_ofs_B';
      }

      if (command == 'დენი C ოფსეტი') {
        command = 'ret_I_ofs_C';
      }
      if (command == 'I_A_gain') {
        command = 'ret_I_DISP_COEFF_A';
      }
      if (command == 'I_B_gain') {
        command = 'ret_I_DISP_COEFF_B';
      }
      if (command == 'I_C_gain') {
        command = 'ret_I_DISP_COEFF_C';
      }

      if (command == 'აქტიური სიმძლავრე A') {
        command = 'ret_W_gain_A';
      }

      if (command == 'აქტიური სიმძლავრე B') {
        command = 'ret_W_gain_B';
      }

      if (command == 'აქტიური სიმძლავრე C') {
        command = 'ret_W_gain_C';
      }

      if (command == 'სრული სიმძლავრე A') {
        command = 'ret_VA_gain_A';
      }

      if (command == 'სრული სიმძლავრე B') {
        command = 'ret_VA_gain_B';
      }

      if (command == 'სრული სიმძლავრე C') {
        command = 'ret_VA_gain_C';
      }

      if (command == 'Var-a gain') {
        command = 'ret_VAR_gain_A'; //VAR_gain_A:
      }

      if (command == 'Var-b gain') {
        command = 'ret_VAR_gain_B';
      }

      if (command == 'Var-c gain') {
        command = 'ret_VAR_gain_C';
      }
    }
    this.service.deviceClibrCommands(this.imei, command).subscribe(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
  }

  deviceCommands(command: string) {
    var unix = Math.round(+new Date() / 1000).toString(16);

    if (command.includes('&&')) {
      command = '+cmd_' + command.split('&&')[1];
      unix = Math.round(+new Date() / 1000).toString(16);
      console.log('like a john dee');
      console.log(command);
      console.log('like a john dee');
    }
    command = command.replace('_', '_' + unix.toLocaleUpperCase() + '_');

    // if (this.subscriptions) {
    //   console.log(this.subscriptions);
    //   this.subscriptions.unsubscribe();
    // }

    this.service.deviceCommands(command, this.imei).subscribe(
      (res) => {},
      (err) => {}
    );
  }
}

export interface PeriodicElement {
  command: string;
  returnedCoef: number;
  secondCommand: string;
  secondCoef: number;
  result: string;
  date: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    returnedCoef: 0,
    command: 'U_A_gain',
    secondCommand: 'ძაბვის დაყენება A',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'U_B_gain',
    secondCommand: 'ძაბვის დაყენება B',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'U_C_gain',
    secondCommand: 'ძაბვის დაყენება C',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'დენი A ოფსეტი',
    secondCommand: 'ოფსეტი დაყენება A',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'დენი B ოფსეტი',
    secondCommand: 'ოფსეტი დაყენება B',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'დენი C ოფსეტი',
    secondCommand: 'ოფსეტი დაყენება C',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'I_A_gain',
    secondCommand: 'დისპლეი დაყენება A',
    secondCoef: 0,
    result: '',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'I_B_gain',
    secondCommand: 'დისპლეი დაყენება B',
    secondCoef: 0,
    result: '',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'I_C_gain',
    secondCommand: 'დისპლეი დაყენება C',
    secondCoef: 0,
    result: '',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'აქტიური სიმძლავრე A',
    secondCommand: 'აქტიური დაყენება A',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'აქტიური სიმძლავრე B',
    secondCommand: 'აქტიური დაყენება B',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'აქტიური სიმძლავრე C',
    secondCommand: 'აქტიური დაყენება C',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'სრული სიმძლავრე A',
    secondCommand: 'სრული დაყენება A',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'სრული სიმძლავრე B',
    secondCommand: 'სრული დაყენება B',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'სრული სიმძლავრე C',
    secondCommand: 'სრული დაყენება C',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'Var-a gain',
    secondCommand: 'Set Var-a gain',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'Var-b gain',
    secondCommand: 'Set Var-b gain',
    secondCoef: 0,
    result: '0',
    date: '',
  },
  {
    returnedCoef: 0,
    command: 'Var-c gain',
    secondCommand: 'Set Var-c gain',
    secondCoef: 0,
    result: '0',
    date: '',
  },
];
