import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareDataService {
  private approvalStageMessage = new BehaviorSubject(
    'Basic Approval is required!'
  );

  //  დევაისების იმმების სიის სტეიტი
  public deviceList = [];
  public sendingCommandsIsLoading = false;
  public deviceIndex: boolean[] = [];
  public isCheckedMultiple: boolean = false;
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();
  currentApprovalMessageCommandTarget: any;

  private approvalStageMessage2 = new BehaviorSubject(
    'Basic Approval is required!'
  );
  currentApprovalStageMessage2 = this.approvalStageMessage2.asObservable();
  currentApprovalMessageCommandTarget2: any;

  private approvalStageMessage3 = new BehaviorSubject(
    'Basic Approval is required!'
  );
  currentApprovalStageMessage3 = this.approvalStageMessage3.asObservable();
  currentApprovalMessageCommandTarget3: any;

  private approvalStageMessage4 = new BehaviorSubject(
    'Basic Approval is required!'
  );
  currentApprovalStageMessage4 = this.approvalStageMessage4.asObservable();
  currentApprovalMessageCommandTarget4: any;

  private approvalStageMessage5 = new BehaviorSubject('');
  currentApprovalStageMessage5 = this.approvalStageMessage5.asObservable();
  currentApprovalMessageCommandTarget5: any;

  private approvalStageMessage6 = new BehaviorSubject('');
  currentApprovalStageMessage6 = this.approvalStageMessage6.asObservable();
  currentApprovalMessageCommandTarget6: any;

  private approvalStageMessage7 = new BehaviorSubject('');
  currentApprovalStageMessage7 = this.approvalStageMessage7.asObservable();
  currentApprovalMessageCommandTarget7: any;

  private approvalStageMessage8 = new BehaviorSubject('');
  currentApprovalStageMessage8 = this.approvalStageMessage8.asObservable();
  currentApprovalMessageCommandTarget8: any;

  private approvalStageMessage9 = new BehaviorSubject('');
  currentApprovalStageMessage9 = this.approvalStageMessage9.asObservable();
  currentApprovalMessageCommandTarget9: any;

  constructor() {}

  // tslint:disable-next-line:typedef
  mapFocus(value: any) {
    this.approvalStageMessage.next(value);
  }

  // tslint:disable-next-line:typedef
  selectedDataForLimits(deviceId: string, data: string) {
    this.approvalStageMessage2.next(deviceId + ';' + data);
  }

  // tslint:disable-next-line:typedef
  selectExtraData(deviceId: string) {
    this.approvalStageMessage3.next(deviceId);
  }

  // tslint:disable-next-line:typedef
  selectKontactors(deviceId: string, data: string) {
    this.approvalStageMessage4.next(deviceId + ';' + data);
  }

  // tslint:disable-next-line:typedef
  selectedDeviceGraphic(deviceId: string) {
    this.approvalStageMessage5.next(deviceId);
  }

  // tslint:disable-next-line:typedef
  selectedEditDeviceOrder(deviceId: string) {
    this.approvalStageMessage6.next(deviceId);
  }

  // tslint:disable-next-line:typedef
  deviceErrors(errorArray) {
    this.approvalStageMessage7.next(errorArray);
  }

  pingMessage(data) {
    this.approvalStageMessage8.next(data);
  }

  mapFullInfo(data) {
    this.approvalStageMessage9.next(data);
  }
}
