<app-nav></app-nav>
<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="side">
    <ul>
      <li *ngFor="let section of sections"   >
        <a  (click)="toggleSection(section)">
          <h3 [ngClass]="{ 'linkBGcolorBlue': currentSection == section }">{{ section.title }} </h3>
         </a>
      </li>
    </ul>
  </mat-drawer>
  <div class="example-sidenav-content">
    <button type="button" mat-button (click)="drawer.toggle(); toggleFiller()">
      <span *ngIf="showFiller == false" class="material-icons">menu</span>
      <span *ngIf="showFiller" class="material-icons">close</span>
    </button>
    <div *ngIf="currentSection" class="section-content"    [class.sidenav-div-contant]="!deviceGraphic"
    [style.width]="deviceGraphic ? '100%' : ''" >
      <div [ngSwitch]="currentSection?.id">
        <div class="add-Device" *ngSwitchCase="'addDevice'" >
          <app-add-device></app-add-device>
        </div>
        <div class="add-User" *ngSwitchCase="'addUser'">
          <app-registration></app-registration>
        </div>
        <div class="bootDevice" *ngSwitchCase="'bootLoader'">
          <app-file-reader></app-file-reader>
        </div>
        <div class="delete-device" *ngSwitchCase="'deleteDevice'">
          <app-delete-device></app-delete-device>
        </div>
        <div class="edit-device" *ngSwitchCase="'editDevice'">
          <app-edit-device-info></app-edit-device-info>
        </div>
        <div class="reset-userInfo" *ngSwitchCase="'resetUserInfo'">
          <app-edit-user-info></app-edit-user-info>
        </div>
        <div class="shownotif-panel" *ngSwitchCase="'notificationPanel'">
          <app-notification-panel></app-notification-panel>
        </div>
        <div class="device-control" *ngSwitchCase="'deviceToUser'">
          <app-device-to-user></app-device-to-user>
        </div>
        <div class="device-control" *ngSwitchCase="'deviceGraphic'">
          <app-device-ghrapic></app-device-ghrapic>
        </div>
        <div class="device-control" *ngSwitchCase="'deviceOrder'">
          <app-device-order></app-device-order>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>

