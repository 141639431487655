import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Parser } from '@angular/compiler';
import { error } from '@angular/compiler/src/util';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-file-reader',
  templateUrl: './file-reader.component.html',
  styleUrls: ['./file-reader.component.scss'],
})
export class FileReaderComponent implements OnInit, OnDestroy {
  file: any;
  byteArr: ArrayBuffer;
  hexData: string;
  toppings = new FormControl();
  topping: string;
  toppingList: any[] = [];
  selectedDevice: string;
  pingMessage: any;
  deviceLog: {};
  userDetails: any;
  userId: any;
  CommandAnswe: any;
  interval: number;
  // tslint:disable-next-line:ban-types
  bootResult: Object;
  isSent: string;
  start: boolean;
  anotherArray = [];
  progress = 0;
  sendedCommandStatus: boolean;
  constructor(private http: HttpClient, private service: UserService) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getPingMessage(this.userId);
      },
      (err) => {
        console.log(err);
      }
    );
    this.interval = setInterval(() => {
      this.getBootResult();
    }, 3000);
  }
  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  // tslint:disable-next-line:typedef
  fileChanged(e) {
    this.file = e.target.files[0];
  }
  // tslint:disable-next-line:typedef
  uploadDocument(x) {
    // tslint:disable-next-line:prefer-const
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      // tslint:disable-next-line:prefer-const
      this.byteArr = e.target.result as ArrayBuffer;
      // this.hexData =  [...new Uint8Array(this.byteArr)]
      // .map(x => x.toString(16).padStart(2, '0'))
      // .join('');
      const convertedData = new Uint8Array(this.byteArr);

      this.deviceCommands(convertedData, x).subscribe(
        (progress) => {
          this.progress = progress;
          console.log(progress);
        },
        (err) => (this.sendedCommandStatus = false),
        () => (this.sendedCommandStatus = true)
      );
    };

    // tslint:disable-next-line:prefer-const
    let fileRead = fileReader.readAsArrayBuffer(this.file);
  }

  deviceCommands(data, deviceImei: Array<string>): Observable<number> {
    const header = { 'content-type': 'application/json' };
    const progress = new Subject<number>();

    for (var i = 0; i < deviceImei.length; i++) {
      var targetImei = this.deviceLog[deviceImei[i]];
      const body = new FormData();
      body.append(data, '');
      //tslint:disable-next-line:only-arrow-functions
      this.http
        .post<any>(
          ' https://request2.mygps.ge:4490/api/DevicePingMessage/PostBootData',
          JSON.stringify(targetImei + ';' + data, function (k, v) {
            if (v instanceof Uint8Array) {
              return Array.apply([], v);
            }
            return v;
          }),
          { headers: header, reportProgress: true, observe: 'events' }
        )
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round(
                (100 * event.loaded) / event.total
              );
              this.progress = percentDone;
              progress.next(percentDone);
            } else if (event instanceof HttpResponse) {
              this.progress = 100;
              progress.complete();
            }
          },
          (err) => {
            if (err.status == 200) {
              progress.complete();
            } else {
              progress.error(err);
            }
          }
        );
    }

    return progress.asObservable();
  }

  // tslint:disable-next-line:typedef
  getPingMessage(x) {
    this.service.PingMessage(x + ';' + 'fromAdmin').subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.pingMessage = res;
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:prefer-const
        this.deviceLog = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(this.pingMessage).length; i++) {
          if (
            !(
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress in
              this.deviceLog
            )
          ) {
            this.deviceLog[
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress
            ] = this.pingMessage[i].imei;
          } else {
            this.deviceLog[
              this.pingMessage[i].name +
                '__' +
                this.pingMessage[i].stationId +
                ' ' +
                this.pingMessage[i].adress +
                this.pingMessage[i].devieId
            ] = this.pingMessage[i].imei;
          }
        }
        this.toppingList = Object.keys(this.deviceLog);
        this.anotherArray = Object.keys(this.deviceLog);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  sendProgramCmd(x) {
    // const headers = { 'content-type': 'multipart/form-data'};
    const body = new FormData();
    const target = this.deviceLog[x];
    body.append('StartBootLoading' + ';' + target, '');
    this.http
      .post<any>(
        ' https://request2.mygps.ge:4490/api/DevicePingMessage/PostDeviceCommand',
        body
      )
      .subscribe(
        (res) => {
          // tslint:disable-next-line:no-string-literal
          this.CommandAnswe = res;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  // tslint:disable-next-line:typedef
  getBootResult() {
    this.service.getBootResult().subscribe(
      (res) => {
        this.bootResult = res.toString().split(';')[0];
        this.isSent = res.toString().split(';')[1];
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  spinnerCancel(x) {
    const target = this.deviceLog[x];
    console.log(target);
    this.service.cancelSpinner(target).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  filterListCareUnit(val) {
    this.toppingList = this.anotherArray.filter(
      (unit) => unit.indexOf(val) > -1
    );
  }
}
