import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  // let FakeObj = {
  //   dateTime: '2024-01-15T17:20:17.087',
  //   deviceId: 0,
  //   deviceImei: '862095055510355',
  //   evenet: 'შემომავალ ფაზაზე ძაბვის ნორმა დაირღვა',
  //   evenetType: 'Important',
  //   id: 1200,
  //   isRead: 0,
  //   userId: null,
  // }
}
