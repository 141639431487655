import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { DeviceListComponent } from './device/device-list/device-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { DeviceCommandsComponent } from './device/device-commands/device-commands.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/auth.guard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DeviceFullInfoComponent } from './device/device-full-info/device-full-info.component';
import { MatDividerModule } from '@angular/material/divider';
import { RegistrationComponent } from './user/registration/registration.component';
import { LoginComponent } from './user/login/login.component';
import { DefaultComponent } from './default/default.component';
import { UserComponent } from './user/user/user.component';
import { UserService } from './services/user.service';
import { ToastrModule } from 'ngx-toastr';
import { HomeComponent } from './home/home.component';
import { PagesComponent } from './pages/pages.component';
 
import { AuthInterceptor } from './auth/auth.interveptor';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MenuToggleDirective } from './menu-toggle.directive';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { DeviceOtherCommandsComponent } from './device/device-other-commands/device-other-commands.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileReaderComponent } from './device/file-reader/file-reader.component';
import { MapComponent } from './map/map.component';
import { AgmCoreModule } from '@agm/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DeviceConfigurationComponent } from './device/device-configuration/device-configuration.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReportsComponent } from './device/reports/reports.component';
import { OrderByPipe } from './order-by.pipe';
import { DeleteDeviceComponent } from './device/delete-device/delete-device.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { EditDeviceInfoComponent } from './device/edit-device-info/edit-device-info.component';
import { EditUserInfoComponent } from './device/edit-user-info/edit-user-info.component';
import { NotificationPanelComponent } from './device/notification-panel/notification-panel.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { ReplacePipe } from './replace.pipe';
import { DeviceToUserComponent } from './device/device-to-user/device-to-user.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClockComponent } from './clock/clock.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DataLimitsComponent } from './data-limits/data-limits.component';
import { DeviceExtraDataComponent } from './device-extra-data/device-extra-data.component';
import { DeviceKontactorsComponent } from './device-kontactors/device-kontactors.component';
import { DeviceGhrapicComponent } from './device/device-ghrapic/device-ghrapic.component';
import { MatChipsModule } from '@angular/material/chips';
import { AddDeviceComponent } from './device/add-device/add-device.component';
import { DeviceOrderComponent } from './device/device-order/device-order.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AllDeviceInfoComponent } from './device/all-device-info/all-device-info.component';
import { MapFullInfoComponent } from './device/map-full-info/map-full-info.component';
import { RemovePipe } from './Pipes/remove-data';
import { MatListModule } from '@angular/material/list';
import { DeviceCalibrationComponent } from './device-calibration/device-calibration.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AudiodialogComponent } from './reusable-components/audiodialog/audiodialog.component';
const routes: Routes = [
  { path: '', redirectTo: '/user/login', pathMatch: 'full' },
  {
    path: 'user',
    component: UserComponent,
    children: [
      { path: 'registration', component: RegistrationComponent },
      { path: 'login', component: LoginComponent },
    ],
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'forbidden', component: ForbiddenComponent },

  {
    path: 'adminpanel',
    component: AdminPanelComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['Admin'] },
  }, // marto admins rom sheedzlos aq shesvla
  { path: 'otherCommands', component: DeviceOtherCommandsComponent },
  { path: 'map', component: MapComponent },
  { path: 'report', component: ReportsComponent },
];
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    DeviceListComponent,
    DeviceCommandsComponent,
    DeviceFullInfoComponent,
    RegistrationComponent,
    LoginComponent,
    DefaultComponent,
    UserComponent,
    HomeComponent,
    PagesComponent,
    AdminPanelComponent,
    ForbiddenComponent,
    MenuToggleDirective,
    DeviceOtherCommandsComponent,
    FileReaderComponent,
    MapComponent,
    DeviceConfigurationComponent,
    ReportsComponent,
    OrderByPipe,
    OrderByPipe,
    DeleteDeviceComponent,
    EditDeviceInfoComponent,
    EditUserInfoComponent,
    NotificationPanelComponent,
    ReplacePipe,
    DeviceToUserComponent,
    SearchBarComponent,
    ClockComponent,
    DataLimitsComponent,
    DeviceExtraDataComponent,
    DeviceKontactorsComponent,
    DeviceGhrapicComponent,
    AddDeviceComponent,
    DeviceOrderComponent,
    AllDeviceInfoComponent,
    MapFullInfoComponent,
    RemovePipe,
    DeviceCalibrationComponent,
    AudiodialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    RouterModule,
    HttpClientModule,
    MatSidenavModule,
    CommonModule,
    ToastrModule.forRoot({
      progressBar: true,
      positionClass: 'toast-bottom-right',
    }),
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    MatSelectModule,
    MatTabsModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBiNzOT0oypzu-Izc2mBwWrV3j7CepjODU',
    }),
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    FilterPipeModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatBadgeModule,
    DragDropModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatPaginatorModule,
    MatListModule,
    MatExpansionModule,
  ],
  providers: [
    RemovePipe,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
