<div *ngIf="allowComandPanel == '1'">
  <app-device-commands
    [isDisabled]="false"
    [isDisabled]="false"
    [commandTarget]="this.commandTarget"
    [allowTurnOnOff]="allowTurnOnOff"
    [allowGraphic]="allowGraphic"
    [selectionStatus]="selectionStatus"
    [deviceArr]="this.deviceLog"
  ></app-device-commands>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-12 mb-3 d-flex justify-content-between d-sm-none">
      <span style="color: #4267b2; cursor: pointer" (click)="devicesFullInfo()">
        <mat-icon>table_chart</mat-icon>
      </span>
      <app-search-bar (newItemEvent)="addNewItem($event)"></app-search-bar>
    </div>
    <div class="col-md-9 col-lg-10 col-12" id="device-list-container">
      <div style="display: flex; justify-content: space-between">
        <div
          class="p-1"
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: row;
          "
        >
          <select
            class="form-control form-control-sm mb-3 mr-3"
            (change)="pageChange($event.target.value)"
            [(ngModel)]="selectedPage"
          >
            <option style="background-color: #4267b2; color: white">
              მონიტორი {{ selectedBox }}
            </option>
            <option
              *ngFor="let name of jsonData; let i = index"
              [style.display]="i == selectedBox ? 'none' : 'block'"
              [value]="i"
            >
              მონიტორი {{ i }}
            </option>
          </select>
          <mat-checkbox
            [disabled]="!this.selectMultiple"
            (change)="setAll($event.checked)"
            [(ngModel)]="shareDataService.isCheckedMultiple"
            #myCheckbox
          >
            ყველა
          </mat-checkbox>

          <mat-slide-toggle
            class="example-margin ml-3 d-flex"
            (click)="toogleSlider(); changeSelectAllState()"
          ></mat-slide-toggle>
          <mat-spinner
            style="position: absolute; z-index: 9000; left: 45%"
            *ngIf="shareDataService.sendingCommandsIsLoading"
          ></mat-spinner>
        </div>
        <div class="p-1" style="cursor: pointer; color: #385898">
          <button
            class="btn btn-success mr-3 btn-sm commandsForSend"
            style="min-width: 50px"
            (click)="showNotSendedCommands = !showNotSendedCommands"
          >
            {{ notSended?.length }}
          </button>
          <mat-icon (click)="openBottomSheet()">edit</mat-icon>
        </div>
      </div>

      <div
        style="
          width: 30%;
          position: absolute;
          z-index: 3;
          overflow-y: auto;
          height: 60%;
          float: right;
        "
        *ngIf="showNotSendedCommands"
      >
        <mat-accordion
          style="width: 100%; background-color: #385898; color: white"
        >
          <mat-expansion-panel
            [expanded]="true"
            style="background-color: #385898 !important; color: white"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                style="
                  width: 100%;
                  min-height: 60%;
                  background-color: #385898;
                  color: white;
                "
              >
                გაუგზავნელი ბრძანებები:
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              style="
                min-height: 60%;
                background-color: #385898;
                color: white;
                font-size: 20px;
              "
              *ngFor="let x of notSended"
            >
              {{ x }}
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!-- აქამადე მივედით  -->
      <!--  უნდა დავადგინოთ საიდან მოდის დევაის ლოგები  -->
      <div
        id="device-panel"
        *ngFor="
          let x of deviceLog
            | orderBy : 'x.value.position'
            | filterBy : userFilter
            | keyvalue : indexOrderAsc
            | slice : startIndex : endIndex;
          let i = index
        "
      >
        <section class="devices-list" id="{{ x.value.imei }}">
          <div>
            <ul>
              <li>
                <section
                  [id]="x.value.name"
                  [formGroup]="toppings"
                  [style.background-color]="getColor(x)"
                >
                  <!-- 36 საფრთხის შემცველი ძაბვაა მაგიტო აყენია უკუძაბვაზე -->
                  <p [style.backg] *ngIf="!selectMultiple">
                    <mat-checkbox
                      [checked]="x.value.userId && selected === i"
                      (change)="checked(x.value.imei, $event, i); selected = i"
                    >
                      <div
                        style="line-height: 1"
                        class="d-flex flex-column p-0 line-he"
                      >
                        <span>
                          {{ x.value.name }}
                        </span>
                        <span style="font-size: 10px; color: darkblue">
                          SN {{ x.value.seriulI_NO }}
                        </span>
                      </div>

                      <!-- <span style="display:flex;">გაჩერების ID: {{ x.value.stationId}} </span><span style="display:flex;">SerN: {{x.value.serialNumber}}
                      </span> -->
                    </mat-checkbox>
                  </p>

                  <p *ngIf="selectMultiple">
                    <mat-checkbox
                      [checked]="
                        x.value.userId || shareDataService.deviceIndex[i]
                      "
                      (change)="checked(x.value.imei, $event, i)"
                    >
                      <div
                        style="line-height: 1"
                        class="d-flex flex-column p-0 line-he"
                      >
                        <span>
                          {{ x.value.name }}
                        </span>
                        <span style="font-size: 10px; color: darkblue">
                          SN {{ x.value.seriulI_NO }}
                        </span>
                      </div>

                      <!-- <span style="display:flex;">გაჩერების ID: {{ x.value.stationId}} </span><span style="display:flex;">SerN: {{x.value.serialNumber}}
                      </span> -->
                    </mat-checkbox>
                  </p>
                  <div class="section-icons">
                    <div
                      *ngIf="
                        getDateDiff(x.value.deviceDateTime, x.value.interval) ==
                        false
                      "
                    >
                      <span
                        *ngIf="x.value.managementMode == 1"
                        _ngcontent-c20=""
                        class="material-icons ng-star-inserted"
                        style="color: forestgreen"
                        title="ჩართულია გრაფიკით"
                      >
                        watch_later
                      </span>

                      <span
                        *ngIf="x.value.managementMode == 0"
                        _ngcontent-c20=""
                        class="material-icons ng-star-inserted"
                        style="color: forestgreen"
                        title="გამორთულია გრაფიკით"
                      >
                        watch_later
                      </span>
                      <span
                        *ngIf="x.value.managementMode == 2"
                        _ngcontent-c20=""
                        class="material-icons ng-star-inserted"
                        style="color: red"
                        title="გამორთულია ხელით"
                      >
                        watch_later
                      </span>
                      <span
                        *ngIf="x.value.managementMode == 3"
                        _ngcontent-c20=""
                        class="material-icons ng-star-inserted"
                        style="color: red"
                        title="ჩართულია ხელით"
                      >
                        watch_later
                      </span>
                      <span
                        *ngIf="x.value.managementMode == 4"
                        _ngcontent-c20=""
                        class="material-icons ng-star-inserted"
                        style="color: red"
                        title="გამორთულია იუზერის გრაფიკით"
                      >
                        watch_later
                      </span>
                      <span
                        *ngIf="x.value.managementMode == 5"
                        _ngcontent-c20=""
                        class="material-icons ng-star-inserted"
                        style="color: red"
                        title="ჩართულია იუზერის გრაფიკით"
                      >
                        watch_later
                      </span>
                    </div>
                    <div
                      *ngIf="
                        getDateDiff(x.value.deviceDateTime, x.value.interval) ==
                        true
                      "
                    >
                      <span
                        _ngcontent-c20=""
                        class="material-icons ng-star-inserted"
                        style="color: red"
                        title="არ არის კავშირზე"
                      >
                        warning
                      </span>
                    </div>
                    <div>
                      <span
                        _ngcontent-c20=""
                        id="{{ x.value.name }}"
                        class="material-icons ng-map"
                        style="color: #385898; cursor: pointer"
                        (click)="clickedMap($event); hideMap = true"
                        title="რუკა"
                      >
                        info
                      </span>
                    </div>
                    <!-- <div >
                          <span *ngIf="getDateDiff(x.value.deviceDateTime,x.value.interval) == true" _ngcontent-c20="" class="material-icons ng-star-inserted" style="color: red" title="გარე განათება გამორთულია">warning</span>
                        </div> -->
                    <!-- <div>
                          <span>
                            <img src="../../../assets/img/menu-svgrepo-com (2).svg" alt=""  style="width:20px;margin-top:2px;cursor: pointer;" (click)="openMenu(x.value.deviceId)"></span>
                        </div> -->
                  </div>
                </section>
                <!-- <div class="helpCommands" *ngIf="x.value.adress == 'true'">
                      <mat-checkbox>K1</mat-checkbox>
                      <mat-checkbox>K2</mat-checkbox>
                    </div> -->
              </li>
            </ul>
          </div>
        </section>
        <div style="margin: 15px"></div>
      </div>
      <!-- <nav aria-label="Page navigation example" style="display:flex;width: 100%;overflow: scroll !important;">
        <ul class="pagination" *ngFor="let x of jsonData; let i = index">
          <li class="page-item"><a class="page-link" (click)="pageChange(i)">{{i}}</a></li>
        </ul>
      </nav> -->
    </div>
    <div class="col-md-3 col-lg-2 col-12 d-none d-sm-block">
      <div class="col-md-12 col-12 mb-3 d-flex justify-content-between">
        <span
          style="color: #4267b2; cursor: pointer"
          (click)="devicesFullInfo()"
        >
          <mat-icon>table_chart</mat-icon>
        </span>
        <app-search-bar (newItemEvent)="addNewItem($event)"></app-search-bar>
      </div>
      <div
        class="col-md-12 col-12 d-none d-sm-block"
        id="error-message-container"
      >
        <h4 class="text-center p-0">დაზიანებები</h4>
        <mat-divider class="mb-1 mt-1 p-0"></mat-divider>
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-sm p-0"
            [ngClass]="isCleanLoading ? 'btn-success' : 'btn-danger'"
            (click)="deleteErrorMessage('all')"
          >
            <mat-spinner
              *ngIf="isCleanLoading"
              class="loading-spinner"
            ></mat-spinner>

            <span style="color: white; font-size: 12px" *ngIf="!isCleanLoading"
              >გასუფთავება</span
            >
            <span style="color: white; font-size: 12px" *ngIf="isCleanLoading"
              >სუფთავდება...</span
            >
          </button>
          <button
            class="btn btn-sm p-0"
            style="background-color: rgb(0, 102, 255)"
            (click)="cleanCash()"
          >
            <span style="color: white; font-size: 12px" *ngIf="!isCleanLoading"
              >ქეშის გასუფთავება</span
            >
          </button>

          <div>
            <mat-icon
              *ngIf="isAudioOpen"
              (click)="AudioCheck('stop')"
              class="audioStarter"
            >
              {{ volume }}
            </mat-icon>
            <mat-icon
              (click)="AudioCheck('start')"
              class="audioClosed"
              *ngIf="!isAudioOpen"
            >
              volume_off
            </mat-icon>
            <mat-icon
              class="text-success"
              id="reset-sounds"
              style="cursor: pointer"
              (click)="resetDisableSounds()"
            >
              refresh
            </mat-icon>
          </div>
        </div>

        <mat-divider class="mb-1 mt-1 p-0"></mat-divider>
        <div class="error-message">
          <div *ngFor="let device of deviceErrors; let i = index">
            <div id="error-info" *ngIf="device != 0">
              <!-- <mat-icon id="error-icon">error</mat-icon> -->
              <span>{{ device }}</span>
              <!-- <span style="margin-left: 10px;"> 2022-05-24 06:59:30.317</span> -->
              <span>
                <mat-icon
                  [id]="device | remove"
                  class="cancel"
                  (click)="disableVolume(device)"
                >
                  {{ volume }}
                </mat-icon>
              </span>
            </div>
            <mat-divider class="mb-1 mt-1"></mat-divider>
          </div>
        </div>

        <!-- <div class="col-md-12 message" style="padding: 10px;">
             <h4>
             მესიჯები
             </h4>
             <mat-divider></mat-divider>
             <div  *ngFor="let device of EventHistory" >
               <div >
                 <span *ngIf="device.evenet.includes('ჩაირთო')"> <mat-icon  style="color:green;">emoji_objects</mat-icon>{{device.evenet}}</span>
                 <span *ngIf="device.evenet.includes('გამოირთო')"> <mat-icon  style="color:rgb(156, 154, 154);">emoji_objects</mat-icon>{{device.evenet}}</span>
                 <span style="margin-left: 10px;"> {{device.dateTime | replace : '[T]' : '  '}}</span>
                 <span style="position: relative;top: 5px;">
                   <mat-icon style="display:flex;  float:right !important;color:rgb(173, 173, 173); cursor: pointer;" class="cancel">cancel</mat-icon>
                  </span>
               </div>
               <div style="border: 1px solid rgb(189, 189, 189); ">
  
               </div>
           </div>
         </div> -->
      </div>
    </div>

    <div class="col-md-12 col-12" id="device-full-info-container">
      <app-device-full-info
        [showInfoTable]="this.isChecked"
        [selectedDeviceId]="selectedDeviceId"
      ></app-device-full-info>
    </div>
    <div class="col-12 d-block d-sm-none" id="error-message-container">
      <h4 class="text-center">დაზიანებები</h4>
      <mat-divider class="mb-3 mt-3"></mat-divider>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-danger btn-sm p-0"
          (click)="deleteErrorMessage('all')"
        >
          გასუფთავება
        </button>
        <mat-icon
          class="text-success"
          id="reset-sounds"
          style="cursor: pointer"
          (click)="resetDisableSounds()"
        >
          refresh
        </mat-icon>
      </div>
      <mat-divider class="mb-1 mt-1"></mat-divider>
      <div class="error-message">
        <div *ngFor="let device of deviceErrors; let i = index">
          <div id="error-info">
            <mat-icon id="error-icon">error</mat-icon>
            <span>{{ device }} </span>
            <!-- <span style="margin-left: 10px;"> 2022-05-24 06:59:30.317</span> -->
            <span>
              <mat-icon
                [id]="device | remove"
                class="cancel"
                (click)="disableVolume(device)"
              >
                {{ volume }}
              </mat-icon>
            </span>
          </div>
          <mat-divider class="mb-1 mt-1"></mat-divider>
        </div>
      </div>
      <!-- <div class="col-md-12 message" style="padding: 10px;">
           <h4>
           მესიჯები
           </h4>
           <mat-divider></mat-divider>
           <div  *ngFor="let device of EventHistory" >
             <div >
               <span *ngIf="device.evenet.includes('ჩაირთო')"> <mat-icon  style="color:green;">emoji_objects</mat-icon>{{device.evenet}}</span>
               <span *ngIf="device.evenet.includes('გამოირთო')"> <mat-icon  style="color:rgb(156, 154, 154);">emoji_objects</mat-icon>{{device.evenet}}</span>
               <span style="margin-left: 10px;"> {{device.dateTime | replace : '[T]' : '  '}}</span>
               <span style="position: relative;top: 5px;">
                 <mat-icon style="display:flex;  float:right !important;color:rgb(173, 173, 173); cursor: pointer;" class="cancel">cancel</mat-icon>
                </span>
             </div>
             <div style="border: 1px solid rgb(189, 189, 189); ">

             </div>
         </div>
       </div> -->
    </div>
    <div
      class="hide-map example-box"
      id="mini-map-container"
      *ngIf="hideMap"
      cdkDragBoundary=".example-boundary"
      cdkDrag
    >
      <div>
        <mat-icon
          (click)="hideMap = !hideMap"
          style="color: red; cursor: pointer"
        >
          cancel
        </mat-icon>
      </div>
      <app-map
        [selectedDevice]="selectedDevice"
        style="width: 100%; height: 100%"
      ></app-map>
    </div>
  </div>
</div>
