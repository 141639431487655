import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ShareDataService } from 'src/app/services/share-data.service';
import { UserService } from 'src/app/services/user.service';
import { DeviceGhrapicComponent } from '../device-ghrapic/device-ghrapic.component';

@Component({
  selector: 'app-device-commands',
  templateUrl: './device-commands.component.html',
  styleUrls: ['./device-commands.component.scss'],
})
export class DeviceCommandsComponent implements OnInit, OnDestroy {
  @Input() isDisabled = false;
  @Input() commandTarget: string;
  @Input() allowTurnOnOff: string;
  @Input() allowGraphic: string;
  @Input() selectionStatus: string;
  @Input() deviceArr: any;
  deviceControl = new FormControl();
  deviceCommand?: string;
  CommandAnswe: any;
  setInterval: any;
  setNtp: any;
  openIntervalDiv: boolean;
  openNtp: boolean;
  startedMonitoring: string;
  interval: number;
  showKontactors: boolean;
  showKontactors1: boolean;
  showKontactors2: boolean;
  showKontactors3: boolean;
  subscribe: any;

  private readonly subscriptions = new Subscription();
  constructor(
    private http: HttpClient,
    private service: UserService,
    private toastr: ToastrService,
    private _bottomSheet: MatBottomSheet,
    private shareDataService: ShareDataService
  ) {
    this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
  }

  ngOnInit(): void {}

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  // tslint:disable-next-line:typedef

  cleanCheckedCommands() {
    this.shareDataService.sendingCommandsIsLoading = false;
    this.shareDataService.deviceList = [];
    this.shareDataService.deviceIndex = new Array(
      this.shareDataService.deviceList.length
    ).fill(false);
    this.shareDataService.isCheckedMultiple = false;

    // this.shareDataService.devicein
  }

  async sendMultipleCommands(command: string, event: Event) {
    this.shareDataService.sendingCommandsIsLoading = true;
    return new Promise<void>((resolve, reject) => {
      if (this.selectionStatus == 'multiple') {
        this.commandTarget = '';
      }
      if (
        this.selectionStatus == 'multiple' &&
        command.includes('cmd_kontactors ')
      ) {
        var splitCommand = command.split(' ');
        if (splitCommand[1][0] == '0') {
          command = command.replace('0333', '0000');
        }
        if (splitCommand[1][0] == '1') {
          command = command.replace('1333', '1111');
        }
      }
      var unix = Math.round(+new Date() / 1000).toString(16);

      command = command.replace('_', '_' + unix.toLocaleUpperCase() + '_');
      if (this.commandTarget === '' || this.commandTarget === 'undefined') {
        const localStorageKeys = Object.keys(localStorage);

        localStorageKeys.forEach((x) => {
          if (localStorage.getItem(x) === 'true') {
            this.commandTarget += x + ';';
          }
        });
      }

      const commandList = this.commandTarget.split(';');
      const uniq = [...new Set(commandList)];

      if (this.isDisabled === false) {
        var id =
          ((event.srcElement as HTMLElement).parentNode as HTMLElement).id == ''
            ? (event.srcElement as HTMLElement).id
            : ((event.srcElement as HTMLElement).parentNode as HTMLElement).id;

        if (event !== undefined && id !== undefined) {
          if (id) {
            var clickedButton = document.getElementById(
              id
            ) as HTMLButtonElement;

            clickedButton.disabled = true;
          }
        }

        if (this.shareDataService.deviceList.length > 1) {
          this.service
            .deviceCommandsList(command, this.shareDataService.deviceList)
            .subscribe(
              (res) => {
                this.shareDataService.sendingCommandsIsLoading = false;
                this.cleanCheckedCommands();
                if (clickedButton !== undefined) {
                  clickedButton.disabled = false;
                }

                this.toastr.success(' ბრძანება გაიგზავნა');

                uniq.length = 0;
              },
              (error) => {
                if (clickedButton !== undefined) {
                  clickedButton.disabled = false;
                }
                this.shareDataService.sendingCommandsIsLoading = false;
                this.cleanCheckedCommands();

                this.toastr.error(' ბრძანება არ გაიგზავნა');

                uniq.length = 0;
              }
            );
        } else {
          uniq.forEach(async (x) => {
            if (x !== 'undefined' && x !== '') {
              try {
                const res = await this.service
                  .deviceCommands(command, x)
                  .subscribe(
                    (res) => {
                      // Success callback
                      this.toastr.success(
                        this.deviceArr[x].name + ' ბრძანება გაიგზავნა'
                      );
                    },
                    (error) => {
                      this.shareDataService.sendingCommandsIsLoading = false;

                      // Error callback
                      this.toastr.error('An error occurred: ' + error.message);
                    },
                    () => {
                      // Complete callback (optional)
                      console.log('Command execution completed.');
                    }
                  );
                // tslint:disable-next-line:no-string-literal
                this.CommandAnswe = res;
                if (clickedButton !== undefined) {
                  clickedButton.disabled = false;
                } else {
                  this.toastr.success(
                    this.deviceArr[x].name + ' ბრძანება გაიგზავნა'
                  );
                }
                resolve();
              } catch (err) {
                this.shareDataService.sendingCommandsIsLoading = false;

                console.log(err);
                if (clickedButton !== undefined) {
                  clickedButton.disabled = false;
                }
                if (err) {
                  this.shareDataService.sendingCommandsIsLoading = false;

                  this.toastr.error(
                    this.deviceArr[x].name + ' ბრძანება არ გაიგზავნა'
                  );
                }
                reject(err);
              }
            }
          });
        }
      }
    });
  }

  async deviceCommands(command: string, event?): Promise<void> {
    this.shareDataService.sendingCommandsIsLoading = true;

    if (this.shareDataService.deviceList.length > 1) {
      await this.sendMultipleCommands(command, event);
    } else {
      this.shareDataService.sendingCommandsIsLoading = true;

      return new Promise<void>((resolve, reject) => {
        if (this.selectionStatus == 'multiple') {
          this.commandTarget = '';
        }
        if (
          this.selectionStatus == 'multiple' &&
          command.includes('cmd_kontactors ')
        ) {
          var splitCommand = command.split(' ');
          if (splitCommand[1][0] == '0') {
            command = command.replace('0333', '0000');
          }
          if (splitCommand[1][0] == '1') {
            command = command.replace('1333', '1111');
          }
        }
        var unix = Math.round(+new Date() / 1000).toString(16);

        command = command.replace('_', '_' + unix.toLocaleUpperCase() + '_');
        if (this.commandTarget === '' || this.commandTarget === 'undefined') {
          const localStorageKeys = Object.keys(localStorage);

          localStorageKeys.forEach((x) => {
            if (localStorage.getItem(x) === 'true') {
              this.commandTarget += x + ';';
            }
          });
        }

        const commandList = this.commandTarget.split(';');
        const uniq = [...new Set(commandList)];

        if (this.isDisabled === false) {
          var id =
            ((event.srcElement as HTMLElement).parentNode as HTMLElement).id ==
            ''
              ? (event.srcElement as HTMLElement).id
              : ((event.srcElement as HTMLElement).parentNode as HTMLElement)
                  .id;
          if (event !== undefined && id !== undefined) {
            if (id) {
              var clickedButton = document.getElementById(
                id
              ) as HTMLButtonElement;

              clickedButton.disabled = true;
            }
          }

          if (this.selectionStatus == 'multiple') {
            var target = '';
            for (var i = 0; i < uniq.length; i++) {
              target += uniq[i] + ';';
            }

            this.service.deviceCommands(command, target).subscribe(
              (res) => {
                this.shareDataService.sendingCommandsIsLoading = false;

                if (clickedButton !== undefined) {
                  clickedButton.disabled = false;
                }

                this.shareDataService.sendingCommandsIsLoading = false;

                this.toastr.success(' ბრძანება გაიგზავნა');

                this.shareDataService.sendingCommandsIsLoading = false;

                uniq.length = 0;
              },
              (error) => {
                this.shareDataService.sendingCommandsIsLoading = false;

                if (clickedButton !== undefined) {
                  clickedButton.disabled = false;
                }
                if (error) {
                  this.shareDataService.sendingCommandsIsLoading = false;
                  this.toastr.error(' ბრძანება არ გაიგზავნა');
                }
                uniq.length = 0;
              }
            );
          } else {
            uniq.forEach(async (x) => {
              if (x !== 'undefined' && x !== '') {
                try {
                  const res = await this.service
                    .deviceCommands(command, x)
                    .toPromise();
                  // tslint:disable-next-line:no-string-literal
                  this.CommandAnswe = res;
                  this.shareDataService.sendingCommandsIsLoading = false;

                  if (clickedButton !== undefined) {
                    clickedButton.disabled = false;
                  }
                  this.toastr.success(' ბრძანება გაიგზავნა');

                  resolve();
                } catch (err) {
                  this.shareDataService.sendingCommandsIsLoading = false;

                  console.log(err);
                  if (clickedButton !== undefined) {
                    clickedButton.disabled = false;
                  }

                  this.shareDataService.sendingCommandsIsLoading = false;

                  this.toastr.error(
                    this.deviceArr[x].name + ' ბრძანება არ გაიგზავნა'
                  );

                  reject(err);
                }
              }
            });
          }
        }
      });
    }
  }
  // tslint:disable-next-line:typedef
  startMonitoring() {
    let startOrStop = '0';
    if (this.startedMonitoring === '0') {
      startOrStop = '1';
    }
    this.service.turnOnOffMonitoring(startOrStop).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // tslint:disable-next-line:typedef
  selectTurnOnMonitoringState() {
    this.service.selectTurnOnMonitoringResult().subscribe(
      (res) => {
        this.startedMonitoring = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  setK(target, Command, event) {
    const targetArr = target.split(';');
    const K1 = targetArr[0];
    const K2 = targetArr[1];
    const K3 = targetArr[2];
    const commandstr = '3333';
    const commandx = [...commandstr];
    if (K1 === 'true') {
      commandx[1] = Command;
    }
    if (K2 === 'true') {
      commandx[2] = Command;
    }
    if (K3 === 'true') {
      commandx[3] = Command;
    }
    this.deviceCommands('cmd_kontactors ' + commandx.join(''), event);
  }

  openBottomSheet(): void {
    console.log(this.commandTarget);
    this._bottomSheet.open(DeviceGhrapicComponent);
    this.shareDataService.selectedDeviceGraphic(this.commandTarget);
  }
}
