import { Component, OnInit } from '@angular/core';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ShareDataService } from 'src/app/services/share-data.service';
import * as XLSX from 'xlsx';
export interface UserData {
  name: string;
  deviceDateTime: string;
  ub: string;
  ua:string;
  uc: string;
  ia: string;
  ib: string;
  ic: string;
  i1: string;
  i2: string;
  i3: string;
  i4: string;
  i5: string;
  i6: string;
  i7: string;
  i8: string;
  i9: string;
  i10: string;
  i11: string;
  i12: string;
  pfa: string;
  pfb: string;
  pfc: string;
}
@Component({
  selector: 'app-all-device-info',
  templateUrl: './all-device-info.component.html',
  styleUrls: ['./all-device-info.component.scss']
})
export class AllDeviceInfoComponent implements OnInit {
  fileName = 'CurrentInfo.xlsx';
  displayedColumns: string[] = [ 'name',  'ua','ub','uc','pfa','pfb','pfc',
  'ia','ib','ic','i1','i2','i3','i4','i5','i6','i7','i8','i9','i10','i11','i12','deviceDateTime' ];
  dataSource: MatTableDataSource<string>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private shareDataService: ShareDataService,
  ) {
    shareDataService.currentApprovalStageMessage8.subscribe((msg) => {
    // this.pingMsg = Array.from(msg);

    var dataArr = Array.from(msg);
    dataArr.pop();
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(dataArr);
    });

  }

  ngOnInit(): void {
  }

  getRowStyle(row: any): any {
    const rowStyle: any = {};


    if (parseFloat(row.pfa) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }

    if (parseFloat(row.pfb) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }

    if (parseFloat(row.pfc) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }

    if (parseFloat(row.ia) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }

    if (parseFloat(row.ib) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }

    if (parseFloat(row.ic) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }

    if (parseFloat(row.i1) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i2) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i3) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i4) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i5) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i6) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i7) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i8) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i9) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i10) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i11) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }
    if (parseFloat(row.i12) > 0.2) {
      rowStyle.backgroundColor = 'red';
    }


    return rowStyle;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  exportexcel(): void {
    /* table id is passed over here */
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

}

