<div style="width: 100%">

  <div class="d-flex justify-content-between">
    <mat-form-field>
      <mat-label>ფილტრი</mat-label>
      <input matInput (keyup)="applyFilter($event)"  #input />
    </mat-form-field>
    <button
    class="btn btn-outline-success btn-sm"
    style="border:none"
    (click)="exportexcel()"
  >
    Excel <mat-icon> cloud_download</mat-icon>
  </button>
  </div>


      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort id="excel-table">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.name }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="ua">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ua</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.ua  == 'N' ? 'N': row.ua | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="ub">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ub</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.ub == 'N' ? 'N': row.ub | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="uc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>uc</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.uc == 'N' ? 'N': row.uc | number : "1.0-1"}}</td>
          </ng-container>


          <ng-container matColumnDef="pfa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>pfa</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.pfa == 'N' ? 'N': row.pfa | number : "1.0-1"}}</td>
          </ng-container>

          <ng-container matColumnDef="pfb">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>pfb</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.pfb == 'N' ? 'N': row.pfb | number : "1.0-1"}}</td>
          </ng-container>

          <ng-container matColumnDef="pfc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>pfc</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.pfc == 'N' ? 'N': row.pfc | number : "1.0-1"}}</td>
          </ng-container>



          <!-- Progress Column -->
          <ng-container matColumnDef="ia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ia</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.ia == 'N' ? 'N': row.ia | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="ib">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ib</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.ib == 'N' ? 'N': row.ib | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="ic">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ic</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.ic == 'N' ? 'N': row.ic | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i1</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i1 == 'N' ? 'N': row.i1 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i2</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i2 == 'N' ? 'N': row.i2 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i3">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i3</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i3 == 'N' ? 'N': row.i3 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i4">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i4</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i4 == 'N' ? 'N': row.i4 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i5">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i5</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i5 == 'N' ? 'N': row.i5 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i6">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i6</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i6 == 'N' ? 'N': row.i6 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i7">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i7</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i7 == 'N' ? 'N': row.i7 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i8">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i8</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i8 == 'N' ? 'N': row.i8 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i9">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i9</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i9 == 'N' ? 'N': row.i9 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i10</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i10 == 'N' ? 'N': row.i10 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i11</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i11 == 'N' ? 'N': row.i11 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="i12">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>i12</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.i12 == 'N' ? 'N': row.i12 | number : "1.0-1"}}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="deviceDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>თარიღი</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="getRowStyle(row)">{{ row.deviceDateTime }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              No data matching the filter "{{ input.value }}"
            </td>
          </tr>
        </table>

        <mat-paginator
          [pageSizeOptions]="[50, 100,2000]"
          aria-label="Select page of users"
        ></mat-paginator>
      </div>

</div>

