<app-nav></app-nav>
<div class="Download">
  <button
    class="btn btn-primary"
    style="
      background-color: rgb(22, 94, 143);
      margin-left: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    "
    (click)="showReport = !showReport"
  >
    რეპორტი
  </button>

  <button
    class="btn btn-primary"
    (click)="exportexcel()"
    style="display: flex; float: right; text-align: center; width: fit-content"
  >
    <mat-spinner
      class="mr-2"
      *ngIf="showExcelReport == true"
      [diameter]="30"
    ></mat-spinner>
    Excel

    <mat-icon class="ml-2"> cloud_download</mat-icon>
  </button>

  <!-- <button
    class="btn btn-primary"
    (click)="exportPdf()"
    style="display: flex; float: right"
  >
    PDF <mat-icon> picture_as_pdf</mat-icon>
  </button> -->
</div>
<div class="date-picker" *ngIf="showReport" style="z-index: 2">
  <div id="selectedDevice">
    <form [formGroup]="forHistoryGroup">
      <div class="selectReport">
        <label
          for="deviceName"
          class="position-relative m-2 text-light"
          style="font-size: large"
          >აირჩიე რეპორტი:</label
        >
        <select
          class="form-select custom-select mb-2"
          formControlName="report"
          placeholder="რეპორტის ტიპი"
        >
          <option *ngFor="let report of reportList" [value]="report">
            {{ report }}
          </option>
        </select>
      </div>
      <div class="selectdevice">
        <!-- <button (click)="testIng(toppingList)">CLICK ME</button> -->
        <label
          for="deviceName"
          class="position-relative m-2 text-light"
          style="font-size: large"
          >აირჩიე მოწყობილობა:</label
        >
        <!-- <select
          class="form-select custom-select"
          formControlName="deviceName"
          multiple
        >
        <option value="all">ყველა</option>
          <option *ngFor="let device of toppingList" [value]="device">
            {{ device }}
          </option>
        </select> -->
        <mat-form-field appearance="fill" style="width: 100%">
          <!-- <mat-label>Toppings</mat-label> -->

          <mat-select
            formControlName="deviceName"
            class="form-select custom-select"
            multiple
            (selectionChange)="selectAllDevices($event)"
          >
            <!-- <mat-select-trigger>
              {{toppings.value?.[0] || ''}}
              <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
              </span>
            </mat-select-trigger> -->
            <input
              matInput
              type="text"
              (keyup)="filterListCareUnit($event.target.value)"
              placeholder="ძებნა"
              style="
                border: 1px solid rgb(199, 196, 196);
                width: 99% !important;
              "
            />
            <mat-option value="all">ყველა</mat-option>
            <mat-option
              *ngFor="let device of toppingList"
              [value]="device"
              [selected]="form?.controls?.deviceName?.value.includes('all')"
              >{{ device }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <div></div>
  </div>
  <div class="datePickerContainer">
    <div class="datePicker">
      <div id="startTime" class="pl-1">
        <h2
          style="
            color: White;
            text-align: center;
            border-bottom: 1px solid white;
            margin-top: 14px;
          "
        >
          დასაწყისი
        </h2>
        <div>
          <mat-calendar
            [dateClass]="dateClass()"
            [selected]="selectedDate"
            #myDate
            (selectedChange)="getChangedValue($event)"
          ></mat-calendar>
        </div>
        <div class="mt-3" style="position: relative; left: -20px">
          <ngx-timepicker-field
            [(ngModel)]="Starttime"
            [format]="24"
            [defaultTime]="'00:00'"
            [controlOnly]="true"
          ></ngx-timepicker-field>
        </div>
        <!-- <label>დასაწყისი</label> -->
      </div>
    </div>
    <div class="datePicker" style="position: relative; right: 5px">
      <div id="endTime">
        <h2
          style="
            color: White;
            text-align: center;
            border-bottom: 1px solid white;
            margin-top: 14px;
          "
        >
          დასასრული
        </h2>
        <div>
          <mat-calendar
            [dateClass]="dateClass1()"
            [selected]="selectedDate1"
            #myDate2
            (selectedChange)="getChangedValue1($event)"
          ></mat-calendar>
        </div>

        <div class="mt-3 pr-2" style="position: relative; left: -20px">
          <ngx-timepicker-field
            [(ngModel)]="Endtime"
            [format]="24"
            [defaultTime]="'23:59'"
            [controlOnly]="true"
          ></ngx-timepicker-field>
        </div>
      </div>
    </div>
  </div>
  <div id="saveButton">
    <button
      class="btn"
      style="width: 100px; height: 50px; margin-right: 10px"
      (click)="getDeviceLogHistory(device)"
      style="background-color: rgb(221, 155, 32); color: white"
      [disabled]="!forHistoryGroup.valid"
    >
      რეპორტი
    </button>
    <mat-spinner *ngIf="showSpinner == true" [diameter]="30"></mat-spinner>
  </div>
</div>
<mat-form-field style="width: 100%" class="ml-3">
  <mat-label>ფილტრი</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
</mat-form-field>
<div
  class="mat-elevation-z8"
  *ngIf="this.forHistoryGroup.value.report == 'მესიჯების ისტორია'"
>
  <div
    style="
      width: 100% !important;
      height: 76vh !important;
      overflow: auto !important;
    "
  >
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="announceSortChange($event)"
      id="excel-table"
      class="ml-3"
    >
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by name"
        >
          სახელი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">{{ CommandAnswe.name }}</td>
      </ng-container>

      <ng-container matColumnDef="deviceDateTime">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by deviceDateTime"
        >
          თარიღი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{ CommandAnswe.deviceDateTime }}
        </td>
      </ng-container>

      <ng-container matColumnDef="csq">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by csq"
        >
          GSM სიგნალი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{ CommandAnswe.csq + "%" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="srOnOf">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by srOnOf"
        >
          ჩართვა/გამორთვა
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.srOnOf == "0"
              ? "გამორთულია"
              : CommandAnswe.srOnOf == "1"
              ? "ჩართულია"
              : CommandAnswe.srOnOf == "2"
              ? "გამორთულია"
              : CommandAnswe.srOnOf == "3"
              ? "ჩართულია"
              : CommandAnswe.srOnOf == "4"
              ? "გამორთულია"
              : CommandAnswe.srOnOf == "5"
              ? "ჩართულია"
              : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="managementMode">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by managementMode"
        >
          მართვის რეჟიმი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.managementMode == "0"
              ? "გრაფიკით"
              : CommandAnswe.managementMode == "1"
              ? "გრაფიკით"
              : CommandAnswe.managementMode == "2"
              ? "ხელით"
              : CommandAnswe.managementMode == "3"
              ? "ხელით"
              : CommandAnswe.managementMode == "4"
              ? "იუზ.გრაფიკით"
              : CommandAnswe.managementMode == "5"
              ? "იუზ.გრაფიკით"
              : ""
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="door">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by door"
        >
          კარადის კარი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.doorState.split(".")[0] == "0"
              ? "დაკეტილია განგაში არაა"
              : CommandAnswe.doorState.split(".")[0] == "1"
              ? "ღიაა განგაში არაა"
              : CommandAnswe.doorState.split(".")[0] == "2"
              ? "დაკეტილია განგაშია"
              : CommandAnswe.doorState.split(".")[0] == "3"
              ? "ღიაა განგაშია"
              : ""
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="defence">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by defence"
        >
          დაცვა
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.doorState.split(".")[1] == "0"
              ? "მოხსნილია დაცვიდან"
              : "დაცვაზეა"
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Ia">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Ia"
        >
          Ia
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.ia == "N" ? "N" : (CommandAnswe.ia | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Ib">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Ib"
        >
          Ib
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.ib == "N" ? "N" : (CommandAnswe.ib | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Ic">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Ic"
        >
          Ic
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.ic == "N" ? "N" : (CommandAnswe.ic | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ua">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Ua"
        >
          Ua
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.ua == "N" ? "N" : (CommandAnswe.ua | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ub">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Ub"
        >
          Ub
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.ub == "N" ? "N" : (CommandAnswe.ub | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="uc">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Uc"
        >
          Uc
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.uc == "N" ? "N" : (CommandAnswe.uc | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pa">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Pa"
        >
          Pa
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.pa == "N" ? "N" : (CommandAnswe.pa | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="pb">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Pb"
        >
          Pb
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.pb == "N" ? "N" : (CommandAnswe.pb | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pc">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Pc"
        >
          Pc
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.pc == "N" ? "N" : (CommandAnswe.pc | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="A">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by A"
        >
          უკუძაბვა A
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.ukudzabvaA == "N"
              ? "N"
              : (CommandAnswe.ukudzabvaA | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="B">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by B"
        >
          უკუძაბვა B
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.ukudzabvaB == "N"
              ? "N"
              : (CommandAnswe.ukudzabvaB | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="C">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by C"
        >
          უკუძაბვა C
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.ukudzabvaC == "N"
              ? "N"
              : (CommandAnswe.ukudzabvaC | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="numeratorCount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by numeratorCount"
        >
          მრიცხველის ჩვენება
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.numeratorCount == "N"
              ? "N"
              : (CommandAnswe.numeratorCount | number : "1.0-3")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I1">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I1"
        >
          I1
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i1 == "N" ? "N" : (CommandAnswe.i1 | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="I2">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I2"
        >
          I2
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i2 == "N" ? "N" : (CommandAnswe.i2 | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="I3">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I3"
        >
          I3
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i3 == "N" ? "N" : (CommandAnswe.i3 | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="I4">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I4"
        >
          I4
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i4 == "N" ? "N" : (CommandAnswe.i4 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I5">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I5"
        >
          I5
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i5 == "N" ? "N" : (CommandAnswe.i5 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I6">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I6"
        >
          I6
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i6 == "N" ? "N" : (CommandAnswe.i6 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I7">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I7"
        >
          I7
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i7 == "N" ? "N" : (CommandAnswe.i7 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I8">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I8"
        >
          I8
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i8 == "N" ? "N" : (CommandAnswe.i8 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I9">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I9"
        >
          I9
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i9 == "N" ? "N" : (CommandAnswe.i9 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I10">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I10"
        >
          I10
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i10 == "N"
              ? "N"
              : (CommandAnswe.i10 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I11">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I11"
        >
          I11
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i11 == "N"
              ? "N"
              : (CommandAnswe.i11 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="I12">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by I12"
        >
          I12
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.i12 == "N"
              ? "N"
              : (CommandAnswe.i12 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="k1">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by k1"
        >
          K1
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.k1 == "0"
              ? "გამორთულია/გრაფიკით"
              : CommandAnswe.k1 == "1"
              ? "ჩართულია/გრაფიკით"
              : CommandAnswe.k1 == "2"
              ? "გამორთულია/ხელით"
              : CommandAnswe.k1 == "3"
              ? "ჩართულია/ხელით"
              : CommandAnswe.k1 == "4"
              ? "გამორთლია/იუზ.გრაფიკით"
              : CommandAnswe.k1 == "5"
              ? "ჩართულია/იუზ.გრაფიკით"
              : ""
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="k2">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by k2"
        >
          K2
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.k2 == "0"
              ? "გამორთულია/გრაფიკით"
              : CommandAnswe.k2 == "1"
              ? "ჩართულია/გრაფიკით"
              : CommandAnswe.k2 == "2"
              ? "გამორთულია/ხელით"
              : CommandAnswe.k2 == "3"
              ? "ჩართულია/ხელით"
              : CommandAnswe.k2 == "4"
              ? "გამორთლია/იუზ.გრაფიკით"
              : CommandAnswe.k2 == "5"
              ? "ჩართულია/იუზ.გრაფიკით"
              : ""
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="NoiseLvl">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by NoiseLvl"
        >
          ხმაურიანობა
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.noiseLvlv == "N"
              ? "N"
              : (CommandAnswe.noiseLvlv | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="no2">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by no2"
        >
          აზოტის დიოქსიდი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.no2 == "N"
              ? "N"
              : (CommandAnswe.no2 | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="airTemp">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by airTemp"
        >
          &#8451;
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.airTemp == "N"
              ? "N"
              : (CommandAnswe.airTemp | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="lux">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by lux"
        >
          განათებულობა
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.lux == "N"
              ? "N"
              : (CommandAnswe.lux | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="airHumidity">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by airHumidity"
        >
          ტენიანობა
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.airHumidity == "N"
              ? "N"
              : (CommandAnswe.airHumidity | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="airPollution">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by airPollution"
        >
          დაბინძურება
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.airPollution == "N"
              ? "N"
              : (CommandAnswe.airPollution | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="PT">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by pt"
        >
          PT
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.pt == "N" ? "N" : (CommandAnswe.pt | number : "1.0-3")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="PFA">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by PFA"
        >
          PFA
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.pfa == "N"
              ? "N"
              : (CommandAnswe.pfa | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="PFB">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by PFB"
        >
          PFB
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.pfb == "N"
              ? "N"
              : (CommandAnswe.pfb | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="PFC">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by PFC"
        >
          PFC
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.pfc == "N"
              ? "N"
              : (CommandAnswe.pfc | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="WaltMainPower">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by WaltMainPower"
        >
          WaltMainPower
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.waltMainPower == "N"
              ? "N"
              : (CommandAnswe.waltMainPower | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ElementWalt">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by ElementWalt"
        >
          ElementWalt
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.elementWalt == "N"
              ? "N"
              : (CommandAnswe.elementWalt | number : "1.0-1")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="interval">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by interval"
        >
          ინტერვალი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{ CommandAnswe.interval }}
        </td>
      </ng-container>

      <ng-container matColumnDef="CurrentSensor">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by currentSensor"
        >
          დენის სენსორი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{
            CommandAnswe.currentSensor == "N"
              ? "N"
              : (CommandAnswe.currentSensor | number : "1.0-1")
          }}
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns3; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns3"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">არ მოიძებნა{{ input.value }}"</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    #paginator
    [pageSizeOptions]="[20, 50, 100, 200, 100000]"
    showFirstLastButtons
  ></mat-paginator>
</div>

<div
  class="mat-elevation-z8"
  *ngIf="this.forHistoryGroup.value.report == 'დაზიანება'"
>
  <div
    style="
      width: 100% !important;
      height: 76vh !important;
      overflow: auto !important;
    "
  >
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="announceSortChange($event)"
      id="excel-table"
      class="ml-3"
    >
      <!-- <ng-container matColumnDef="device">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by device"
      >
        სახელი
      </th>
      <td mat-cell *matCellDef="let CommandAnswe">{{ CommandAnswe.device }}</td>
    </ng-container> -->

      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by deviceDateTime"
        >
          თარიღი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{ CommandAnswe.dateTime | date : "dd/MM/yyyy HH:mm:ss" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="error">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by error"
        >
          დაზიანება
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{ CommandAnswe.evenet }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">არ მოიძებნა{{ input.value }}"</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    #paginator
    [pageSizeOptions]="[20, 50, 100, 200, 100000]"
    showFirstLastButtons
  ></mat-paginator>
</div>

<div
  class="mat-elevation-z8"
  *ngIf="
    this.forHistoryGroup.value.report != 'დაზიანება' &&
    this.forHistoryGroup.value.report != 'მესიჯების ისტორია' &&
    this.forHistoryGroup.value.report != 'გრაფიკით ჩართვის ხანგრძლივობა წუთებში'
  "
>
  <div
    style="
      width: 100% !important;
      height: 76vh !important;
      overflow: auto !important;
    "
  >
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="announceSortChange($event)"
      id="excel-table"
      class="ml-3"
    >
      <ng-container matColumnDef="Name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by name"
        >
          სახელი
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">{{ CommandAnswe.name }}</td>
      </ng-container>

      <ng-container matColumnDef="DateStart">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by date"
        >
          გრაფიკით ჩართვის დრო
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{ CommandAnswe.date.split("-")[0] }}
        </td>
      </ng-container>
      <ng-container matColumnDef="DateEnd">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by date"
        >
          გრაფიკით გამორთვის დრო
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{ CommandAnswe.date.split("-")[1] }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Duration">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Duration"
        >
          ხანგრძლივობა
        </th>
        <td mat-cell *matCellDef="let CommandAnswe">
          {{ CommandAnswe.turnOnTime }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="turnOnDuration; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: turnOnDuration"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">არ მოიძებნა{{ input.value }}"</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    #paginator
    [pageSizeOptions]="[20, 50, 100, 200, 100000]"
    showFirstLastButtons
  ></mat-paginator>
</div>

<div
  class="graphicByMinute"
  style="width: 100% !important; height: 80%; overflow: auto !important"
  *ngIf="
    this.forHistoryGroup.value.report == 'გრაფიკით ჩართვის ხანგრძლივობა წუთებში'
  "
>
  <table class="mat-table" id="excel-table" style="width: 40% !important">
    <tr>
      <th style="color: transparent; overflow: hidden">სახელი</th>
      <td
        class="mat-header-cell ml-3"
        *ngFor="let date of displayedColumns4"
        style="background-color: rgb(22, 94, 143); color: #fff"
      >
        {{ "*" + date }}
      </td>
    </tr>
    <tr *ngFor="let date of sortByDate | keyvalue : keepOrder">
      <th
        class="mat-header-cell"
        style="
          text-align: center;
          min-width: 120px !important;
          overflow: hidden;
        "
      >
        {{ date.key.replace("T", " ").replace("00:00:00", "") }}
      </th>
      <td class="mat-cell" *ngFor="let x of date.value | keyvalue : keepOrder">
        {{ x.value.miN_PER_DAY }}
      </td>
    </tr>
  </table>
</div>
