import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ShareDataService } from './share-data.service';
import { UserService } from './user.service';
import { AudioReferenceDataType } from '../types/AudioTypes';
import { EventHistoryType } from '../types/EventHistory';
@Injectable({
  providedIn: 'root',
})
export class AudioService {
  constructor() {}

  checkImportantEvent(
    EventHistory: EventHistoryType[],
    newIndex: number,
    deviceError: any
  ): boolean {
    console.log(
      !deviceError.includes(
        EventHistory[newIndex]['evenet'] +
          ' ' +
          EventHistory[newIndex]['dateTime'].replace('T', ' ')
      )
    );
    return !deviceError.includes(
      EventHistory[newIndex]['evenet'] +
        ' ' +
        EventHistory[newIndex]['dateTime'].replace('T', ' ')
    );
  }
  //  გასაკეთებუელი,  გადააკეთე errorMessage სტრინგის არრაით, და თუ არის ორზე მეტი დაატრიალებინე ციკლში

  //  errorMessage არრაი იმიტომ არის რომ წინა კოდი  trash -იყო, და იმაზე მორგება მინდოდა, მერე დამეზარა შეცვლა, ეხლა მუშაობს არ შეცვალო
  AudioRefrenceDataArray: AudioReferenceDataType[] = [
    {
      // mushaobs
      errorMessage: ['უნებართვო'],
      audioLink: 'Siren.mp3',
      id: 0,
    },

    {
      // mushaobs
      errorMessage: ['შემომავალ ფაზაზე ძაბვის ნორმა დაირღვა'],
      audioLink: 'Shemavalfazaze-dzabivsnormadairgva.mp3',
      id: 1,
    },
    {
      //  mushaobs
      errorMessage: ['განათება გამოირთო'],
      audioLink: 'gamoirto.mp3',
      id: 2,
    },
    {
      //  mushaobs
      errorMessage: ['ფაზების მიმდევრობა არეულია'],
      audioLink: 'fazebis_mimdinareoba.mp3',
      id: 3,
    },
    {
      // mushaobs
      errorMessage: ['განათება ჩაირთო'],
      audioLink: 'chairto.mp3',
      id: 4,
    },
    {
      //  მუშაობს
      errorMessage: ['უკუძაბვაა'],
      audioLink: 'QselshiUkuDzabvaa.mp3',
      id: 5,
    },
    {
      // მუშაობს
      errorMessage: ['კარადის კარი გაიღო'],
      audioLink: 'kari_gaigo.mp3',
      id: 6,
    },
    {
      //  მუშაობს
      errorMessage: ['კარადის კარი დაიკეტა'],
      audioLink: 'kari_daixura.mp3',
      id: 7,
    },
    {
      // მუშაობს
      errorMessage: ['ძაბვა ნორმაშია'],
      audioLink: 'dzabva_normashia.mp3',
      id: 8,
    },
    {
      //  მუშაობს
      errorMessage: ['ძაბვა გამოირთო'],
      audioLink: 'dzabva_gamoirto.mp3',
      id: 9,
    },
    {
      // მუშაობს
      errorMessage: ['ტემპერატურა ზღვარზე დაბალია'],
      audioLink: 'temperatura_dabalia.mp3',
      id: 10,
    },
    {
      // მუშაობს
      errorMessage: ['წყვეტა'],
      audioLink: 'kavshirisWyveta.mp3',
      id: 11,
    },
    {
      // მუშაობს
      errorMessage: ['ტემპერატურა ზღვარზე მაღალია'],
      audioLink: 'temperatura_maghalia.mp3',
      id: 12,
    },
    {
      // მუშაობს
      errorMessage: ['ნახშიროჟანგი ზღვარზე დაბალია'],
      audioLink: 'naxshirojangi_dabalia.mp3',
      id: 13,
    },
    {
      // მუშაობს
      errorMessage: ['ნახშიროჟანგი ზღვარზე მაღალია'],
      audioLink: 'naxshirojangi_maghalia.mp3',
      id: 14,
    },
    {
      // მუშაობს
      errorMessage: ['აზოტის დიოქსიდი ზღვარზე დაბალია'],
      audioLink: 'azoti_dabalia.mp3',
      id: 15,
    },
    {
      // მუშაობს
      errorMessage: ['აზოტის დიოქსიდი ზღვარზე მაღალია'],
      audioLink: 'azoti_maghalia.mp3',
      id: 16,
    },
    {
      // მუშაობს
      errorMessage: ['ტენიანობა ზღვარზე დაბალია'],
      audioLink: 'tenianoba_dabalia.mp3',
      id: 17,
    },
    {
      // მუშაოსბ
      errorMessage: ['ტენიანობა ზღვარზე მაღალია'],
      audioLink: 'tenianoba_maghalia.mp3',
      id: 18,
    },
    {
      // მუშაობს
      errorMessage: ['ხმაური ზღვარზე დაბალია'],
      audioLink: 'xmauri_dabalia.mp3',
      id: 19,
    },
    {
      // მუშაობს
      errorMessage: ['ხმაური ზღვარზე მაღალია'],
      audioLink: 'xmauri_maghalia.mp3',
      id: 20,
    },
    {
      //  მუშაობს
      errorMessage: ['განათებულობა ზღვარზე მაღალია'],
      audioLink: 'ganatebuloba_maghalia.mp3',
      id: 21,
    },
    {
      // mushaobs
      errorMessage: ['კვების ბლოკი არ მუშაობს'],
      audioLink: 'kvebis_bloki_ar_mushaobs.mp3',
      id: 22,
    },
    {
      // mushaobs
      errorMessage: ['ელემენტის ძაბვა მინიმალურია'],
      audioLink: 'elementis_dzabva_minimaluria.mp3',
      id: 23,
    },
    {
      //  mushaobs
      errorMessage: ['მიღების დონე დაბალია'],
      audioLink: 'mighebis_done_dabalia.mp3',
      id: 24,
    },
    {
      // mushaobs
      errorMessage: ['ქსელში ძაბვა დასაშვებზე მაღალია'],
      audioLink: 'qselshi_dzabva_dasashvebze_maghalia.mp3',
      id: 25,
    },
    {
      // mushaobs
      errorMessage: ['ელექტრო ენერგია გამოირთო'],
      audioLink: 'eleqtroenergiaGamoirto.mp3',
      id: 26,
    },
    {
      // mushoabs
      errorMessage: ['ელექტრო ენერგია ჩაირთო'],
      audioLink: 'eleqtroenergiaChairto.mp3',
      id: 27,
    },
    {
      // mushaobs
      errorMessage: ['MOODBUS - ის  შეცდომა'],
      audioLink: 'modbasis_shecdoma.mp3',
      id: 28,
    },
    {
      // mushaobs
      errorMessage: ['ტელეფონის ნომრის ფორმატის შეცდომა'],
      audioLink: 'teleponis_nomris_formatis_shecdoma.mp3',
      id: 29,
    },
    {
      // mushaovs
      errorMessage: ['მრიცხველის მონაცემის შეცდომა'],
      audioLink: 'mricxvelis_generatoris_shecdoma.mp3',
      id: 33,
    },
    {
      // მუშაობს
      errorMessage: ['დენი ნორმაზე მაღალია'],
      audioLink: 'gamomavalFazazeDenisNormaDairgva.mp3',
      id: 30,
    },
    {
      // მუშაობს
      errorMessage: ['დენი ნორმაზე დაბალია'],
      audioLink: 'gamomavalFazazeDenisNormaDairgva.mp3',
      id: 35,
    },
    {
      // მუშაობს
      errorMessage: ['დენი ნორმაშია'],
      audioLink: 'DeniNormashia.mp3',
      id: 31,
    },
    // {
    //   // mushaobs;'
    //   errorMessage: ['ძაბვა ნორმაზე'],
    //   audioLink: 'qselshiDasashvebzeMagaliDzabvaa.mp3',
    //   id: 32,
    // },
  ];

  // TEST DATA
  // ტესტირების დროს ყოველ რელოადზე და დასეივებაზე შეუცვალე id პირველ ობიექტს რომ აუდიო გაეშვას
  //  ძველი id ინახება ლოკალ სტორიჯში რითაც ვამოწმებთ ერრორი იყო თუ არა ნათქვამი უკვე
  FakeObj = [
    {
      dateTime: '2024-01-15T17:20:17.087',
      deviceId: 0,
      deviceImei: '8620950552510355',
      evenet: 'ic დენი ნორმაზე მაღალია',
      evenetType: 'Important',
      id:  221,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-15T17:20:17.087',
      deviceId: 0,
      deviceImei: '8620950552510355',
      evenet: 'განათება გამოირთო',
      evenetType: 'Important',
      id: 3420,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-15T17:20:17.087',
      deviceId: 0,
      deviceImei: '8620950552510355',
      evenet: 'ic დენი ნორმაზე მაღალია',
      evenetType: 'Important',
      id:  421,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-15T17:20:17.087',
      deviceId: 0,
      deviceImei: '8620950552510355',
      evenet: 'განათება გამოირთო',
      evenetType: 'Important',
      id: 5420,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-15T17:20:17.087',
      deviceId: 0,
      deviceImei: '8620950552510355',
      evenet: 'განათება გამოირთო',
      evenetType: 'Important',
      id: 1220,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-24T17:11:27.087',
      deviceId: 0,
      deviceImei: '862095055510355',
      evenet: '5057 ia დენი ნორმაშია',
      evenetType: 'Important',
      id: 211,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-24T17:11:27.087',
      deviceId: 0,
      deviceImei: '862095055510355',
      evenet: '862095055510355 ia დენი ნორმაშია',
      evenetType: 'Important',
      id: 51,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-24T17:11:27.087',
      deviceId: 0,
      deviceImei: '862095055510355',
      evenet: '862095055510355 ia დენი ნორმაშია',
      evenetType: 'Important',
      id: 2,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-24T17:11:27.087',
      deviceId: 0,
      deviceImei: '862095055510355',
      evenet: '862095055510355 შემომავალ ფაზაზე ძაბვის ნორმა დაირღვა',
      evenetType: 'Important',
      id: 1230,
      isRead: 0,
      userId: null,
    },
    {
      dateTime: '2024-01-15T17:20:17.087',
      deviceId: 0,
      deviceImei: '8620950552510355',
      evenet: 'განათება გამოირთო',
      evenetType: 'Important',
      id: 1220,
      isRead: 0,
      userId: null,
    },
  ];
}
