import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent implements OnInit {
  userList: any;
  toppingList: any[] = [];
  selectedUser: string;
  selectedUserName: string;
  selectedUserPass: string;
  userIdList: {};
  isChecked = true;
  toppings = new FormControl();
  userDetails: any;
  userId: any;
  ViewNetwork;
  allowTurnOnOff;
  allowGraphic;
  allowCommandPanel;
  allowOtherCommands;

  constructor(
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getUserList();
    this.userService.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  getUserList() {
    this.userService.getUserList().subscribe(
      (res) => {
        // tslint:disable-next-line:no-string-literal
        this.userList = res;
        this.userIdList = {};
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Object.keys(res).length; i++) {
          // tslint:disable-next-line:forin
          for (const x in res[i]) {
            if (!this.toppingList.includes(res[i].name)) {
              this.toppingList.push(res[i].name);
              this.userIdList[res[i].name] = res[i].id;
            }
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  onDeviceSelect(x) {
    this.userLimits(x);
  }

  // tslint:disable-next-line:typedef
  saveLimitCorrection(
    selectedUser,
    ViewNetwork,
    allowTurnOnOff,
    allowGraphic,
    allowCommandPanel,
    allowOtherCommands
  ) {
    const id = this.userIdList[selectedUser];
    if (ViewNetwork === true) {
      ViewNetwork = 1;
    } else {
      ViewNetwork = 0;
    }

    if (allowTurnOnOff === true) {
      allowTurnOnOff = 1;
    } else {
      allowTurnOnOff = 0;
    }

    if (allowGraphic === true) {
      allowGraphic = 1;
    } else {
      allowGraphic = 0;
    }

    if (allowCommandPanel === true) {
      allowCommandPanel = 1;
    } else {
      allowCommandPanel = 0;
    }
    if (allowOtherCommands === true) {
      allowOtherCommands = 1;
    } else {
      allowOtherCommands = 0;
    }
    this.userService
      .saveUserLimits(
        id,
        ViewNetwork,
        allowTurnOnOff,
        allowGraphic,
        allowCommandPanel,
        allowOtherCommands
      )
      .subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
  }
  // tslint:disable-next-line:typedef
  userLimits(x) {
    const id = this.userIdList[x];
    this.userService.selectUserLimits(id).subscribe(
      (res) => {
        if (res[0].split(';')[1] === '1') {
          // tslint:disable-next-line:no-unused-expression
          this.ViewNetwork = true;
        } else {
          this.ViewNetwork = false;
        }

        if (res[0].split(';')[2] === '1') {
          // tslint:disable-next-line:no-unused-expression
          this.allowTurnOnOff = true;
        } else {
          this.allowTurnOnOff = false;
        }

        if (res[0].split(';')[3] === '1') {
          // tslint:disable-next-line:no-unused-expression
          this.allowGraphic = true;
        } else {
          this.allowGraphic = false;
        }

        if (res[0].split(';')[4] === '1') {
          // tslint:disable-next-line:no-unused-expression
          this.allowCommandPanel = true;
        } else {
          this.allowCommandPanel = false;
        }

        if (res[0].split(';')[5] === '1') {
          // tslint:disable-next-line:no-unused-expression
          this.allowOtherCommands = true;
        } else {
          this.allowOtherCommands = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
