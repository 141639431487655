import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ShareDataService } from '../services/share-data.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-data-limits',
  templateUrl: './data-limits.component.html',
  styleUrls: ['./data-limits.component.scss'],
})
export class DataLimitsComponent implements OnInit {
  dataLimitForm = new FormGroup({
    Name: new FormControl('', Validators.required),
    UpperLimit: new FormControl('', Validators.required),
    LowLimit: new FormControl('', Validators.required),
  });
  userId: any;
  limitsPerDevice: {};
  targetData: any;
  userDetails: {};
  selectedDeviceId: string;
  selectedDeviceData: string;
  constructor(
    private service: UserService,
    private shareDataService: ShareDataService,
    private _bottomSheetRef: MatBottomSheetRef<DataLimitsComponent>
  ) {}

  ngOnInit(): void {
    this.service.getUserProfile().subscribe(
      (res) => {
        this.userDetails = res;
        // tslint:disable-next-line:no-string-literal
        this.userId = this.userDetails['id'];
        this.getCurrentLimits();
      },
      (err) => {
        console.log(err);
      }
    );
    this.targetData =
      this.shareDataService.currentApprovalStageMessage2.subscribe((msg) => {
        this.selectedDeviceId = msg.split(';')[0];
        this.selectedDeviceData = msg.split(';')[1];
      });
  }
  // tslint:disable-next-line:typedef
  getCurrentLimits() {
    this.service.getCurrentDeviceLimits(this.userId).subscribe(
      (res) => {
        this.limitsPerDevice = {};
        res.forEach((x) => {
          this.limitsPerDevice[x.deviceId] = x;
        });
        if (
          this.limitsPerDevice[this.selectedDeviceId] !== undefined &&
          this.limitsPerDevice[this.selectedDeviceId][
            this.selectedDeviceData
          ] !== ''
        ) {
          // tslint:disable-next-line:no-string-literal
          this.dataLimitForm.setValue({
            Name: this.selectedDeviceData,
            UpperLimit: parseFloat(
              this.limitsPerDevice[this.selectedDeviceId][
                this.selectedDeviceData
              ].split(';')[0]
            ),
            LowLimit: parseFloat(
              this.limitsPerDevice[this.selectedDeviceId][
                this.selectedDeviceData
              ].split(';')[1]
            ),
          });
        } else {
          this.dataLimitForm.setValue({
            Name: this.selectedDeviceData,
            UpperLimit: '',
            LowLimit: '',
          });
        }

        // tslint:disable-next-line:no-string-literal
        console.log(this.limitsPerDevice);
        this.dataLimitForm.setValue({
          Name: this.selectedDeviceData,
          UpperLimit:
            this.limitsPerDevice[this.selectedDeviceId][
              this.selectedDeviceData
            ].split(';')[1],
          LowLimit:
            this.limitsPerDevice[this.selectedDeviceId][
              this.selectedDeviceData
            ].split(';')[0],
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:typedef
  saveLimits() {
    // tslint:disable-next-line:no-string-literal
    this.service
      .deviceLimits(
        this.userId,
        this.selectedDeviceId,
        // tslint:disable-next-line:no-string-literal
        this.selectedDeviceData,
        this.dataLimitForm.controls['UpperLimit'].value,
        // tslint:disable-next-line:no-string-literal
        this.dataLimitForm.controls['LowLimit'].value
      )
      .subscribe(
        (res) => {
          this._bottomSheetRef.dismiss();
          event.preventDefault();
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
