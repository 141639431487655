<h1>მოწყობილობის წაშლა</h1>
<div class="Block">
  <mat-form-field
    appearance="fill"
    class="add-device-option"
    style="margin-right: 5px"
  >
    <mat-label>მოწყობილობა</mat-label>
    <mat-select [formControl]="toppings" multiple [(ngModel)]="selectedDevice">
      <input matInput type="text" (keyup)="filterListCareUnit($event.target.value)" placeholder="ძებნა" style="border: 1px solid rgb(199, 196, 196); width: 99% !important;">
      <mat-select-trigger>
        {{ toppings.value ? toppings.value[0] : "" }}
        <span
          *ngIf="toppings.value?.length > 1"
          class="example-additional-selection"
        >
          (+{{ toppings.value.length - 1 }}
          {{ toppings.value?.length === 2 ? "other" : "others" }})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let topping of toppingList" [value]="topping">{{
        topping.split('__')[0]
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <button class="btn btn-success" (click)="sendProgramCmd(selectedDevice)">
    წაშლა
  </button>
  <!-- <button class="btn btn-danger" (click)="sendProgramCmd(selectedDevice)">დაპროგრამება</button> -->
</div>