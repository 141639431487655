<form [formGroup]="service.formModel" autocomplete="off" (submit)="onSubmit()">
  <h1 style="border-bottom: 1px solid white;font-weight: bold;">მომხარებლის დამატება</h1>
  <div class="form-group required">
    <label>სახელი</label>
    <input  class="form-control" formControlName="UserName">
    <label  class="text-danger" *ngIf="service.formModel.get('UserName').touched && service.formModel.get('UserName').errors?.required">
      ამ ველის შევსება აუცილებელია
    </label>
  </div>
  <mat-form-field appearance="fill" class="add-device-option" style="margin-right: 5px;background-color: #aac9eb;">
    <mat-label>როლი:</mat-label>
    <mat-select  multiple [(ngModel)]="selectedRole" formControlName="Role">
      <mat-option *ngFor="let role of roleList" [value]="role">{{role}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="form-group">
    <label>ელ-ფოსტა</label>
    <input  class="form-control" formControlName="Email">
    <label  class="text-danger" *ngIf="service.formModel.get('Email')"></label>
    <label  class="text-danger" *ngIf="service.formModel.get('Email').touched && service.formModel.get('Email').errors?.email">
      ელ-ფოსტა არასწორია
    </label>
  </div>
  <div class="form-group">
    <label>სრული სახელი</label>
    <input  class="form-control" formControlName="FullName">
  </div>
  <div formGroupName="Passwords">
    <div class="form-group required">
      <label>პაროლი</label>
      <input type="password" class="form-control" formControlName="Password">
      <label  class="text-danger" *ngIf="service.formModel.get('Passwords.Password').touched && service.formModel.get('Passwords.Password').errors?.required">
        ამ ველის შევსება აუცილებელია
      </label>
      <label  class="text-danger" *ngIf="service.formModel.get('Passwords.Password').touched && service.formModel.get('Passwords.Password').errors?.minlength">
        პაროლი უნდა შეიცავდეს მინიმუმ 4 ასოს
      </label>
    </div>
    <div class="form-group required">
      <label>პაროლის გამეორება</label>
      <input type="password"  class="form-control" formControlName="ConfirmPassword">
      <label  class="text-danger" *ngIf="service.formModel.get('Passwords.ConfirmPassword').touched && service.formModel.get('Passwords.ConfirmPassword').errors?.required">
        ამ ველის შევსება აუცილებელია
      </label>
      <label  class="text-danger" *ngIf="service.formModel.get('Passwords.ConfirmPassword').touched && service.formModel.get('Passwords.ConfirmPassword').errors?.passwordMismatch">
        პაროლები არ ემთხვევა ერთმანეთს
      </label>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-8 offset-md-2">
   <button type="submit" class="btn btn-lg btn-block"[disabled]="!service.formModel.valid">დამატება</button>
    </div>
  </div>
</form>
