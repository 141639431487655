import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private fb: FormBuilder, private http: HttpClient) {}
  readonly BaseURI = ' https://request2.mygps.ge:4490/api';
  formModel = this.fb.group({
    UserName: ['', Validators.required],
    Role: ['', Validators.required],
    Email: ['', Validators.email],
    FullName: [''],
    Passwords: this.fb.group(
      {
        Password: ['', [Validators.required, Validators.minLength(4)]],
        ConfirmPassword: ['', Validators.required],
      },
      { validator: this.comparePasswords }
    ),
  });

  // tslint:disable-next-line:typedef
  comparePasswords(fb: FormGroup) {
    // tslint:disable-next-line:prefer-const
    let confirmPswrdCtrl = fb.get('ConfirmPassword');
    // PasswordMismatch
    // tslint:disable-next-line:no-conditional-assignment
    if (
      confirmPswrdCtrl.errors == null ||
      'passwordMismatch' in confirmPswrdCtrl.errors
    ) {
      if (fb.get('Password').value !== confirmPswrdCtrl.value) {
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      } else {
        confirmPswrdCtrl.setErrors(null);
      }
    }
  }

  // tslint:disable-next-line:typedef
  register() {
    // tslint:disable-next-line:prefer-const
    let body = {
      userName: this.formModel.value.UserName,
      Email: this.formModel.value.Email,
      FullName: this.formModel.value.FullName,
      Password: this.formModel.value.Passwords.Password,
      Role:
        this.formModel.value.Role.toString() == 'ადმინისტრატორი'
          ? 'Admin'
          : 'Customer',
    };
    return this.http.post(this.BaseURI + '/ApplicationUser/Register', body);
  }
  // tslint:disable-next-line:typedef
  login(formData) {
    return this.http.post(this.BaseURI + '/ApplicationUser/Login', formData);
  }
  // tslint:disable-next-line:typedef
  getUserProfile() {
    // tslint:disable-next-line:prefer-const
    return this.http.get(this.BaseURI + '/UserProfile');
  }

  // tslint:disable-next-line:typedef
  getBootResult() {
    // tslint:disable-next-line:prefer-const
    return this.http.get(this.BaseURI + '/DevicePingMessage/BootResult', {
      responseType: 'text',
    });
  }
  // tslint:disable-next-line:typedef
  getUserList() {
    return this.http.get(this.BaseURI + '/DevicePingMessage/GetUserIdList');
  }
  roleMatch(allowedRoles): boolean {
    // tslint:disable-next-line:no-var-keyword
    var isMatch = false;
    // tslint:disable-next-line:prefer-const
    let payLoad = JSON.parse(
      window.atob(localStorage.getItem('token').split('.')[1])
    );
    // tslint:disable-next-line:prefer-const
    let userRole = payLoad.role;
    allowedRoles.forEach((element) => {
      if (userRole === element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  // tslint:disable-next-line:typedef
  StartPingMessage(x) {
    var userId = { UserId: x };
    const body = JSON.stringify(userId);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(this.BaseURI + '/DevicePingMessage/StartPingMessage', body, {
        headers: headers,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // tslint:disable-next-line:typedef
  PingMessage(x) {
    return this.http
      .get<any>(this.BaseURI + `/DevicePingMessage/GetPingMessage?userId=${x}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // tslint:disable-next-line:typedef
  addDevice(
    userId,
    imei,
    name,
    deviceId,
    lat,
    long,
    adress,
    objectId,
    serialNumber
  ) {
    const addDevice = {
      UserId: userId,
      Imei: imei,
      Name: name,
      DeviceId: deviceId.toString(),
      Lat: lat,
      Long: long,
      Adress: adress,
      ObjectId: objectId,
      SerialNumber: Number.parseInt(serialNumber),
    };
    const body = JSON.stringify(addDevice);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(this.BaseURI + '/DevicePingMessage/AddNewDevice', body, {
        headers: headers,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  deviceCommandsList(command, commandTargetArray) {
    const obj = {
      listOfDeviceImeis: commandTargetArray,
      command,
    };
    console.log('SENDING');
    const body = JSON.stringify(obj);

    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/PostDeviceCommandToMultipleDevices',
      obj
    );
  }
  // tslint:disable-next-line:typedef
  deviceCommands(command, commandTarget) {
    const commandObj = {
      DeviceImei: commandTarget,
      Command: command,
    };
    // const headers = { 'content-type': 'multipart/form-data'};
    const body = JSON.stringify(commandObj);

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(this.BaseURI + '/DevicePingMessage/PostDeviceCommand', body, {
        headers: headers,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // tslint:disable-next-line:typedef
  mapCordinates(userId) {
    // const headers = { 'content-type': 'multipart/form-data'};
    // const user = {
    //   UserId: userId,
    // };
    const body = JSON.stringify(userId);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    return this.http
      .get<any>(
        this.BaseURI + `/DevicePingMessage/GetMapCoordinates?userId=${userId}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // tslint:disable-next-line:typedef
  editDeviceInfo(x) {
    // const headers = { 'content-type': 'multipart/form-data'};

    const body = JSON.stringify(x);
    console.log(body);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(this.BaseURI + '/DevicePingMessage/editDeviceInfo', x, {
        headers: headers,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  } //apishi araa
  // tslint:disable-next-line:typedef
  editUserInfo(x) {
    var userInfoArray = x.split(';');
    const user = {
      UserId: userInfoArray[0],
      UserName: userInfoArray[1],
      Password: userInfoArray[2],
      Role: userInfoArray[3],
    };

    const body = JSON.stringify(user);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    // tslint:disable-next-line:prefer-const
    return this.http.post(
      this.BaseURI + '/ApplicationUser/ChangeUserInfo',
      body,
      {
        headers: headers,
      }
    );
  } // apishi araa

  // tslint:disable-next-line:typedef
  inserEvents(deviceId, deviceImei, event, useriD, eventType) {
    const body = new FormData();
    body.append(
      deviceId +
        ';' +
        deviceImei +
        ';' +
        event +
        ';' +
        useriD +
        ';' +
        eventType,
      ''
    );
    // tslint:disable-next-line:prefer-const
    return this.http.post(
      this.BaseURI + '/DevicePingMessage/InsertDeviceEvent',
      body
    );
  }

  // tslint:disable-next-line:typedef
  deviceEvents(userId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('accountId', userId);

    // tslint:disable-next-line:prefer-const
    return this.http.post(
      this.BaseURI + '/DevicePingMessage/DeviceEventHistoryByAccount',
      {},
      { headers: headers }
    );
  }

  // tslint:disable-next-line:typedef
  removeDeviceFromUser(userId: string, deviceId: string) {
    const device = {
      UserId: userId,
      DeviceId: deviceId,
    };
    const body = JSON.stringify(device);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI +
        `/DevicePingMessage/UpdateUserDevice?deviceId=${deviceId}&userId=${userId}`,

      {
        headers: headers,
      }
    );
  }
  // tslint:disable-next-line:typedef
  addDeviceToUser(userId: string, deviceId: string) {
    const device = {
      UserId: userId,
      DeviceId: deviceId,
    };
    const body = JSON.stringify(device);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI +
        `/DevicePingMessage/UpdateUserDevice?deviceId=${deviceId}&userId=${userId}`,

      {
        headers: headers,
      }
    );
  }
  // tslint:disable-next-line:typedef
  cancelSpinner(deviceId) {
    const body = new FormData();
    body.append(deviceId, '');
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/CancelSpinner',
      body
    );
  }
  // tslint:disable-next-line:typedef
  deviceLimits(userId, imei, limitType, maxLimit, minLimit) {
    const obj = {
      userId,
      imei,
      limitType,
      maxLimit: String(maxLimit),
      minLimit: String(minLimit),
    };
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/SetDeviceLimits',
      obj
    );
  } // apishi araa
  // tslint:disable-next-line:typedef
  deleteEvent(userId) {
    console.log(userId);

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/deleteEvent',
      userId,
      {
        headers: headers,
      }
    );
  }

  DeleteEventInAccount(userId: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('accountId', userId);

    return this.http.post(
      this.BaseURI + '/DevicePingMessage/DeleteEventInAccount',
      {},
      { headers: headers }
    );
  }
  // tslint:disable-next-line:typedef
  selectUserLimits(userId) {
    console.log(userId);
    const user = {
      UserId: userId,
    };
    const body = JSON.stringify(user);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/GetUserLimit',
      body,
      {
        headers: headers,
      }
    );
  }

  // tslint:disable-next-line:typedef
  saveUserLimits(
    userId,
    ViewNetwork,
    allowTurnOnOff,
    allowGraphic,
    allowCommandPanel,
    allowOtherCommands
  ) {
    const UserLimits = {
      UserId: userId,
      ViewNetwork: ViewNetwork,
      allowTurnOnOff: allowTurnOnOff,
      allowGraphic: allowGraphic,
      allowCommandPanel: allowCommandPanel,
      allowOtherCommands: allowOtherCommands,
    };
    const body = JSON.stringify(UserLimits);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/SaveUserLimits',
      body,
      {
        headers: headers,
      }
    );
  }

  // tslint:disable-next-line:typedef
  turnOnOffMonitoring(start) {
    const body = new FormData();
    body.append(start, '');
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/TurnOnMonitoring',
      body
    );
  }

  // tslint:disable-next-line:typedef
  selectTurnOnMonitoringResult() {
    // tslint:disable-next-line:prefer-const
    return this.http.get(
      this.BaseURI + '/DevicePingMessage/GetTurnOnMonitoring',
      { responseType: 'text' }
    );
  }

  // tslint:disable-next-line:typedef
  addNewComment(comment, imei) {
    const device = {
      Imei: imei,
      Comment: comment,
    };

    const body = JSON.stringify(device);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/AddNewComment',
      body,
      {
        headers: headers,
      }
    );
  }

  // tslint:disable-next-line:typedef
  getCurrentDeviceLimits(userId) {
    const user = {
      UserId: userId,
    };
    const body = JSON.stringify(user);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/DeviceLimitsByUser',
      body,
      {
        headers: headers,
      }
    );
  }

  // tslint:disable-next-line:typedef
  getDeviceSheduler(deviceImei) {
    const body = new FormData();
    body.append(deviceImei, '');
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/GetSchedule',
      body
    );
  }

  getUnverifiedConnections() {
    return this.http.get(
      this.BaseURI + '/DevicePingMessage/GetUnverifiedDevices'
    );
  }

  getNonSendedCommands() {
    return this.http.get(
      this.BaseURI + '/DevicePingMessage/GetNotSendedCommands'
    );
  }

  // tslint:disable-next-line:typedef
  debugLog(deviceImei) {
    const UserLimits = {
      Imei: deviceImei,
    };
    const body = JSON.stringify(UserLimits);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/GetDebugLog',
      body,
      {
        headers: headers,
      }
    );
  }
  deleteUser(userId) {
    const selectedUser = {
      Id: userId,
    };
    const body = JSON.stringify(selectedUser);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/DeleteUser',
      body,
      {
        headers: headers,
      }
    );
  }
  deleteFromUnverified(imei) {
    const selectedUser = {
      Imei: imei,
    };
    const body = JSON.stringify(selectedUser);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/DeleteUnverified',
      body,
      {
        headers: headers,
      }
    );
  }

  deviceConnectionStatus(
    deviceId: string,
    statusCode: number,
    imei: string,
    name: string
  ) {
    const selectedDevice = {
      DeviceId: deviceId,
      ConnectionStatus: statusCode,
      Imei: imei,
      Name: name,
    };

    const body = JSON.stringify(selectedDevice);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/DeviceConnectionStatus',
      body,
      {
        headers: headers,
      }
    );
  }

  uploadExcel(data) {
    return this.http.post(this.BaseURI + '/DevicePingMessage/Upload', data);
  }
  getMapFullInfo(objectId: number): Observable<Device> {
    return this.http.get<Device>(
      this.BaseURI + '/DevicePingMessage/' + `${objectId}`
    );
  }

  getMapAllInfo(userId: string): Observable<Device[]> {
    console.log(userId);
    return this.http.get<Device[]>(
      this.BaseURI + `/DevicePingMessage/GetFullInfo/?userId=${userId}`
    );
  }

  getCalibrationData() {
    return this.http.get(this.BaseURI + '/DevicePingMessage/CalibrationData');
  }

  report(
    imei: string[],
    reportType: number,
    startDate: string,
    endDate: string
  ) {
    const selectedDevice = {
      DeviceImei: imei,
      Type: reportType,
      StartTime: startDate,
      EndTime: endDate,
    };

    const body = JSON.stringify(selectedDevice);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/Report',
      body,
      {
        headers: headers,
      }
    );
  }

  graphicReportMinutes(
    imei: string[],
    reportType: number,
    startDate: string,
    endDate: string
  ) {
    const selectedDevice = {
      DeviceImei: imei,
      Type: reportType,
      StartTime: startDate,
      EndTime: endDate,
    };

    const body = JSON.stringify(selectedDevice);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/GraphicReportMinutes',
      body,
      {
        headers: headers,
      }
    );
  }

  deviceClibrCommands(imei: string, command: string) {
    const selectedDevice = {
      DeviceImei: imei,
      command: command,
    };

    const body = JSON.stringify(selectedDevice);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      this.BaseURI + '/DevicePingMessage/calibrationCommands',
      body,
      {
        headers: headers,
      }
    );
  }

  getLastSerialNumber() {
    return this.http.get(
      this.BaseURI + '/DevicePingMessage/GetLastSerialNumber'
    );
  }

  checkImei(data: object) {
    const body = JSON.stringify(data);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    return this.http.post(this.BaseURI + '/DevicePingMessage/ImeiCheck', body, {
      headers: headers,
    });
  }
  sms(data: object) {
    const body = JSON.stringify(data);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    return this.http.post(this.BaseURI + '/DevicePingMessage/SendSms', body, {
      headers: headers,
    });
  }

  getLastPingMessage(imei: string) {
    return this.http.get(
      this.BaseURI + '/DevicePingMessage/GetLastPingMessage/' + imei
    );
  }
}

interface Device {
  UserId: string;
  DeviceName: string;
  DeviceId: number;
  DeviceImei: string;
  Lat: string;
  Long: string;
  Adress: string;
  StationId: string;
  Longitude: number;
  Latitude: number;
  OBJECTID: number;
  KARADIS_NO: string;
  RAIONI: string;
  DADGMULI_S: number;
  KVEBIS_WYA: number;
  KARADIS_TI: number;
  MARTVIS_BL: number;
  MRICXVELIS: number;
  MRICXVEL_1: number;
  SIDA_MOWYO: string;
  MDGOMAREOB: number;
  DAMIWEBA: string;
  SENISVNA: string;
  GAMAVALI_K: number;
  NUMBER_OF_: string;
  DISTRICT: string;
  THE_INSTAL: string;
  POWER_SOUR: string;
  TYPE_OF_TH: string;
  MANAGEMENT: string;
  NUMBER_OF1: string;
  TYPE_OF__1: string;
  INTERNAL_M: string;
  GROUNDING: string;
  OUTPUT_CAB: string;
  // Other properties
}
