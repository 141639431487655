import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  userFilter: any = { name: '' };
  showImei = false;
  filterToggle = false;

  constructor() { }

  ngOnInit(): void {
  }
  // tslint:disable-next-line:typedef
  addNewItem(name, value) {
    this.newItemEvent.emit(name + ';^' + value);
  }
}
